<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{title()}}</h1>
                <a v-if="!lockedPeriod(selectedYear, selectedPeriod)" v-on:click="newBooking()" class="btn btn-primary float-right">{{getLocalMsg('global.new')}}</a>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="years">{{getLocalMsg('global.years')}}</label>
                    <select class="form-control" id="years" v-model="selectedYear">
                        <option v-once v-for="(year, index) in years" v-bind:key="index" v-bind:value = "year">{{year}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="periods">{{getLocalMsg('global.periods')}}</label>
                    <select class="form-control" id="periods" v-model="selectedPeriod">
                        <option v-once v-for="(period, index) in periods" v-bind:key="index" v-bind:value = "period">{{getLocalMsg('global.' + period)}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="search-bookings">{{getLocalMsg('global.search')}}</label>
                    <input type="text" class="form-control" id="search-bookings" v-model="searchInput">
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <booking-items 
                v-bind:book="book" 
                v-bind:bookings="bookings"
                v-bind:details="true"
            ></booking-items>
        </div>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import BookingItems from '../bookings/BookingItems.vue'

export default {
    name: 'BookingList',
    mixins: [sharedMixin],
    props: {
        propBook: String
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            searchInput: ''
        }
    },
    components: {
        BookingItems
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.years.loaded ? loaded : false;
            loaded = this.$store.state.periods.loaded ? loaded : false;
            loaded = this.$store.state.writeoffs.loaded ? loaded : false;
            return loaded;
        },
        bookings () {
            const filterText = this.searchInput.toLowerCase();
            return this.getItems('bookings').filter(booking=>{
                const relationName = booking.relation.name ? booking.relation.name.toLowerCase() : '';
                const description = booking.description ? booking.description.toLowerCase() : '';
                const reference = booking.reference ? booking.reference.toLowerCase() : '';
                return relationName.includes(filterText) || description.includes(filterText) || reference.includes(filterText);
            });
        },
        years () {
            return this.getItems('years');
        },
        periods () {
            return this.getItems('periods');
        },
        selectedYear: {
            get: function () {
                return this.getSelectionProperty('bookings','year');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','year',value);
            }   
        },
        selectedPeriod: {
            get: function () {
                return this.getSelectionProperty('bookings','period');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','period',value);
            }   
        },
        book() {
            return this.getSelectionProperty('bookings','book');
        },
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'bookings',
                property: 'book',
                value: this.propBook
            })
            this.$store.commit('showSpinner',false);

        },
        title () {
            if(this.book === 'P'){
                return this.getLocalMsg('booking.overviewPurchases');
            }else{
                return this.getLocalMsg('booking.overviewSales');
            }
        },
        newBooking() {
            if(this.book === 'P'){
                this.$router.push('/admin/purchase/new')
            }else{
                this.$router.push('/admin/sale/new')
            }
        },
    }
    
   
}
</script>

