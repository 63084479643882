<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('stockchange.overview')}}</h1>
            </div>
        </div>
        <validation-alert 
            v-bind:errors = "validationErrors"
        />
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{getLocalMsg('stockchange.year')}}</th>
                        <th class="text-right">{{getLocalMsg('stockchange.initial')}}</th>
                        <th class="text-right">{{getLocalMsg('stockchange.end')}}</th>
                        <th class="text-right">{{getLocalMsg('stockchange.change')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(stockchange, index) in stockchanges" v-bind:key="index">
                        <td class="align-middle">{{stockchange.year}}</td>
                        <td class="align-middle">
                            <input type="text" :autocomplete="noAutoComplete.initial" class="form-control text-right" v-bind:value="fixAmount(stockchange.initial,2)" v-on:change="updateInitial($event, stockchange.year)">
                        </td>
                        <td class="align-middle text-right">
                            <input type="text" :autocomplete="noAutoComplete.end" class="form-control text-right" v-bind:value="fixAmount(stockchange.end,2)" v-on:change="updateEnd($event, stockchange.year)">
                        </td>
                        <td class="align-middle text-right">{{fixAmount(stockchange.end - stockchange.initial,2)}}</td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ValidationAlert from '../../shared/ValidationAlert';

export default {
    name: 'StockchangeList',
    mixins: [sharedMixin],
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    components: {
        ValidationAlert
    },
    data () {
        return {
            validationErrors: [],
            noAutoComplete: {
                initial: this.noAutoCompleteId(),
                end: this.noAutoCompleteId()
            },
        };
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.stockchanges.loaded ? loaded : false;
            return loaded;
        },
        stockchanges () {
            return this.getItems('stockchanges');
        },
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        validate (val, field) {
            let validate = true;
            this.validationErrors = [];

            if(!this.isNumeric(val)) {
                this.validationErrors.push({
                    type: 'numeric',
                    field: this.getLocalMsg('stockchange.' + field)
                });
                validate=false;
            }

            return validate;
        },
        updateInitial(e, year) {

            if(!this.validate(e.target.value, 'initial')){
                return;
            };

            this.$store.commit('showSpinner', true);
            this.$store.dispatch('stockchanges/update', {
                year: year,
                property: 'initial',
                value: e.target.value
            }).catch(error => {
                this.log(error);
            }).finally(() => 
                () => this.$store.commit('showSpinner', false)
            );
        },
        updateEnd(e, year) {

            if(!this.validate(e.target.value, 'end')){
                return;
            };

            this.$store.commit('showSpinner', true);
            return this.$store.dispatch('stockchanges/update', {
                year: year,
                property: 'end',
                value: e.target.value
            }).catch(error => {
                this.log(error);
            }).finally(() => 
                () => this.$store.commit('showSpinner', false)
            );
        }
    }
}
</script>

<style>

</style>
