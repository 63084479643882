<template>
    <div>
        <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
            <!-- Brand -->
            <a class="navbar-brand" href="#">{{getLocalMsg('global.siteName').toUpperCase()}}</a>

            <!-- Links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/dashboard')" class="nav-link" href="#"><i class="fas fa-home"></i></a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{getLocalMsg('navAdmin.purchases')}}
                    </a>
                    <div class="dropdown-menu">
                        <!-- <a v-on:click.prevent="$router.push('/admin/purchase/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newPurchase')}}</a> -->
                        <a v-on:click.prevent="$router.push('/admin/purchases')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewPurchases')}}</a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <!-- <a v-on:click.prevent="$router.push('/admin/supplier/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newSupplier')}}</a> -->
                        <a v-on:click.prevent="$router.push('/admin/suppliers')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewSuppliers')}}</a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <a v-on:click.prevent="$router.push('/admin/purchases/categories')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewPurchaseCategories')}}</a>
                        <!-- <a v-on:click.prevent="$router.push('/admin/purchases/category/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newPurchaseCategory')}}</a> -->
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{getLocalMsg('navAdmin.sales')}}
                    </a>
                    <div class="dropdown-menu">
                        <!-- <a v-on:click.prevent="$router.push('/admin/sale/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newSale')}}</a> -->
                        <a v-on:click.prevent="$router.push('/admin/sales')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewSales')}}</a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <!-- <a v-on:click.prevent="$router.push('/admin/client/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newClient')}}</a> -->
                        <a v-on:click.prevent="$router.push('/admin/clients')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewClients')}}</a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <a v-on:click.prevent="$router.push('/admin/sales/categories')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.overviewSalesCategories')}}</a>
                        <!-- <a v-on:click.prevent="$router.push('/admin/sales/category/new')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.newSalesCategory')}}</a> -->
                    </div>
                </li>
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/transactions')" class="nav-link" href="#">{{getLocalMsg('navAdmin.transactions')}}</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{getLocalMsg('navAdmin.results')}}
                    </a>
                    <div class="dropdown-menu">
                        <a v-on:click.prevent="$router.push('/admin/stockchanges')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.stockchanges')}}</a>
                        <a v-on:click.prevent="$router.push('/admin/lockedperiods')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.lockedperiods')}}</a>
                        <a v-on:click.prevent="$router.push('/admin/vatdeclaration')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.vatDeclaration')}}</a>
                        <a v-on:click.prevent="$router.push('/admin/writeoffs')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.writeOffs')}}</a>
                        <a v-on:click.prevent="$router.push('/admin/incomestatement')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.incomeStatement')}}</a>
                    </div>
                </li>                
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{activeCompany.name}}
                    </a>
                    <div class="dropdown-menu">
                        <fragment v-if="companies.length !== 1">
                            <a v-on:click.prevent="activeCompany=company" v-for="(company, index) in companies" v-bind:key="index" class="dropdown-item" href="#">{{company.name}}</a>
                            <div class="dropdown-divider"></div>
                        </fragment>
                        <a v-on:click.prevent="$router.push('/admin/registercompany')" class="dropdown-item" href="#">{{getLocalMsg('navAdmin.registerCompany')}}</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{userName}}
                    </a>
                    <div class="dropdown-menu">
                        <a v-on:click.prevent="logout" class="dropdown-item" href="#">Logout</a>
                    </div>
                </li>
                <!-- <b-form id="logout-form" action="logout" method="POST" style="display: none;">
                    <input type="hidden" name="_token" :value="csrf">
                </b-form> -->
                <form ref="logout" action="/logout" method="POST" style="display: none;">
                    <input type="hidden" name="_token" v-bind:value="csrf">
                </form>
            </ul>
        </nav>
    </div>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';

export default {
    name: 'NavAdmin',
    components: {},
    mixins: [sharedMixin],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('value')
        }
    },
    computed: {
        userName () {
            return this.$store.state.user.name;
        },
        companies () {
            return this.$store.state.user.companies;
        },
        activeCompany: {
            get: function () {
                return this.$store.state.user.selection.activeCompany;
            },
            set: function (value) {
                
                this.$store.commit('showSpinner', true);
                this.$store.dispatch('setActiveCompany', value.id).then(
                    () => window.location.href = '/admin/dashboard'
                ).catch(error => {
                    this.log(error);
                })

            }
        }
    },
    methods: {
        logout(){
            this.$refs.logout.submit();
        },

    }
}
</script>

<style scoped>
    .navbar {
        background-color: #3f556a !important;
        font-size: 1.5rem;
        color: white;
        padding-right: 120px !important;
        padding-left: 120px !important;
    }
    .nav-link {
        color:white !important;
        font-size: 1.1rem;
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    
</style>
