<template>
    <tr v-bind:class="classes" >
        <td><slot name="title"></slot></td>
        <td v-if="periodicity==='quarterly'" class="text-right">{{fixedValue(value ? value.Q1 : 0)}}</td>
        <td v-if="periodicity==='quarterly'" class="text-right">{{fixedValue(value ? value.Q2 : 0)}}</td>
        <td v-if="periodicity==='quarterly'" class="text-right">{{fixedValue(value ? value.Q3 : 0)}}</td>
        <td v-if="periodicity==='quarterly'" class="text-right">{{fixedValue(value ? value.Q4 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M01 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M02 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M03 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M04 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M05 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M06 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M07 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M08 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M09 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M10 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M11 : 0)}}</td>
        <td v-if="periodicity==='monthly'" class="text-right">{{fixedValue(value ? value.M12 : 0)}}</td>
        <td class="text-right">{{fixedValue(value ? value.Total : 0)}}</td>
    </tr>
    
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';

export default {
    name: 'IncomeRow',
    mixins: [sharedMixin],
    props: {
        title: String,
        periodicity: String,
        classes: Array,
        value: Object
    },
    methods: {
        fixedValue(value){
            if(value){
                return this.fixAmount(value,2);
            }
            return '';
            
            
        }
    }
}

</script>

<style lang="scss" scoped>

    .row-bold{
        td {
            font-weight: 900;
        }
    }
    .row-indent{
        td {
            padding-left: 30px;
        }
    }

</style>
