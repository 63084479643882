<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1>{{title()}}</h1>
                <h3>{{purchaseDescription}}</h3>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <div v-if="hasManual" class="box bg-white">
                    <div class="mb-3">{{getLocalMsg('writeOff.hasManual')}}</div>
                    <button class="btn btn-primary" type="button" v-on:click="reset">
                        {{getLocalMsg('writeOff.reset')}}
                    </button>

                </div>
                <div v-else class="box bg-white">
                    {{getLocalMsg('writeOff.info')}}
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{getLocalMsg('writeOff.year')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.valueStart')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.writeOff')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.valueEnd')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.professionalShare')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.deductibleShare')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.deductible')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(writeOff, index) in writeOffs" v-bind:key="index">
                        <td class="align-middle">{{writeOff.year}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueStart,2)}}</td>
                        <td class="align-middle text-right">
                            <input type="text" :autocomplete="noAutoComplete.writeOff" class="form-control text-right" v-bind:value="fixAmount(writeOff.writeOff,2)" v-on:change="update('writeOff', writeOff, $event.target.value)">
                        </td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueEnd,2)}}</td>
                        <td class="align-middle text-right">
                            <input type="text" :autocomplete="noAutoComplete.professionalShare" class="form-control text-right" v-bind:value="writeOff.professionalShare" v-on:change="update('professionalShare', writeOff, $event.target.value)">
                        </td>
                        <td class="align-middle text-right">
                            <input type="text" :autocomplete="noAutoComplete.deductibleShare" class="form-control text-right" v-bind:value="writeOff.deductibleShare" v-on:change="update('deductibleShare', writeOff, $event.target.value)">
                        </td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.deductible,2)}}</td>
                        <td class="align-middle text-right">
                            <span v-if="writeOff.manual" class="badge badge-info">{{getLocalMsg('writeOff.manual')}}</span>
                            <span v-if="writeOff.saleId" class="badge badge-success">{{getLocalMsg('writeOff.sold')}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
                <a v-on:click="$router.go(-1)" class="btn btn-primary float-right">{{getLocalMsg('global.back')}}</a>
        </div>
        <div class="box bg-white mt-4">
            {{getLocalMsg('writeOff.fullWriteOff')}}
            <select v-model="year" class="form-control mb-3">
                <option v-for="year in years" v-bind:key="year" v-bind:value="year">
                    {{ year }}
                </option>
            </select>
            <button class="btn btn-secondary" type="button" v-on:click="fullWriteOff">
                {{getLocalMsg('writeOff.writeOff2')}}
            </button>
        </div>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';


export default {
    name: 'WriteOffEdit',
    mixins: [sharedMixin],
    props: {
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            purchaseId : this.$route.params.purchaseId,
            year: this.getSelectionProperty('bookings','year'),
            noAutoComplete: {
                writeOff: this.noAutoCompleteId(),
                professionalShare: this.noAutoCompleteId(),
                deductibleShare: this.noAutoCompleteId()
            },
        }
    },
    components: {
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.writeoffs.loaded ? loaded : false;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            return loaded;
        },
        writeOffs () {
            const writeOffs = this.$store.state.writeoffs.items;
            return writeOffs.filter(item => {
                return Number(item.purchaseId) === Number(this.purchaseId);
            })
        },
        years () {
            return this.writeOffs.map(item=>{
                return item.year;
            })
        },
        hasManual () {
            return this.writeOffs.find(item => {
                return item.manual;
            }) ? true : false;
        },
        purchase () {
            return this.$store.state.bookings.items.find(booking => {
                return booking.booklines.find (bookline => {
                    return Number(bookline.id) === Number(this.purchaseId);
                })
            })
        },
        purchaseDescription () {
            const purchase = this.purchase;
            let description = purchase.description ? purchase.description + ' / ' : '';
            description += purchase.relation.name;
            return description;
        },
        saleId () {
            const sale = this.writeOffs.find(item => {
                return item.saleId;
            });
            if(sale){
                return sale.saleId;
            }
            return null;
        },
        sale () {
            return this.$store.state.bookings.items.find(booking => {
                return booking.booklines.find (bookline => {
                    return Number(bookline.id) === Number(this.saleId);
                })
            })
        },
        purchaseBookline () {
            return this.purchase.booklines.find(bookline => {
                return Number(bookline.id) === Number(this.purchaseId);
            })
        },
        saleBookline () {
            return this.sale.booklines.find(bookline => {
                return Number(bookline.id) === Number(this.saleId);
            })
        }
    },
    methods: {
        
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }  
            this.$store.commit('showSpinner',false);
        },
        title () {
            return this.getLocalMsg('writeOff.title');
        },
        fullWriteOff(){
            this.$store.commit('showSpinner', true);
            this.$store.dispatch('writeoffs/fullWriteOffManual', {
                year: this.year,
                purchaseId: this.purchaseId
            }).catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )
        },
        update(property, writeOff, value){
            this.$store.commit('showSpinner', true);
            const data = {
                year: writeOff.year,
                purchaseId: writeOff.purchaseId,
                writeOff: writeOff.writeOff,
                deductibleShare: writeOff.deductibleShare,
                professionalShare: writeOff.professionalShare
            }
            data[property] = value;
            this.$store.dispatch('writeoffs/update', data)
            .catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )
        },
        reset(){
            this.$store.commit('showSpinner', true);
            this.$store.dispatch('writeoffs/reset', {
                purchaseId: this.purchaseId,
                saleId: this.saleId
            }).catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )
        },
    }
}
</script>

<style lang="scss" scoped>

   

</style>
