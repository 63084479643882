var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [_vm._v(_vm._s(_vm.title))])
            ]),
            _vm._v(" "),
            _vm.propTransaction.transactionType.code === "P" ||
            _vm.propTransaction.transactionType.code === "S"
              ? _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.booking,
                            expression: "booking"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: _vm.noAutoComplete.booking },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.booking = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.bookings, function(booking, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: booking } },
                          [_vm._v(_vm._s(_vm.bookingSelectString(booking)))]
                        )
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { attrs: { for: _vm.noAutoComplete.transactionDate } },
                    [
                      _vm._v(
                        _vm._s(_vm.getLocalMsg("transaction.transactionDate"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("date-picker", {
                    attrs: {
                      autocomplete: _vm.noAutoComplete.transactionDate,
                      id: _vm.noAutoComplete.transactionDate,
                      config: _vm.dateTimePickerOptions
                    },
                    model: {
                      value: _vm.transactionDate,
                      callback: function($$v) {
                        _vm.transactionDate = $$v
                      },
                      expression: "transactionDate"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.propTransaction.transactionType.code === "T"
              ? _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getLocalMsg("transaction.from")) +
                      "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 mb-3" },
              _vm._l(_vm.transactionAccounts, function(
                transactionAccount,
                index
              ) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass: "btn btn-secondary mr-1",
                    class: _vm.buttonStyleFrom(transactionAccount),
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.transactionAccount1 = transactionAccount
                      }
                    }
                  },
                  [_vm._v(_vm._s(transactionAccount.name))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.propTransaction.transactionType.code === "T"
              ? _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getLocalMsg("transaction.to")) +
                      "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.propTransaction.transactionType.code === "T"
              ? _c(
                  "div",
                  { staticClass: "col-12 mb-3" },
                  _vm._l(_vm.transactionAccounts, function(
                    transactionAccount,
                    index
                  ) {
                    return _c(
                      "button",
                      {
                        key: index,
                        staticClass: "btn btn-secondary mr-1",
                        class: _vm.buttonStyleTo(transactionAccount),
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.transactionAccount2 = transactionAccount
                          }
                        }
                      },
                      [_vm._v(_vm._s(transactionAccount.name))]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: _vm.noAutoComplete.amount } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("transaction.amount"))),
                  _c("br"),
                  _c("small", [
                    _vm._v(_vm._s(_vm.getLocalMsg("transaction.positive")))
                  ])
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy",
                      value: _vm.amount,
                      expression: "amount",
                      modifiers: { lazy: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    autocomplete: _vm.noAutoComplete.amount,
                    id: _vm.noAutoComplete.amount
                  },
                  domProps: { value: _vm.amount },
                  on: {
                    change: function($event) {
                      _vm.amount = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { attrs: { for: _vm.noAutoComplete.description } },
                  [_vm._v(_vm._s(_vm.getLocalMsg("transaction.description")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy",
                      value: _vm.description,
                      expression: "description",
                      modifiers: { lazy: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    autocomplete: _vm.noAutoComplete.description,
                    id: _vm.noAutoComplete.description
                  },
                  domProps: { value: _vm.description },
                  on: {
                    change: function($event) {
                      _vm.description = $event.target.value
                    }
                  }
                })
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }