export default {
    namespaced: true,
    state: {
        items: [],
        loaded: false,
        activeItem: {},
        selection: {
            transactionAccount: ''
        }
    },
    actions: {
        /**
         * Fetch status van de transacties vanuit de backend via axios
         * @param {*} param0 
         * @param {*} date
         */
        fetchStatus({ commit }, date) {
            return axios.get('/api/transactions/status/' + date)
                .then(result => {
                    console.log(result);
                    commit('updateStatus', {
                        data: result.data
                    })
                })
                .catch(error => {
                    commit('handleError', error.response.data);
                });
        },
    },
    mutations: {
        updateStatus(state, { data }) {
            state.status=data;
        },
    }
};