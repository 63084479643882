export default {
    namespaced: true,
    state: {
        be_nl: {
            dates: {
                format: 'DD/MM/YYYY'
            },
            global: {
                siteName: 'IziBooks',
                id: 'Volgnummer',
                purchase: 'Aankoop',
                writeOffTable: 'Afschrijvingstabel',
                techBookingId: 'Technische Id Boeking',
                techRelationId: 'Technische Id Relatie',
                techAmountInput: 'Bedrag (input)',
                techInfo: 'Technische info voor ondersteuning',
                view: 'Bekijk',
                edit: 'Bewerk',
                delete: 'Verwijder',
                new: 'Nieuw',
                newe: 'Nieuwe',
                cancel: 'Annuleren',
                back: 'Keer Terug',
                close: 'Sluiten',
                save: 'Opslaan',
                saveAndNext: 'Opslaan en volgende',
                calculations: 'Berekeningen',
                quarterly: 'Per kwartaal',
                monthly: 'Per maand',
                years: 'Boekjaren',
                year: 'Boekjaar',
                periods: 'BTW Periodes',
                period: 'BTW Periode',
                search: 'Zoek',
                yes: 'Ja',
                no: 'Neen',
                M01: 'jan',
                M02: 'feb',
                M03: 'mrt',
                M04: 'apr',
                M05: 'mei',
                M06: 'jun',
                M07: 'jul',
                M08: 'aug',
                M09: 'sep',
                M10: 'okt',
                M11: 'nov',
                M12: 'dec',
                Q1: 'Kwartaal 1',
                Q2: 'Kwartaal 2',
                Q3: 'Kwartaal 3',
                Q4: 'Kwartaal 4',
                total: 'Totaal',
                notRequired: 'Niet verplicht',
                vatNumberNotFound: 'Dit nummer kennen we niet. Dit is niet blokkerend, maar je controleert het best wel even.',
            },
            error: {
                ERR_UNAUTHENTICATED: {
                    header: 'Houston, we have a problem ...',
                    body: 'Je moet aangelogd zijn om deze pagina te bezoeken.',
                    reloadPageButton: 'Login',
                },
                ERR_SERVER: {
                    header: 'Houston, we have a problem ...',
                    body: 'Onze server doet een beetje moeilijk ;-)',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_ACTIVEUSER_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De actieve gebruiker werd niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_ACTIVECOMPANY_SET: {
                    header: 'Houston, we have a problem ...',
                    body: 'De bedrijfsinformatie kon niet worden gezet.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_SESSION_EXPIRED: {
                    header: 'Houston, we have a problem ...',
                    body: 'De huidige sessie is verlopen.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_COMPANY_CREATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het aanmaken van de nieuwe onderneming is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATION_CREATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het aanmaken van de nieuwe klant of leverancier is vermoedelijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_STOCKCHANGES_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De voorraden werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_STOCKCHANGE_UPDATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het wijzigen van de voorraad is vermoedelijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_LOCKEDPERIODS_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De afgesloten periodes werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_LOCKEDPERIOD_UPDATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het wijzigen van de afgesloten periode is vermoedelijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_BOOKINGS_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De boekingen werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_BOOKING_DELETE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het verwijderen van de boeking is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_BOOKING_UPDATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het wijzigen van de boeking is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_BOOKING_CREATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het aanmaken van een nieuwe boeking is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_ACCOUNTS_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De rekeningen werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_CATEGORIES_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De categorieën werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_CATEGORY_DELETE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het verwijderen van de categorie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_CATEGORY_UPDATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het wijzigen van de categorie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_CATEGORY_CREATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het aanmaken van een nieuwe categorie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_VATTYPES_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De btwtypes werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_TRANSACTIONS_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De transacties werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATIONTYPES_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De relatietypes werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATIONS_FETCH: {
                    header: 'Houston, we have a problem ...',
                    body: 'De relaties werden niet correct opgehaald van de server.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATION_DELETE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het verwijderen van de relatie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATION_UPDATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het wijzigen van de relatie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },
                ERR_RELATION_CREATE: {
                    header: 'Houston, we have a problem ...',
                    body: 'Het aanmaken van een nieuwe relatie is waarschijnlijk niet gelukt.',
                    footer1: 'Ververs de pagina en controleer of je laatste aanpassing goed gebeurd is.',
                    footer2: 'Nog steeds met de handen in het haar? No worries ... Vraag hulp en we lossen het voor je op.',
                    reloadPageButton: 'Ververs de pagina',
                    sendMailButton: 'Vraag hulp',
                },

                technicalTitle: 'Technische foutmelding',
                reloadPage: 'Ververs de pagina',
                sendMail: 'Vraag hulp',
                // mailSubject: 'Ik vraag hulp. Contacteer me aub zo snel mogelijk.',
                // mailCode: 'Foutcode',
                // mailMessage: 'Beschrijving',
                // mailTechnicalMessage: 'Technische beschrijving',
                // mailUser: 'Gebruiker',
                // mailTimestamp: 'Datum en tijdstip',
                mailSentHeader:'Je vraag om hulp is verzonden',
                mailSentBody:'Zoals je van ons gewoon bent, contacteren we je snel.'


            },
            navAdmin: {

                purchases: 'Aankopen',
                overviewPurchases: 'Aankopen',
                overviewSuppliers: 'Leveranciers',
                overviewPurchaseCategories: 'Categorieën Aankopen',
                sales: 'Verkopen',
                overviewSales: 'Verkopen',
                overviewClients: 'Klanten',
                overviewSalesCategories: 'Categorieën Verkopen',   
                transactions: 'Transacties',             
                results: 'Resultaten',
                vatDeclaration: 'BTW Aangifte',
                writeOffs: 'Afschrijvingen',
                incomeStatement: 'Resultatenrekening',
                stockchanges: 'Voorraden',
                lockedperiods: 'Afgesloten periodes',
                registerCompany: 'Nieuwe onderneming toevoegen'

            },
            navSite: {
                price: 'Prijs',
                about: 'Over',
                contact: 'Contact',
                login: 'Login',
                register: 'Gratis Proefabonnement'
            },
            companies: {
                info: 'We hebben nog wat info nodig over je onderneming. Je kan deze gegevens nadien nog aanpassen.',
                unsuitable: 'Sorry. :siteName is momenteel nog niet geschikt voor het voeren van een boekhouding voor deze ondernemingsvorm. We laten je iets weten wanneer dat wel zo is.',
                startDate: 'Oprichtingsdatum',
                vatExempt: 'Kleine onderneming, vrijgesteld van BTW',
                name: 'Naam Onderneming',
                street: 'Straat en Huisnummer',
                postcode: 'Postcode',
                city: 'Gemeente',
                country: 'Land',
                countryCode: 'Landcode',
                type: 'Ondernemingsvorm',
                vatNumber: 'Ondernemingsnummer',
                vatNumberPlaceHolder: 'BTW-nummer zonder BE, enkel cijfers. :siteName is enkel geschikt voor Belgische organisaties.',
                register: 'Registreer',
                types: [
                    { id: 1, name: 'Zelfstandige in hoofd- of bijberoep' },
                    { id: 2, name: 'BVBA / BV' },
                    { id: 3, name: 'VOF' },
                    { id: 4, name: 'VZW' },
                    { id: 5, name: 'Gewone commanditaire vennootschap' },
                    { id: 6, name: 'Commanditaire vennootschap op aandelen' },
                    { id: 7, name: 'NV' },
                    { id: 8, name: 'CVOA' },
                    { id: 9, name: 'CVBA' },
                    { id: 10, name: 'VME - Vereniging van mede-eigenaars' },
                    { id: 11, name: 'Overige' },
                ]
            },
            dashboard: {
                purchases: 'Aankopen',
                sales: 'Verkopen',
                resultsPerMonth: 'Resultaten :year per maand',
                resultsPerQuarter: 'Resultaten :year per kwartaal',
                retailPerMonth: 'Omzet <> Aankoop Handelsgoederen, grond en hulpstoffen :year per maand',
                retailPerQuarter: 'Omzet <> Aankoop Handelsgoederen, grond en hulpstoffen :year per kwartaal',
                totalPurchases: 'Aankopen :year (excl BTW)',
                totalSales: 'Verkopen :year (excl BTW)'
            },
            category: {
                overviewCategories: 'Overzicht Categorieën',
                category: 'Categorie',
                name: 'Naam',
                info: 'Hier komt wat info over wat de kostencategorieen zijn. Ook dat je ze niet hier per se moet invullen',
                defaultDeductibleVatShare: 'Aftrekbare BTW',
                defaultDeductibleCostsShare: 'Aftrekbare kosten',
                delete: {
                    header: 'Ben je zeker dat je deze categorie wilt verwijderen?',
                    body: 'Opgelet, je kan deze categorie dan niet meer gebruiken bij het aanmaken van nieuwe boekingen. De categorie blijft wel behouden in vroegere boekingen.',
                },

            },
            stockchange: {
                overview: 'Voorraad',
                year: 'Boekjaar',
                initial: 'Beginvoorraad',
                end: 'Eindvoorraad',
                change: 'Voorraadwijziging'
            },
            lockedperiod: {
                overview: 'Afgesloten periodes',
                info: 'De afgesloten periodes kunnen niet meer bewerkt worden. Dit om te vermijden dat er nog wijzigingen gebeuren aan reeds ingediende BTW aangiftes of jaarrekeningen.',
                period: 'Periode',
                locked: 'Afgesloten'
            },
            relation: {
                name: 'Naam',
                clientId: 'Klantnr',
                supplierId: 'Leveranciersnr',
                purchases: 'Aankopen',
                sales: 'Verkopen',
                client: 'Klant',
                supplier: 'Leverancier',
                type: 'Type',
                defaultAccount: 'Standaard Rekening',
                defaultCategory: 'Standaard Categorie',
                vatNumber: 'BTW Nummer',
                street: 'Straat',
                postcode: 'Postcode',
                city: 'Gemeente',
                country: 'Land',
                website: 'Website',
                email: 'Emailadres',
                overviewClients: 'Overzicht Klanten',
                overviewSuppliers: 'Overzicht Leveranciers',
                info: 'Ter info, het is niet nodig om steeds eerst een nieuwe leverancier aan te maken via dit scherm. U kan tijdens het ingeven van een nieuwe aankoop ook de gegevens van een nieuwe leverancier meedelen zodat deze samen met de boeking van de aankoop wordt aangemaakt.',
                delete: {
                    header: 'Ben je zeker dat je deze :relation wilt verwijderen?',
                    body: 'Opgelet, je kan deze :relation dan niet meer gebruiken bij het aanmaken van nieuwe boekingen. De :relation blijft wel behouden in vroegere boekingen.',
                },
            },
            booking: {
                locked: 'Deze boeking kan niet gewijzigd worden. De BTW aangifte is reeds verstuurd.',
                overviewPurchases: 'Overzicht Aankopen',
                overviewSales: 'Overzicht Verkopen',
                purchase: 'Aankoop',
                purchases: 'Aankopen',
                purchaseId: 'Aankoopnr',
                payments: 'Betalingen',
                notPaid: 'Nog niet betaald',
                paid: 'Volledig betaald',
                overPaid: 'Teveel betaald',
                addPayment: 'Voeg betaling toe',
                details: 'Details',
                sale: 'Verkoop',
                sales: 'Verkopen',
                saleId: 'Verkoopnr',
                bookingId: 'Boekingsnr',
                date: 'Datum',
                invoiceDate: 'Datum',
                relation: 'Relatie',
                client: 'Klant',
                newClient: 'Nieuwe Klant',
                supplier: 'Leverancier',
                newSupplier: 'Nieuwe Leverancier',
                reference: 'Referentie Factuur',
                description: 'Omschrijving',
                amount: 'Bedrag',
                invoiceAmount: 'Bedrag Factuur',
                realEstate: 'Werken in onroerende staat in Belgie',
                vatShifted: 'BTW Verlegd',
                vatExempt: 'Vrijgesteld van BTW',
                noVatDeclaration: 'Niet opnemen in de BTW Aangifte',
                professionalShare: 'Beroepsgedeelte',
                term: 'Afschrijvingstermijn (aantal jaren)',
                saleExists: 'Deze investering is al verkocht en kan dus niet gewijzigd of verwijderd worden.',
                account: 'Rekening',
                category: 'Categorie',
                vattype: 'BTW Type',
                amountExclVat: 'Excl BTW',
                amountVat: 'BTW',
                amountInclVat: 'Incl BTW',
                deductibleVat: 'Aftrekbare BTW',
                deductibleCosts: 'Aftrekbare Kosten',
                calculations: 'Berekeningen',
                vatCodes: 'BTW Codes',
                code: 'Code',
                totalsNotMatching: 'Het factuurtotaal komt niet overeen met het totaal van de onderliggende bedragen.',
                delete: {
                    withRenumbering: {
                        header: 'Opgepast bij verwijderen :book!',
                        body1: 'Aangezien u reeds andere :books heeft ingeboekt met volgnummers die volgen op deze :book kan u deze :book niet eenvoudig meer verwijderen',
                        body2: 'Situatie 1, u kan de volgnummers niet meer wijzigen:',
                        body3: 'Bv, u stuurde reeds een document met volgnummers door naar een overheidsinstantie',
                        body4: 'Bv, u nam de volgnummers over op papieren versies van :bookdocumenten',
                        body5: 'Indien u de volgnummers die volgen op deze :book niet meer kan wijzigen dan moet u een extra :book aanmaken met dezelfde informatie maar met een negatief bedrag.',
                        body6: 'Situatie 2, u kan de volgnummers nog wijzigen:',
                        body7: 'Indien u hieronder op "Verwijderen met hernummering volgnummers" klikt zullen na het verwijderen van de :book de volgnummers van alle daaropvolgende :books gewijzigd worden.',
                        deleteButton: 'Verwijderen met hernummering volgnummers' 
                    },
                    withoutRenumbering: {
                        header: 'Ben je zeker dat je deze :book wilt verwijderen?',
                        body: 'Opgelet, je kan het verwijderen van deze :book niet ongedaan maken.',
                        deleteButton: 'Verwijderen'
                    },
                    
                },
                confirms: {
                    referenceAlreadyExists: 'Referentie Factuur is reeds gebruikt in een andere boeking voor dezelfde relatie. Wil je verder?',
                    dateNotInBookingPeriod: 'Datum Factuur valt niet in de geselecteerde boekingperiode. Wil je verder?',
                    manualWriteOffs: 'Voor deze investering werden manuele aanpassingen gedaan aan de afschrijvingstabel. Deze worden overschreven. Wil je verder?',
                    correct: 'Eerst rechtzetten',
                    proceed: 'Toch inboeken'
                },
            },
            transaction: {
                overview: 'Overzicht Transacties',
                dateTotal: 'Dagtotaal',
                rollingTotal: 'Toestand',
                transactionAccounts: 'Rekeningen',
                transactionType: 'Type',
                bookingRefNumber: 'Boekingnr',
                transactionDate: 'Datum',
                amount: 'Bedrag',
                sale: 'Verkoop',
                purchase: 'Aankoop',
                positive: 'Steeds positief in te vullen, behalve bij creditnota\'s.',
                description: 'Beschrijving',
                add: 'Voeg toe',
                update: 'Opslaan',
                reference: 'Referentie',
                booking: 'Boeking',
                transactionAccount: 'Rekening',
                from: 'Van',
                to: 'Naar',
                newO : '+ Uitgaand',
                newI: '+ Inkomend',
                newT: '+ Transfer',
                newP: '+ Aankoop',
                newS: '+ Verkoop',
                delete: {
                    header: 'Ben je zeker dat je deze transactie wilt verwijderen?',
                    body: 'Opgelet, je kan het verwijderen van deze transactie niet ongedaan maken.',
                },
            },  
            writeOff: {
                title: 'Afschrijvingen (excl. aftrekbare BTW)',
                title2: 'Afschrijvingstabel',
                investments: 'Investeringen (excl. aftrekbare BTW)',
                term: 'Termijn',
                purchase: 'Aankoop',
                info: 'Afschrijvingen gebeuren standaard lineair. Je kan dit wijzigen, maar dan kan Easybookr de correctheid niet meer waarborgen. Gezien de impact op de resultatenrekening, raden we ten sterkste af om afschrijvingen te wijzigen in boekjaren die reeds via de personenbelasting werden ingediend.',
                hasManual: 'U heeft de afschrijvingen handmatig aangepast. Easybookr kan de correctheid van de afschrijvingen niet meer waarborgen.',
                reset: 'Terug naar standaard afschrijvingen',
                year: 'Jaar',
                valueStart: 'Waarde begin',
                purchases: 'Aankopen',
                writeOff: 'Afschrijving',
                writeOffs: 'Afschrijvingen',
                writeOff2: 'Afschrijven',
                valueEnd: 'Waarde einde',
                professionalShare: 'Beroepsgedeelte %',
                deductibleShare: 'Aftrekbaar %',
                deductible: 'Aftrekbaar',
                fullWriteOff: 'Schrijf het resterende bedrag volledig af in',
                manual: 'Manueel',
                desinvestment: 'Desinvestering via verkoop',
                sold: 'Verkocht' 
            },
            incomeStatement: {
                title: 'Resultatenrekening',
                detail: 'Details',
                rowA1: '[A] Omzet (excl. BTW)',
                rowB1: '[B] Kosten handelsgoederen, grond- en hulpstoffen',
                rowB2: 'Aankoop handelsgoederen, grond- en hulpstoffen',
                rowB3: 'Voorraadwijzigingen (:yearMin1 - :year)',
                rowC1: '[C] Bruto winst = [A]-[B]',
                rowC2: 'Bruto marge = [C]/[A]',
                rowD1: '[D] Overige kosten',
                rowE1: '[E] Operationele winst = [C]-[D]',
                rowE2: 'Operationele marge = [E]/[A]',
                rowF1: '[F] Jaarlijkse afschrijvingen',
                rowG1: '[G] Belastbare winst = [E]-[F]',
                rowG2: 'Netto marge = [G]/[A]',
                rowH1: 'Niet opgenomen hierboven (Aankoop handelsgoederen, grond- en hulpstoffen + Overige kosten)',
                rowH2: 'Verworpen uitgaven (Niet aftrekbare kosten)',
                rowH3: 'Privé gedeelte aankopen',
                rowI1: 'Niet opgenomen hierboven (Jaarlijkse afschrijvingen)',
                rowI2: 'Verworpen uitgaven (Niet aftrekbare afschrijvingen)',
                rowI3: 'Privé gedeelte afschrijvingen',
                rowJ1: 'Gerealiseerde meerwaarde op verkoop van investeringen (Niet opgenomen in omzet)',
                rowJ2: 'Verkoop (beroepsgedeelte)',
                rowJ3: 'Niet afgeschreven bedrag',
                rowJ4: 'Meerwaarde',

            },
            vatDeclaration: {
                title: 'Kwartaalaangifte BTW',
                detailVatcode: 'Detail BTW Rooster',
                detailBooking: 'Detail Boeking',
                box2: 'KADER II: UITGAANDE HANDELINGEN',
                box2A: 'A. Handelingen onderworpen aan een bijzondere regeling',
                box2A00: 'Rooster 00',
                box2B: 'B. Handelingen waarvoor de btw verschuldigd is door de aangever',
                box2B01: 'Rooster 01 - Tarief 6%',
                box2B02: 'Rooster 02 - Tarief 12%',
                box2B03: 'Rooster 03 - Tarief 21%',
                box2C: 'C. Diensten waarvoor de buitenlandse btw verschuldigd is door de medecontractant',
                box2C44: 'Rooster 44',
                box2D: 'D. Handelingen waarvoor de btw verschuldigd is door de medecontractant',
                box2D45: 'Rooster 45',
                box2E: 'E. Vrijgestelde intracommunautaire leveringen verricht in België en ABC-verkopen',
                box2E46: 'Rooster 46',
                box2F: 'F. Andere vrijgestelde handelingen en andere handelingen vericht in het buitenland',
                box2F47: 'Rooster 47',
                box2G: 'G. Bedrag van de uitgereikte creditnota\'s en de negatieve verbeteringen',
                box2G48: 'Rooster 48 - Met betrekking tot de handelingen ingeschreven in de roosters [44] en [46]',
                box2G49: 'Rooster 49 - Met betrekking tot de andere handelingen van kader II',
                box3: 'KADER III: INKOMENDE HANDELINGEN',
                box3A: 'A. Bedrag van de inkomende handelingen rekening houdend met de ontvangen creditnota\'s en de andere verbeteringen',
                box3A81: 'Rooster 81 - Handelsgoederen, grond- en hulpstoffen',
                box3A82: 'Rooster 82 - Diverse goederen en diensten',
                box3A83: 'Rooster 83 - Bedrijfsmiddelen',
                box3B: 'B. Bedrag van de ontvangen creditnota\'s en de negative verbeteringen',
                box3B84: 'Rooster 84 - Met betrekking tot de handelingen ingeschreven in de roosters [86] en [88]',
                box3B85: 'Rooster 85 - Met betrekking tot de andere inkomende handelingen van kader III',
                box3C: 'C. Intracommunautaire verwervingen verricht in België en ABC-verkopen',
                box3C86: 'Rooster 86',
                box3D: 'D. Andere inkomende handelingen waarvoor de btw verschuldigd is door de aangever',
                box3D87: 'Rooster 87',
                box3E: 'E. Intracommunautaire diensten met verlegging van heffing',
                box3E88: 'Rooster 88',
                box4: 'KADER IV: VERSCHULDIGDE BELASTING',
                box4A: 'A. Btw op de handelingen aangegeven in :',
                box4A54: 'Rooster 54 - De roosters [01], [02] en [03]',
                box4A55: 'Rooster 55 - De roosters [86] en [88]',
                box4A56: 'Rooster 56 - Rooster [87], met uitzondering van invoeren met verlegging van heffing',
                box4B: 'B. Btw op invoeren met verlegging van heffing',
                box4B57: 'Rooster 57',
                box4C: 'C. Diverse btw-regularisaties in het voordeel van de Staat',
                box4C61: 'Rooster 61',
                box4D: 'D. Terug te storten btw vermeld op ontvangen creditnota\'s',
                box4D63: 'Rooster 63',
                box4E: 'Totaal van de verschuldige belasting',
                box4EXX: 'Rooster XX',
                box5: 'KADER V: AFTREKBARE BELASTING',
                box5A: 'A. Aftrekbare btw',
                box5A59: 'Rooster 59',
                box5B: 'B. Diverse btw-regularisaties in het voordeel van de aangever',
                box5B62: 'Rooster 62',
                box5C: 'C. Te recupereren btw vermeld op uitgereikte creditnota\'s',
                box5C64: 'Rooster 64',
                box5D: 'Totaal van de aftrekbare belasting',
                box5DYY: 'Rooster YY',
                box6: 'KADER VI: SALDO',
                box671: 'Rooster 71 - Aan de Staat verschuldigde belasting : rooster [XX] - rooster [YY]',
                box672: 'Rooster 72 - Sommen verschuldigd door de Staat : rooster [YY] - rooster [XX]',
                box7: 'KADER VII: VOORSCHOT',
                box791: 'Rooster 91',
                box8: 'NIHIL KLANTENLISTING',
                box8KL: 'Nihil Klantenlisting',
                none: 'Geen',
                leaveEmpty: 'Leeg laten',

            },
            validation: {
                header: 'Gelieve eerst volgende fout(en) recht te zetten: ',
                required: 'Het veld :field is verplicht.',
                numeric: 'Het veld :field moet een getal bevatten.',
                integer: 'Het veld :field moet een geheel getal bevatten.',
                unique: 'De waarde in het veld :field is reeds gebruikt en moet uniek zijn.',
                between: 'De waarde in het veld :field moet tussen :min en :max liggen.',
                min: 'De waarde in het veld :field moet minimum :min zijn.',
                digits: 'De waarde in het veld :field moet bestaan uit :quantity cijfers.',
                maxLength: 'De waarde in het veld :field mag maximaal :max karakters bevatten.',
                searchandselect: 'Zoek en selecteer een geldige waarde in het veld :field'
            }
        },
        be_fr: {
            booking: {
                
            },
            global: {
                years: 'Années',
                periods: 'Périodes',
                search: 'Recherche'
            }
        },
        local: 'be_nl'
    },
    getters: {
        getLocalMsg: (state) => (messageId, replacements) => {

            const arrMessageId = messageId.split('.');
            let message = state[state.local];
            for (let i = 0; i < arrMessageId.length; i++) {
                if (message[arrMessageId[i]]){
                    message = message[arrMessageId[i]];
                }else{
                    return messageId;
                }
            }

            //Vervangen replacements in string
            if(message && replacements){
                for (let prop in replacements) {
                    // skip loop if the property is from prototype
                    if (!replacements.hasOwnProperty(prop)) continue;
                    message = message.split(':' + prop).join(replacements[prop]);
                }
            }



            if (message){
                return message;
            }else{
                return messageId;
            }
        },
    }
};  