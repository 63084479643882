<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{title()}}</h1>
                <a v-on:click.prevent="newRelation()" class="btn btn-primary float-right">{{getLocalMsg('global.new')}}</a>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="search-relations">{{getLocalMsg('global.search')}}</label>
                    <input type="text" class="form-control" id="search-relations" v-model="searchInput">
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{clientSupplier === 'S' ? getLocalMsg('relation.supplierId') : getLocalMsg('relation.clientId')}}</th>
                        <th>{{getLocalMsg('relation.name')}}</th>
                        <th>{{getLocalMsg('relation.type')}}</th>
                        <th>{{getLocalMsg('relation.vatNumber')}}</th>
                        <th>{{getLocalMsg('relation.city')}}</th>
                        <th>{{getLocalMsg('relation.country')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(relation, index) in relations" v-bind:key="index">
                        <td class="align-middle">{{relation.refNumber}}</td>
                        <td class="align-middle">{{relation.name}}</td>
                        <td class="align-middle">{{relation.relationtype.name}}</td>
                        <td class="align-middle">{{relation.vatnumber}}</td>
                        <td class="align-middle">{{relation.city}}</td>
                        <td class="align-middle">{{relation.country}}</td>
                        <td class="align-middle text-right">
                            <dropdown-menu
                                v-on:view="viewRelation(relation.id)"
                                v-on:edit="editRelation(relation.id)"
                                v-on:delete="showDeleteModal(relation)"
                            />                      
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <modal-template ref="modalDelete">
            <template v-slot:header>
                <h5>{{getLocalMsg('relation.delete.header',{relation: relationName(true,true)})}}</h5>
            </template>
            <template v-slot:body>
                <p>{{getLocalMsg('relation.delete.body',{relation: relationName(true,true)})}}</p>
            </template>
            <template v-slot:footer>
                <button type="button" v-on:click="deleteRelation" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('global.delete')}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue'
import DropdownMenu from '../../shared/DropdownMenu.vue'


export default {
    name: 'RelationList',
    mixins: [sharedMixin],
    props: {
        propClientSupplier: String
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            searchInput: '',
            relationIdToDelete: null
        }
    },
    components: {
        ModalTemplate,
        DropdownMenu
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.relations.loaded ? loaded : false;
            return loaded;
        },
        relations () {
            const filterText = this.searchInput.toLowerCase();
            return this.getItems('relations').filter(relation=>{
                const name = relation.name ? relation.name.toLowerCase() : '';
                const relationTypeName = relation.relationtype.name ? relation.relationtype.name.toLowerCase() : '';
                const city = relation.city ? relation.city.toLowerCase() : '';
                const vatnumber = relation.vatnumber ? relation.vatnumber.toLowerCase() : '';
                return name.includes(filterText) || relationTypeName.includes(filterText)|| city.includes(filterText)|| vatnumber.includes(filterText);
            });
        },
        clientSupplier () {
            return this.getSelectionProperty('relations','clientSupplier');
        },
    },
    methods: {
        
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }  
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'relations',
                property: 'clientSupplier',
                value: this.propClientSupplier
            })
            this.$store.commit('showSpinner',false);
        },
        title () {
            if(this.clientSupplier === 'S'){
                return this.getLocalMsg('relation.overviewSuppliers');
            }else{
                return this.getLocalMsg('relation.overviewClients');
            }
        },
        relationName (singular, lowercase) {
            let relationName = '';
            if(this.clientSupplier === 'S'){
                relationName = singular ? this.getLocalMsg('relation.supplier') : this.getLocalMsg('relation.suppliers');
            }else{
                relationName = singular ? this.getLocalMsg('relation.client') : this.getLocalMsg('relation.clients');
            }
            return lowercase ? relationName.toLowerCase() : relationName;
        },
        showDeleteModal(relation) {

            this.relationIdToDelete = relation.id;
            this.showModal('modalDelete');
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        newRelation() {
            if(this.clientSupplier === 'S'){
                this.$router.push('/admin/supplier/new')
            }else{
                this.$router.push('/admin/client/new')
            }
        },
        editRelation(id) {
            if(this.clientSupplier === 'S'){
                this.$router.push('/admin/supplier/' + id + '/edit')
            }else{
                this.$router.push('/admin/client/' + id + '/edit')
            }
        },
        deleteRelation(){

            this.deleteItem('relations',this.relationIdToDelete)
            .catch(error => {
                this.log(error);
            })

        },
        viewRelation(id) {
            if(this.clientSupplier === 'S'){
                this.$router.push('/admin/supplier/' + id + '/view')
            }else{
                this.$router.push('/admin/client/' + id + '/view')
            }
        }
    }
}
</script>

<style>

</style>
