<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('transaction.overview')}}</h1>
                <a v-on:click.prevent="showTransactionModalNew('S')" class="btn btn-primary float-right">{{getLocalMsg('transaction.newS')}}</a>
                <a v-on:click.prevent="showTransactionModalNew('P')" class="btn btn-primary float-right mr-1">{{getLocalMsg('transaction.newP')}}</a>
                <a v-on:click.prevent="showTransactionModalNew('I')" class="btn btn-primary float-right mr-1">{{getLocalMsg('transaction.newI')}}</a>
                <a v-on:click.prevent="showTransactionModalNew('O')" class="btn btn-primary float-right mr-1">{{getLocalMsg('transaction.newO')}}</a>
                <a v-on:click.prevent="showTransactionModalNew('T')" class="btn btn-primary float-right mr-1">{{getLocalMsg('transaction.newT')}}</a>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label for="transactionAccounts">{{getLocalMsg('transaction.transactionAccounts')}}</label>
                    <select class="form-control" id="transactionAccounts" v-model="transactionAccount">
                        <option v-once v-for="(transactionAccount, index) in transactionAccounts" v-bind:key="index" v-bind:value = "transactionAccount">{{transactionAccount.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table ">
                <tbody>
                    <template v-for="transactionDate in transactionsByDate">
                        <tr v-bind:key="'header-' + transactionDate.date">
                            <td class="align-middle fg-color-blue">
                                <div class="highlight-header-row p-2 mb-3">{{formatDate(transactionDate.date)}}</div>
                                <small class="pl-1 pb-0"><strong>{{getLocalMsg('transaction.dateTotal')}} : {{fixAmount(transactionDate.dateTotal,2)}} / {{getLocalMsg('transaction.rollingTotal')}} : {{fixAmount(transactionDate.rollingTotal,2)}}</strong></small>
                            </td>
                        </tr>
                        <tr v-bind:key="'detail-transactions-' + transactionDate.date">
                            <td class="hide-border-top pt-0">
                                <table class="table table-borderless table-sm">
                                    <thead>
                                        <tr>
                                            <th style="width: 30%"><small><strong>{{getLocalMsg('transaction.reference')}}</strong></small></th>
                                            <th style="width: 25%"><small><strong>{{getLocalMsg('transaction.booking')}}</strong></small></th>
                                            <th style="width: 35%"><small><strong>{{getLocalMsg('transaction.description')}}</strong></small></th>
                                            <th style="width: 5%" class="text-right"><small><strong>{{getLocalMsg('transaction.amount')}}</strong></small></th>
                                            <th style="width: 5%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(transaction, index) in transactionDate.transactions" v-bind:key="index" v-bind:class="{'fg-red': noBookingLinkedToPurchaseOrSale(transaction)}">
                                            <td class="align-middle"><small>{{transaction.reference}}</small></td>
                                            <td class="align-middle"><small>
                                                <div v-if="['S','P'].includes(transaction.transactionType.code)">
                                                    <transaction-select-booking 
                                                        v-bind:propTransaction = "transaction"
                                                    />
                                                </div>
                                            </small></td>
                                            <td class="align-middle"><small>{{transaction.description}}</small></td>
                                            <td class="align-middle text-right"><small>{{fixAmount(transaction.amount * transaction.factor,2)}}</small></td>
                                            <td class="align-middle text-right">
                                                <button class="mt-n1 float-right btn btn-sm" v-on:click="showDeleteModal(transaction)"><i class="far fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <modal-template ref="modalDelete">
            <template v-slot:header>
                <h5>{{getLocalMsg('transaction.delete.header')}}</h5>
            </template>
            <template v-slot:body>
                <p>{{getLocalMsg('transaction.delete.body')}}</p>
            </template>
            <template v-slot:footer>
                <button type="button" v-on:click="deleteTransaction" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('global.delete')}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
        <modal-template ref="modalTransaction">
            <template v-slot:header>
                <div>
                    <h5>{{getLocalMsg('transaction.add')}}</h5>
                </div>
            </template>
            <template v-slot:body>
                <transaction-edit 
                    ref="transactionEdit"
                    v-bind:key="forceRerenderKey"
                    v-bind:propTransaction="selectedTransaction"
                />
            </template>
            <template v-slot:footer>
                <div>
                    <button type="button" v-on:click="updateTransaction" class="btn btn-primary">{{getLocalMsg('transaction.update')}}</button>
                </div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue'
import TransactionEdit from '../../admin/transactions/TransactionEdit.vue'
import TransactionSelectBooking from '../../admin/transactions/TransactionSelectBooking.vue'

export default {
    name: 'TransactionList',
    mixins: [sharedMixin],
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            transactionToDelete: null,
            selectedTransaction: {
                transactionType: {},
                transactionAccount: {},
                amount: ''
            },
            forceRerenderKey: 0,  
        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.transactions.loaded ? loaded : false;
            loaded = this.$store.state.transactionAccounts.loaded ? loaded : false;
            loaded = this.$store.state.transactionTypes.loaded ? loaded : false;
            return loaded;
        },
        transactions () {
            return this.getItems('transactions')
            .sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))
            .sort((a, b) => (a.transactionDate > b.transactionDate) ? 1 : ((b.transactionDate > a.transactionDate) ? -1 : 0));
        },
        transactionsByDate () {

            let transactionsByDate = [];
            let rollingTotal = this.transactionAccount.openingBalance;

            let transactionDates = this.transactions.map(transaction => {
                return transaction.transactionDate;
            })
            transactionDates = [...new Set(transactionDates)];
            for(let i = 0; i < transactionDates.length; i++){
                const transactions = this.transactions.filter(transaction => {
                    return transaction.transactionDate == transactionDates[i];
                })
                const dateTotal = transactions.reduce((a, b) => a + (b['amount'] * b['factor']), 0);
                rollingTotal += dateTotal;
                transactionsByDate.push({
                    date: transactionDates[i],
                    dateTotal: dateTotal,
                    rollingTotal: rollingTotal,
                    transactions: transactions
                })
            }

            return transactionsByDate.slice().reverse();

        },

        transactionAccounts () {
            return this.getItems('transactionAccounts');
        },
        transactionAccount: {
            get: function () {
                return this.getSelectionProperty('transactions','transactionAccount');
            },
            set: function (value) {
                this.setSelectionProperty('transactions','transactionAccount',value);
            }  
        },
    },
    components: {
        ModalTemplate,
        TransactionEdit,
        TransactionSelectBooking
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'transactions',
                property: 'transactionAccount',
                value: this.transactionAccounts[0]
            })
            this.$store.commit('showSpinner',false);
        },
        showDeleteModal(transaction) {
            this.transactionToDelete = transaction;
            this.showModal('modalDelete');
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        showTransactionModalNew(transactionTypeCode) {
            this.forceRerender();
            this.selectedTransaction = {
                transactionType: this.$store.state.transactionTypes.items.find(item => {
                    return item.code == transactionTypeCode;
                }),
                transactionAccount: this.transactionAccount
            };
            this.showModal('modalTransaction');
        },
        showTransactionModalEdit(transaction) {
            this.forceRerender();
            this.selectedTransaction = transaction,
            this.showModal('modalTransaction');
        },
        updateTransaction() {
            const success = this.$refs.transactionEdit.updateTransaction().then(result =>{
                if (result == 'NotValidated') {
                    return;
                } else {
                    console.log('yes');
                    $(this.$refs.modalTransaction.$el).modal('hide');
                }
            });
        },
        deleteTransaction(){

            this.$store.commit('showSpinner', true);

            const setActiveItem = () => {
                return this.$store.dispatch('setActiveItem', {
                    moduleName: 'transactions',
                    newItem: false,
                    item: this.transactionToDelete
                })
            }

            const setDeleted = setActiveItem().then(() => {
                return this.setItemProperty('transactions','isDeleted',true);
            })

            const updateTransaction = setDeleted.then(() => {
                return this.$store.dispatch('updateTransaction')
            })

            return updateTransaction.catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )

        },
        forceRerender() {
            this.forceRerenderKey += 1;  
        },
        noBookingLinkedToPurchaseOrSale(transaction) {
            if((transaction.transactionType.code == 'P' || transaction.transactionType.code == 'S') && !transaction.booking){
                return true;
            }
            return false;
        }
    },
}
</script>

<style lang="scss" scoped>

.highlight-header-row {
    background-color: #3f556a !important;
    color: white;
}


.hide-border-top {
    border-top:0;
}

.fg-red {
    color: red;
}


</style>
