var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "input-group" }, [
      _c("div", { staticClass: "input-group-prepend" }, [_vm._t("prepend")], 2),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchItem,
            expression: "searchItem"
          }
        ],
        ref: "searchField",
        staticClass: "form-control",
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          autocomplete: _vm.noAutoComplete.searchField
        },
        domProps: { value: _vm.searchItem },
        on: {
          keyup: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.down($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.up($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.commit($event)
            }
          ],
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.commit($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchItem = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [_vm._t("append")], 2)
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "resultList",
        staticClass: "list-group collapse position-absolute"
      },
      _vm._l(_vm.foundItems, function(foundItem, index) {
        return _c(
          "button",
          {
            key: index,
            staticClass: "list-group-item list-group-item-action",
            class: { active: index === 0 },
            attrs: { type: "button", "data-id": foundItem.id },
            on: {
              click: function($event) {
                return _vm.selectAndCommit($event)
              }
            }
          },
          [_vm._v(_vm._s(foundItem.name))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }