

export default {

    /**
     * Berekenen van all totalen in de boeking, op basis van factuurtotalen en onderliggende boeklijnen
     * Bepalen aftrekbare BTW en aftrekbare kosten
     * Toewijzing doen van bedragen aan de juiste BTW-codes (roosters)
     * @param {*} state 
     */
    setBookingTotals(state) {

        const booking = state.activeItem;

        let totalAmountExclVat = 0;
        let totalAmountInclVat = 0;
        let totalAmountVat = 0;

        for (let i = 0; i < booking.booklines.length; i++) {

            if (booking.booklines[i]['amountExclVat']) {
                totalAmountExclVat += booking.booklines[i]['amountExclVat'];
                totalAmountInclVat += booking.booklines[i]['amountInclVat'];
                totalAmountVat += booking.booklines[i]['amountVat'];
            }
        }
        
        booking.amountExclVat = totalAmountExclVat;
        booking.amountInclVat = totalAmountInclVat;
        booking.amountVat = totalAmountVat;

    },

}

// const getBookingTotal = ({booking, amountType, accountType, vattypeId}) => {
//     let total = 0;
//     for (let i = 0; i < booking.booklines.length; i++) {
//         const amount = booking.booklines[i][amountType];
//         if (amount) {

//             if (vattypeId && accountType) {
//                 if (accountType === booking.booklines[i].account.type && vattypeId === booking.booklines[i].vattype.id) {
//                     total += amount;
//                 }
//             } else if (accountType) {
//                 if (accountType === booking.booklines[i].account.type) {
//                     total += amount;
//                 }
//             } else if (vattypeId) {
//                 if (vattypeId === booking.booklines[i].vattype.id) {
//                     total += amount;
//                 }
//             } else {
//                 total += amount;
//             }
//         }
//     }
//     return total;
// };