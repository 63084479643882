var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.transaction.booking
    ? _c("div", { staticClass: "input-group input-group-sm" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.booking,
                expression: "booking"
              }
            ],
            staticClass: "custom-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.booking = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.bookings, function(booking, index) {
            return _c("option", { key: index, domProps: { value: booking } }, [
              _vm._v(_vm._s(_vm.bookingSelectString(booking)))
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.update }
            },
            [_c("i", { staticClass: "fas fa-check" })]
          )
        ])
      ])
    : _c("div", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.bookingSelectString(_vm.transaction.booking)) +
            "\n"
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }