<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{title()}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="years">{{getLocalMsg('global.years')}}</label>
                    <select class="form-control" id="years" v-model="selectedYear">
                        <option v-once v-for="(year, index) in years" v-bind:key="index" v-bind:value = "year">{{year}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{getLocalMsg('writeOff.investments')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.term')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.valueStart')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.purchases')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.writeOff')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.valueEnd')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.professionalShare')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.deductibleShare')}}</th>
                        <th class="text-right">{{getLocalMsg('writeOff.deductible')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="purchased.length>0" class="highlight-header-row">
                        <td colspan = "10" class="align-middle">{{getLocalMsg('writeOff.purchases')}}</td>
                    </tr>
                    <tr v-for="(writeOff, index) in purchased" v-bind:key="'purchased-' + index">
                        <td class="align-middle">{{purchaseDescription(writeOff.purchaseId)}}</td>
                        <td class="align-middle text-right">{{writeOff.purchase.term}}</td>
                        <td class="align-middle text-right">{{fixAmount(0,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueStart,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.writeOff,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueEnd,2)}}</td>
                        <td class="align-middle text-right">{{writeOff.professionalShare}}</td>
                        <td class="align-middle text-right">{{writeOff.deductibleShare}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.deductible,2)}}</td>
                        <td class="align-middle text-right">
                            <dropdown-menu
                                v-on:purchase="showModalPurchase(writeOff.purchaseId)"
                                v-on:writeoff="editWriteOff(writeOff.purchaseId)"
                            />   
                        </td>
                    </tr>
                    <tr v-if="others.length>0" class="highlight-header-row">
                        <td colspan = "10" class="align-middle">{{getLocalMsg('writeOff.writeOffs')}}</td>
                    </tr>
                    <tr v-for="(writeOff, index) in others" v-bind:key="'others-' + index">
                        <td class="align-middle">{{purchaseDescription(writeOff.purchaseId)}}</td>
                        <td class="align-middle text-right">{{writeOff.purchase.term}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueStart,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(0,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.writeOff,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueEnd,2)}}</td>
                        <td class="align-middle text-right">{{writeOff.professionalShare}}</td>
                        <td class="align-middle text-right">{{writeOff.deductibleShare}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.deductible,2)}}</td>
                        <td class="align-middle text-right">
                            <dropdown-menu
                                v-on:purchase="showModalPurchase(writeOff.purchaseId)"
                                v-on:writeoff="editWriteOff(writeOff.purchaseId)"
                            />   
                        </td>
                    </tr>
                    <tr v-if="sold.length>0" class="highlight-header-row">
                        <td colspan = "10" class="align-middle">{{getLocalMsg('writeOff.desinvestment')}}</td>
                    </tr>
                    <tr v-for="(writeOff, index) in sold" v-bind:key="'sold-' + index">
                        <td class="align-middle">{{purchaseDescription(writeOff.purchaseId)}}</td>
                        <td class="align-middle text-right">{{writeOff.purchase.term}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueStart,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(0,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.writeOff,2)}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.valueEnd,2)}}</td>
                        <td class="align-middle text-right">{{writeOff.professionalShare}}</td>
                        <td class="align-middle text-right">{{writeOff.deductibleShare}}</td>
                        <td class="align-middle text-right">{{fixAmount(writeOff.deductible,2)}}</td>
                        <td class="align-middle text-right">
                            <dropdown-menu
                                v-on:purchase="showModalPurchase(writeOff.purchaseId)"
                                v-on:writeoff="editWriteOff(writeOff.purchaseId)"
                            />   
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th class="align-middle"></th>
                        <th class="align-middle"></th>
                        <th class="align-middle text-right">{{fixAmount(totalValueStart,2)}}</th>
                        <th class="align-middle text-right">{{fixAmount(totalPurchased,2)}}</th>
                        <th class="align-middle text-right">{{fixAmount(totalWriteOff,2)}}</th>
                        <th class="align-middle text-right">{{fixAmount(totalValueEnd,2)}}</th>
                        <th class="align-middle text-right"></th>
                        <th class="align-middle text-right"></th>
                        <th class="align-middle text-right">{{fixAmount(totalDeductible,2)}}</th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <modal-template ref="modalPurchase" dialogClasses="modal-xxl modal-dialog-scrollable">
            <template v-slot:header>
                <h5>{{getLocalMsg('writeOff.purchase')}}</h5>
            </template>
            <template v-slot:body>
                <booking-items 
                    v-if="selectedPurchase.length > 0"
                    v-bind:book="selectedPurchase[0].book" 
                    v-bind:bookings="selectedPurchase"
                    v-bind:details="true"
                    v-bind:detailsRelation="true"
                ></booking-items>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
            </template>
        </modal-template>
        
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue';
import BookingItems from '../bookings/BookingItems.vue';
import DropdownMenu from '../../shared/DropdownMenu.vue';


export default {
    name: 'WriteOffList',
    mixins: [sharedMixin],
    props: {
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            selectedPurchase: [],
            // purchaseId : this.$route.params.purchaseId,
            year: this.getSelectionProperty('bookings','year')
        }
    },
    components: {
        ModalTemplate,
        BookingItems,
        DropdownMenu
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.writeoffs.loaded ? loaded : false;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            return loaded;
        },
        others () {
            return this.$store.getters['writeoffs/getOthersByYear'](this.selectedYear);
        },
        sold () {
            return this.$store.getters['writeoffs/getSoldByYear'](this.selectedYear);
        },
        purchased () {
            return this.$store.getters['writeoffs/getPurchasedByYear'](this.selectedYear);
        },
        totalValueStart () {
            return this.$store.getters['writeoffs/getTotalValueStartByYear'](this.selectedYear);
        },
        totalPurchased () {
            return this.$store.getters['writeoffs/getTotalPurchasedByYear'](this.selectedYear);
        },
        totalWriteOff () {
            return this.$store.getters['writeoffs/getTotalWriteOffByYear'](this.selectedYear);
        },
        totalValueEnd () {
            return this.$store.getters['writeoffs/getTotalValueEndByYear'](this.selectedYear);
        },
        totalDeductible () {
            return this.$store.getters['writeoffs/getTotalDeductibleByYear'](this.selectedYear);
        },

        years () {
            return this.getItems('years');
        },
        selectedYear: {
            get: function () {
                return this.getSelectionProperty('bookings','year');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','year',value);
            }   
        },
        // purchase () {
        //     return this.$store.state.bookings.items.find(booking => {
        //         return booking.booklines.find (bookline => {
        //             return Number(bookline.id) === Number(this.purchaseId);
        //         })
        //     })
        // },
        
    },
    methods: {
        
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }  
            this.$store.commit('showSpinner',false);
        },
        title () {
            return this.getLocalMsg('writeOff.title2');
        },
        purchase (purchaseId) {
            return this.$store.state.bookings.items.find(booking => {
                return booking.booklines.find (bookline => {
                    return Number(bookline.id) === Number(purchaseId);
                })
            })
        },
        purchaseDescription (purchaseId) {
            const purchase = this.purchase(purchaseId);
            let description = purchase.description ? purchase.description + ' / ' : '';
            description += purchase.relation.name;
            return description;
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        }, 

        showModalPurchase(purchaseId) {
            this.selectedPurchase = [this.purchase(purchaseId)];
            this.showModal('modalPurchase');
        }, 
        editWriteOff(purchaseId){
            this.$router.push('/admin/writeoff/' + purchaseId + '/edit')
        }

    }
}
</script>

<style lang="scss" scoped>

    .highlight-header-row {
        background-color: #3f556a !important;
        color: white;
    }

</style>
