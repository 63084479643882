<template>
    <fragment>
        <tr>
            <td v-if="book === 'P'" class="align-middle">
                <select v-model="account" class="form-control">
                    <option v-for="(account, index) in accounts" v-bind:key="index" v-bind:value = "account">{{account.name}}</option>
                </select>
            </td>
            <td class="align-middle">
                <select v-model="category" class="form-control">
                    <option v-for="(category, index) in categories" v-bind:key="index" v-bind:value = "category">{{category.name}}</option>
                </select>
            </td>
            <td class="align-middle jdl-td-sm">
                <select v-model="vattype" class="form-control">
                    <option v-once v-for="(vattype, index) in vattypes" v-bind:key="index" v-bind:value = "vattype">{{vattype.name}}</option>
                </select>
            </td>
            <td class="align-middle jdl-td-sm">
                <input type="text" :autocomplete="noAutoComplete.amountExclVat" class="form-control text-right" v-model.lazy="amountExclVat">
            </td>
            <td class="align-middle text-right jdl-td-xs" v-bind:class="{'not-applicable': !vatApplicable($store.state.bookings.activeItem).value}">
                {{amountVat}}
            </td>
            <td class="align-middle text-right jdl-td-sm" v-bind:class="{'not-applicable': !vatApplicable($store.state.bookings.activeItem).value}">
                {{amountInclVat}}
            </td>
            <td class="align-middle text-right jdl-td-xs"></td>
            <td v-if="book === 'P'" class="align-middle text-right jdl-td-sm">
                <div class="input-group">
                    <input type="text" :autocomplete="noAutoComplete.deductibleVatShare" class="form-control text-right"  v-bind:disabled="noVatDeclaration" v-model.lazy="deductibleVatShare">
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </td>
            <td v-if="book === 'P'" class="align-middle text-right jdl-td-sm">
                <div class="input-group">
                    <input type="text" :autocomplete="noAutoComplete.deductibleCostsShare" class="form-control text-right" v-model.lazy="deductibleCostsShare">
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>  
                </div>
            </td>
            <td class="text-right align-middle jdl-td-xs">
                <a v-on:click="deleteBookline" class="btn btn-sm btn-secondary">
                    {{getLocalMsg('global.delete')}}
                </a>
                <!-- <a href="#" id="navbardrop" data-toggle="dropdown">
                    <i class="fas fa-ellipsis-v"></i>
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Link 1</a>
                    <a class="dropdown-item" href="#">Link 2</a>
                    <a class="dropdown-item" href="#">Link 3</a>
                </div> -->
            </td>
        </tr>
        <tr v-if="account.type==='I'" class="extra-row">
            <td class="align-middle" colspan="10">
                <label for="term" class="ml-3">{{getLocalMsg('booking.term')}}</label>
                <input type="text" id="term" class="form-control form-control-sm text-right ml-2" v-model.lazy="term">

            </td>
        </tr>
    </fragment>
</template>

<script>

//TODO: Description toevoegen of volledig weg doen

import ModalTemplate from '../../shared/ModalTemplate.vue'
import sharedMixin from '../../mixins/shared-mixin';

export default {
    name: 'BooklineEdit',
    mixins: [sharedMixin],
    components: {
        ModalTemplate,
    },
    props: {
        id: {
            type: [Number, String],
            validator(value) {
                return Number.isInteger(Number(value));
            },
        },
    },
    data () {
        return {
            noAutoComplete: {
                amountExclVat: this.noAutoCompleteId(),
                deductibleVatShare: this.noAutoCompleteId(),
                deductibleCostsShare: this.noAutoCompleteId(),
            },
        }
    },
    computed: {

        noVatDeclaration() {
            return this.getItemProperty('bookings','noVatDeclaration');
        },
        deductibleVatShare: {
            get: function () {
                return this.getBooklineProperty('deductibleVatShare');
            },
            set: function (value) {
                value = this.roundAmount(value, 0);
                this.setBooklineProperty('deductibleVatShare',value).then(
                    () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                        () => this.$store.dispatch('bookings/setBookingTotals').then(
                                () => this.$store.dispatch('bookings/addBookline')
                            )
                    )
                );
            }   
        },
        // description: {
        //     get: function () {
        //         return this.getBooklineProperty('description');
        //     },
        //     set: function (value) {
        //         this.setBooklineProperty('description',value);

        //     }   
        // },
        deductibleCostsShare: {
            get: function () {
                return this.getBooklineProperty('deductibleCostsShare');
            },
            set: function (value) {
                value = this.roundAmount(value, 0);
                this.setBooklineProperty('deductibleCostsShare',value).then(
                    () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                        () => this.$store.dispatch('bookings/setBookingTotals').then(
                                () => this.$store.dispatch('bookings/addBookline')
                            )
                    )
                )
            }   
        },
        account: {
            get: function () {
                return this.getBooklineProperty('account');
            },
            set: function (value) {
                if(value.type!=='I'){
                    this.term = null;
                }
                this.setBooklineProperty('account',value).then(
                    () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                        () => this.$store.dispatch('bookings/setBookingTotals').then(
                            () => this.$store.dispatch('bookings/addBookline')
                        )
                    )
                );
            }   
        },
       term: {
            get: function () {
                return this.getBooklineProperty('term');
            },
            set: function (value) {
                this.setBooklineProperty('term',value);
            }  
        },
        category: {
            get: function () {
                return this.getBooklineProperty('category');
            },
            set: function (value) {
                this.setBooklineProperty('category',value).then(
                    () => this.$store.dispatch('bookings/setDefaultDeductibleVatAndCostsShare',this.id).then(
                        () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                            () => this.$store.dispatch('bookings/setBookingTotals').then(
                                () => this.$store.dispatch('bookings/addBookline')
                            )
                        )
                    )
                );
            }   
        },
        vattype: {
            get: function () {
                return this.getBooklineProperty('vattype');
            },
            set: function (value) {
                this.setBooklineProperty('vattype',value).then(
                    () => this.$store.dispatch('bookings/setRestAmount',this.id).then(
                        () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                            () => this.$store.dispatch('bookings/setBookingTotals').then(
                                () => this.$store.dispatch('bookings/addBookline')
                            )
                        )
                    )
                );
            }  
        },
        amountExclVat: {
            get: function () {
                const result = this.getBooklineProperty('amountExclVat');
                return this.fixAmount(result,2);
            },
            set: function (value) {
                if(typeof value == 'string') {
                    value = value.replace(',','.');
                }
                if(isNaN(parseInt(value))) {
                    value = null;
                } else {
                    //value = this.roundAmount(value, 2); -> uitgeschakeld 
                    //-> mag niet afgerond worden omdat hier automatisch het restbedrag wordt ingezet
                    //en er anders afwijking kan ontstaan tov het factuurtotaal
                    value = Number(value);
                    
                }
                this.setBooklineProperty('amountExclVat',value).then(
                    () => this.$store.dispatch('bookings/applyCalculations',this.id).then(
                        () => this.$store.dispatch('bookings/setBookingTotals').then(
                            () => this.$store.dispatch('bookings/addBookline')
                        )
                    )
                );
            }  
        },
        amountInclVat () {
            const result = this.getBooklineProperty('amountInclVat');
            return this.fixAmount(result,2);
        },
        amountVat () {
            const result = this.getBooklineProperty('amountVat');
            return this.fixAmount(result,2);
        },
        accounts(){
            return this.getItems('accounts');
        },
        categories(){
            return this.getItems('categories');
        },
        vattypes(){
            return this.getItems('vattypes');
        },
        lastBookline () {
            let booklines = this.getItemProperty('bookings','booklines');
            return booklines[booklines.length-1];
        },
        book () {
            return this.getItemProperty('bookings','book');  
        },
    },
    methods: {
        getBooklineProperty (property) {
            const data = {
                booklineId: this.id,
                property: property
            };
            return this.$store.getters['bookings/getBooklineProperty'](data);
        },
        setBooklineProperty(property,value){
            const data = {
                booklineId: this.id,
                property: property,
                value: value
            };
            return this.$store.dispatch('bookings/setBooklineProperty',data);
        },
        deleteBookline(){
            const data = {
                booklineId: this.id,
            };
            this.$store.dispatch('bookings/deleteBookline',data).then(
                ()=> {
                    if(this.lastBookline){
                        return this.$store.dispatch('bookings/setRestAmount',this.lastBookline.id).then(
                            () => this.$store.dispatch('bookings/applyCalculations',this.lastBookline.id).then(
                                () => this.$store.dispatch('bookings/setBookingTotals')
                            )
                        )    
                    } else {
                        return this.$store.dispatch('bookings/setBookingTotals').then(
                            () => this.$store.dispatch('bookings/addBookline')
                        )
                    }
                }
            );
        },
    },
}
</script>

<style lang="scss" scoped>
    .jdl-td-sm {
        width:130px;
        //margin:0;
    }
    .jdl-td-xs {
        width:80px;
        //margin:0;
    }
    .extra-row td{
        border-top:0;
        padding-top:0;
    }

    .extra-row input {
        width: 50px !important;
        display: inline-block;
    }
    .extra-row label {
        font-size: 13px;
    } 

</style>
