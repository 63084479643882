var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "navbar navbar-expand-sm bg-dark navbar-dark" }, [
      _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
        _vm._v(_vm._s(_vm.getLocalMsg("global.siteName").toUpperCase()))
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/dashboard")
                }
              }
            },
            [_c("i", { staticClass: "fas fa-home" })]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: { href: "#", "data-toggle": "dropdown" }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getLocalMsg("navAdmin.purchases")) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/purchases")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.overviewPurchases")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/suppliers")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.overviewSuppliers")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/purchases/categories")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.getLocalMsg("navAdmin.overviewPurchaseCategories"))
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: { href: "#", "data-toggle": "dropdown" }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getLocalMsg("navAdmin.sales")) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/sales")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.overviewSales")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/clients")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.overviewClients")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/sales/categories")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.getLocalMsg("navAdmin.overviewSalesCategories"))
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/transactions")
                }
              }
            },
            [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.transactions")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: { href: "#", "data-toggle": "dropdown" }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getLocalMsg("navAdmin.results")) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/stockchanges")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.stockchanges")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/lockedperiods")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.lockedperiods")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/vatdeclaration")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.vatDeclaration")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/writeoffs")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.writeOffs")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$router.push("/admin/incomestatement")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.incomeStatement")))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav ml-auto" }, [
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: { href: "#", "data-toggle": "dropdown" }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.activeCompany.name) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dropdown-menu" },
            [
              _vm.companies.length !== 1
                ? _c(
                    "fragment",
                    [
                      _vm._l(_vm.companies, function(company, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.activeCompany = company
                              }
                            }
                          },
                          [_vm._v(_vm._s(company.name))]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push("/admin/registercompany")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("navAdmin.registerCompany")))]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: { href: "#", "data-toggle": "dropdown" }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.userName) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.logout($event)
                  }
                }
              },
              [_vm._v("Logout")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "logout",
            staticStyle: { display: "none" },
            attrs: { action: "/logout", method: "POST" }
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "_token" },
              domProps: { value: _vm.csrf }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }