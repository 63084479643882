var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropleft btn-dots" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-lg dropdown-toggle",
        class: _vm.darkOrLightMode,
        attrs: { type: "button", "data-toggle": "dropdown" }
      },
      [_c("i", { staticClass: "fas fa-ellipsis-v" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown-menu" }, [
      _vm.hasViewListener
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  return _vm.$emit("view")
                }
              }
            },
            [
              _c("i", { staticClass: "far fa-eye" }),
              _c("span", [_vm._v(_vm._s(_vm.getLocalMsg("global.view")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasEditListener
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  return _vm.$emit("edit")
                }
              }
            },
            [
              _c("i", { staticClass: "far fa-edit" }),
              _c("span", [_vm._v(_vm._s(_vm.getLocalMsg("global.edit")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDeleteListener
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  return _vm.$emit("delete")
                }
              }
            },
            [
              _c("i", { staticClass: "far fa-trash-alt" }),
              _c("span", [_vm._v(_vm._s(_vm.getLocalMsg("global.delete")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPurchaseListener
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  return _vm.$emit("purchase")
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-shopping-bag" }),
              _c("span", [_vm._v(_vm._s(_vm.getLocalMsg("global.purchase")))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasWriteOffListener
        ? _c(
            "a",
            {
              staticClass: "dropdown-item",
              on: {
                click: function($event) {
                  return _vm.$emit("writeoff")
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-table" }),
              _c("span", [
                _vm._v(_vm._s(_vm.getLocalMsg("global.writeOffTable")))
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }