import Helpers from '../../../../admin/helpers';
import bookingtotals from './bookingtotals';

const mainMutations = {

    /**
     * Eigenschappen van een boeklijn wijzigen in de actieve boeking
     * Bijkomend wordt telkens een vlag gezet om aan te duiden dat de actieve boeking gewijzigd is
     * Op deze vlag kan getest worden om te bepalen of er wijzigingen naar de backend weggeschreven moeten worden
     * @param {*} state 
     * @param {object} param1 met id van de boeklijn, de eigenschap en de waarde die gezet moet worden  
     */
    setBooklineProperty(state, {booklineId,property,value}) {
        const item = state.activeItem;
        const bookline = Helpers.getItem(item.booklines, booklineId);
        bookline[property] = value;
        item.isChanged = true;
    },
    /**
     * Boeklijn verwijderen in de actieve boeking
     * Bijkomend wordt telkens een vlag gezet om aan te duiden dat de actieve boeking gewijzigd is
     * Op deze vlag kan getest worden om te bepalen of er wijzigingen naar de backend weggeschreven moeten worden
     * @param {*} state 
     * @param {*} param1  met id van de boeklijn
     */
    deleteBookline(state, {booklineId}) {
        const booking = state.activeItem;
        state.activeItem.booklines = state.activeItem.booklines.filter(item => {
            return item.id !== booklineId;
        })
        booking.isChanged = true;
    },
    /**
     * Nieuwe lege bookline toevoegen.Id is negatief en incrementeel om geen conflict te hebben met de id 's uit de database
     * @param {*} state 
     * @param {object} bookline
     */
    //
    addBookline(state, {bookline}) {
        const item = state.activeItem;
        item.booklines.push(bookline);
        item.addedBooklineCounter++;

    },

};

export default {...mainMutations, ...bookingtotals};