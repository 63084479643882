var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.title()))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "years" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.years")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedYear,
                        expression: "selectedYear"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "years" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedYear = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._m(0),
                  0
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table table-hover" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.investments")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.term")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.valueStart")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.purchases")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.writeOff")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.valueEnd")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(
                      _vm._s(_vm.getLocalMsg("writeOff.professionalShare"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.deductibleShare")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.deductible")))
                  ]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm.purchased.length > 0
                    ? _c("tr", { staticClass: "highlight-header-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "align-middle",
                            attrs: { colspan: "10" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("writeOff.purchases"))
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.purchased, function(writeOff, index) {
                    return _c("tr", { key: "purchased-" + index }, [
                      _c("td", { staticClass: "align-middle" }, [
                        _vm._v(
                          _vm._s(_vm.purchaseDescription(writeOff.purchaseId))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.purchase.term))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(0, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueStart, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.writeOff, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueEnd, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.professionalShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.deductibleShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.deductible, 2)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-middle text-right" },
                        [
                          _c("dropdown-menu", {
                            on: {
                              purchase: function($event) {
                                return _vm.showModalPurchase(
                                  writeOff.purchaseId
                                )
                              },
                              writeoff: function($event) {
                                return _vm.editWriteOff(writeOff.purchaseId)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _vm.others.length > 0
                    ? _c("tr", { staticClass: "highlight-header-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "align-middle",
                            attrs: { colspan: "10" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("writeOff.writeOffs"))
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.others, function(writeOff, index) {
                    return _c("tr", { key: "others-" + index }, [
                      _c("td", { staticClass: "align-middle" }, [
                        _vm._v(
                          _vm._s(_vm.purchaseDescription(writeOff.purchaseId))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.purchase.term))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueStart, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(0, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.writeOff, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueEnd, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.professionalShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.deductibleShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.deductible, 2)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-middle text-right" },
                        [
                          _c("dropdown-menu", {
                            on: {
                              purchase: function($event) {
                                return _vm.showModalPurchase(
                                  writeOff.purchaseId
                                )
                              },
                              writeoff: function($event) {
                                return _vm.editWriteOff(writeOff.purchaseId)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _vm.sold.length > 0
                    ? _c("tr", { staticClass: "highlight-header-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "align-middle",
                            attrs: { colspan: "10" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("writeOff.desinvestment"))
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.sold, function(writeOff, index) {
                    return _c("tr", { key: "sold-" + index }, [
                      _c("td", { staticClass: "align-middle" }, [
                        _vm._v(
                          _vm._s(_vm.purchaseDescription(writeOff.purchaseId))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.purchase.term))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueStart, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(0, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.writeOff, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.valueEnd, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.professionalShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(writeOff.deductibleShare))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-right" }, [
                        _vm._v(_vm._s(_vm.fixAmount(writeOff.deductible, 2)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-middle text-right" },
                        [
                          _c("dropdown-menu", {
                            on: {
                              purchase: function($event) {
                                return _vm.showModalPurchase(
                                  writeOff.purchaseId
                                )
                              },
                              writeoff: function($event) {
                                return _vm.editWriteOff(writeOff.purchaseId)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("tfoot", [
                _c("tr", [
                  _c("th", { staticClass: "align-middle" }),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle" }),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.totalValueStart, 2)))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.totalPurchased, 2)))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.totalWriteOff, 2)))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.totalValueEnd, 2)))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }),
                  _vm._v(" "),
                  _c("th", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.totalDeductible, 2)))
                  ]),
                  _vm._v(" "),
                  _c("th")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalPurchase",
            attrs: { dialogClasses: "modal-xxl modal-dialog-scrollable" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(_vm._s(_vm.getLocalMsg("writeOff.purchase")))
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _vm.selectedPurchase.length > 0
                        ? _c("booking-items", {
                            attrs: {
                              book: _vm.selectedPurchase[0].book,
                              bookings: _vm.selectedPurchase,
                              details: true,
                              detailsRelation: true
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.close")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1838548799
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.years, function(year, index) {
      return _c("option", { key: index, domProps: { value: year } }, [
        _vm._v(_vm._s(year))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }