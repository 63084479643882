import Helpers from '../admin/helpers';

export default {

    /**
     * Show loading spinner
     * @param {*} state 
     */
    showSpinner (state, value) {
        state.showSpinner = value;
    },
    /**
     * Vlag zetten om te bepalen of de data voor en bepaalde module geladen is;
     * @param {*} state 
     * @param {*} param1 
     */
    setLoadedState(state, {moduleName, loaded}) {
        state[moduleName].loaded = loaded;
    },
    /**
     * Indien zich een error voordoet wordt deze hier weggeschreven naar de state
     * @param {*} state 
     * @param {*} error 
     */
    handleError(state, errorData) {

        state.errors.hasError = true;

        state.errors.code = errorData.code;
        state.errors.subCode = errorData.subCode;
        state.errors.message = errorData.message;
        state.errors.technicalMessage = errorData.technicalMessage;

        if (errorData.message === 'Unauthenticated.') {
            state.errors.code = 'ERR_UNAUTHENTICATED';
            state.errors.technicalMessage = errorData.message;
        }

        if (errorData.message === 'Server Error') {
            state.errors.code = 'ERR_SERVER';
            state.errors.technicalMessage = errorData.message;
        }

        if (errorData.message === 'Too Many Attempts.') {
            state.errors.code = 'ERR_SERVER';
            state.errors.technicalMessage = errorData.message;
        }

    },
    /**
     * Error in de state wordt gereset
     * @param {} state 
     */
    dismissError(state) {
        state.errors.hasError = false;
        state.errors.message = '';
    },
    /**
     * Opvullen van de userinfo obv gefetchte data
     * @param {*} state 
     * @param {*} param1 
     */
    fetchUser(state, {data}) {
        state.user.name = data.name;
        state.user.email = data.email;
        state.user.companies = data.companies;
        state.user.selection.activeCompany = data.activeCompany;
        state.user.loaded = true;
        Helpers.log('user is loaded')
    },
    /**
     * Opvullen van de array van items in de state obv gefetchte data
     * @param {*} state 
     * @param {*} param1 
     */
    fetchData(state, {moduleName,data}) {
        state[moduleName].items = data;
        state[moduleName].loaded = true;
        Helpers.log(moduleName + ' are loaded')
    },
    /**
     * Vervangen van 1 item in de state door gefetcht item
     * @param {*} state 
     * @param {*} param1 
     */
    updateItem(state, {moduleName, data}) {
        const oldItem = Helpers.getItem(state[moduleName].items, data.id);
        const oldItemIndex = state[moduleName].items.indexOf(oldItem);
        state[moduleName].items.splice(oldItemIndex, 1, data)
        state[moduleName].activeItem=data;
        console.log('gelukt, item updated in ' + moduleName);
        console.log(state[moduleName].activeItem)
    },
    /**
     * Toevoegen van een nieuw item in de state (als eerste)
     * @param {*} state 
     * @param {*} param1 
     */
    createItem(state, {moduleName, data}) {
        state[moduleName].items.unshift(data);
        state[moduleName].activeItem = data;
        console.log('gelukt, item created in ' + moduleName);
        console.log(state[moduleName].activeItem)
    },
    /**
     * Verwijderen van 1 item uit de state
     * @param {*} state 
     * @param {*} param1 
     */
    deleteItem(state, { moduleName, data }) {
        const item = Helpers.getItem(state[moduleName].items, data.id);
        const itemIndex = state[moduleName].items.indexOf(item);
        state[moduleName].items.splice(itemIndex, 1)
        state[moduleName].activeItem = {};
        console.log('gelukt, item deleted in ' + moduleName);
    },
    /**
     * Een item in de state als actief item zetten (vb bij editeren)
     * @param {*} state 
     * @param {*} param1 
     */
    setActiveItem(state, {moduleName, item}) {
        state[moduleName].activeItem = item;
    },
    /**
     * Zetten van actieve selectie in de state (vb. geselecteerde maand, jaar, boek, ...)
     * Opslaan van selecties in local storage
     * @param {*} state 
     * @param {*} param1 
     */
    setSelectionProperty(state, {moduleName,property,value}) {

        const selection = state[moduleName].selection;
        selection[property] = value;

        
    },
    /**
     * Zetten van een property in het actieve item in de state
     * @param {*} state 
     * @param {*} param1 
     */
    setItemProperty(state, {moduleName,property,value}) {
        const item = state[moduleName].activeItem;
        item[property] = value;
        item.isChanged = true;
    },

};


