var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _c(
          "div",
          [
            _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "box bg-white mb-4" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getLocalMsg("companies.info")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box bg-white" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        { attrs: { for: _vm.noAutoComplete.companyStartDate } },
                        [_vm._v(_vm._s(_vm.getLocalMsg("companies.startDate")))]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          autocomplete: _vm.noAutoComplete.companyStartDate,
                          id: _vm.noAutoComplete.companyStartDate,
                          config: _vm.dateTimePickerOptions
                        },
                        model: {
                          value: _vm.companyStartDate,
                          callback: function($$v) {
                            _vm.companyStartDate = $$v
                          },
                          expression: "companyStartDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.companyVatNumber } },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("companies.vatNumber")) +
                            " (" +
                            _vm._s(_vm.getLocalMsg("global.notRequired")) +
                            ")"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.companyVatNumber,
                          expression: "companyVatNumber",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.companyVatNumber,
                        id: _vm.noAutoComplete.companyVatNumber,
                        placeholder: _vm.getLocalMsg(
                          "companies.vatNumberPlaceHolder",
                          { siteName: _vm.getLocalMsg("global.siteName") }
                        )
                      },
                      domProps: { value: _vm.companyVatNumber },
                      on: {
                        change: function($event) {
                          _vm.companyVatNumber = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.vatNumberValidationText
                      ? _c(
                          "small",
                          {
                            staticClass: "form-text text-muted fg-color-primary"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(this.vatNumberValidationText) +
                                "\n                        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "company-type" } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("companies.type")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.companyType,
                            expression: "companyType"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "company-type" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.companyType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._m(0),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check mt-1 mb-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.companyVatExempt,
                          expression: "companyVatExempt"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        tabindex: "-1",
                        id: "company-vat-exempt"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.companyVatExempt)
                          ? _vm._i(_vm.companyVatExempt, null) > -1
                          : _vm.companyVatExempt
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.companyVatExempt,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.companyVatExempt = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.companyVatExempt = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.companyVatExempt = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "company-vat-exempt" }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getLocalMsg("companies.vatExempt")))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.companyName } },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.name")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.companyName,
                          expression: "companyName",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.companyName,
                        id: _vm.noAutoComplete.companyName
                      },
                      domProps: { value: _vm.companyName },
                      on: {
                        change: function($event) {
                          _vm.companyName = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.companyName } },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.name")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.companyName,
                          expression: "companyName",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.companyName,
                        id: _vm.noAutoComplete.companyName
                      },
                      domProps: { value: _vm.companyName },
                      on: {
                        change: function($event) {
                          _vm.companyName = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { tabindex: "0" },
                        on: { click: _vm.validateAndUpdateItem }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.register")))]
                    )
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.companyTypes, function(companyType, index) {
      return _c("option", { key: index, domProps: { value: companyType } }, [
        _vm._v(_vm._s(companyType.name))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }