export default {
    namespaced: true,
    state: {
        items: [],
        loaded: false,
        activeItem: {},
        selection: {
            type:''
        }
    },
};