var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fragment", [
    _c("tr", [
      _vm.book === "P"
        ? _c("td", { staticClass: "align-middle" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account,
                    expression: "account"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.account = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.accounts, function(account, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: account } },
                  [_vm._v(_vm._s(account.name))]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "align-middle" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.category,
                expression: "category"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.category = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.categories, function(category, index) {
            return _c("option", { key: index, domProps: { value: category } }, [
              _vm._v(_vm._s(category.name))
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "align-middle jdl-td-sm" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.vattype,
                expression: "vattype"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.vattype = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._m(0),
          0
        )
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "align-middle jdl-td-sm" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.lazy",
              value: _vm.amountExclVat,
              expression: "amountExclVat",
              modifiers: { lazy: true }
            }
          ],
          staticClass: "form-control text-right",
          attrs: {
            type: "text",
            autocomplete: _vm.noAutoComplete.amountExclVat
          },
          domProps: { value: _vm.amountExclVat },
          on: {
            change: function($event) {
              _vm.amountExclVat = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "align-middle text-right jdl-td-xs",
          class: {
            "not-applicable": !_vm.vatApplicable(
              _vm.$store.state.bookings.activeItem
            ).value
          }
        },
        [_vm._v("\n            " + _vm._s(_vm.amountVat) + "\n        ")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "align-middle text-right jdl-td-sm",
          class: {
            "not-applicable": !_vm.vatApplicable(
              _vm.$store.state.bookings.activeItem
            ).value
          }
        },
        [_vm._v("\n            " + _vm._s(_vm.amountInclVat) + "\n        ")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "align-middle text-right jdl-td-xs" }),
      _vm._v(" "),
      _vm.book === "P"
        ? _c("td", { staticClass: "align-middle text-right jdl-td-sm" }, [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.deductibleVatShare,
                    expression: "deductibleVatShare",
                    modifiers: { lazy: true }
                  }
                ],
                staticClass: "form-control text-right",
                attrs: {
                  type: "text",
                  autocomplete: _vm.noAutoComplete.deductibleVatShare,
                  disabled: _vm.noVatDeclaration
                },
                domProps: { value: _vm.deductibleVatShare },
                on: {
                  change: function($event) {
                    _vm.deductibleVatShare = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c("span", { staticClass: "input-group-text" }, [_vm._v("%")])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.book === "P"
        ? _c("td", { staticClass: "align-middle text-right jdl-td-sm" }, [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.deductibleCostsShare,
                    expression: "deductibleCostsShare",
                    modifiers: { lazy: true }
                  }
                ],
                staticClass: "form-control text-right",
                attrs: {
                  type: "text",
                  autocomplete: _vm.noAutoComplete.deductibleCostsShare
                },
                domProps: { value: _vm.deductibleCostsShare },
                on: {
                  change: function($event) {
                    _vm.deductibleCostsShare = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c("span", { staticClass: "input-group-text" }, [_vm._v("%")])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "text-right align-middle jdl-td-xs" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-secondary",
            on: { click: _vm.deleteBookline }
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.getLocalMsg("global.delete")) +
                "\n            "
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.account.type === "I"
      ? _c("tr", { staticClass: "extra-row" }, [
          _c("td", { staticClass: "align-middle", attrs: { colspan: "10" } }, [
            _c("label", { staticClass: "ml-3", attrs: { for: "term" } }, [
              _vm._v(_vm._s(_vm.getLocalMsg("booking.term")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.term,
                  expression: "term",
                  modifiers: { lazy: true }
                }
              ],
              staticClass: "form-control form-control-sm text-right ml-2",
              attrs: { type: "text", id: "term" },
              domProps: { value: _vm.term },
              on: {
                change: function($event) {
                  _vm.term = $event.target.value
                }
              }
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.vattypes, function(vattype, index) {
      return _c("option", { key: index, domProps: { value: vattype } }, [
        _vm._v(_vm._s(vattype.name))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }