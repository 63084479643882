<template>
    <div>
    </div>
</template>

<script>

export default {
    name: 'Redirect',
    created () {
        console.log('redirected to ' + this.$route.params.path);
        this.$router.replace(this.$route.params.path);
    }
}
</script>

<style >
</style>
