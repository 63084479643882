<template>
    <div class="dropleft btn-dots">
        <button v-bind:class="darkOrLightMode" class="btn btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
            <i class="fas fa-ellipsis-v"></i>
        </button>
        <div class="dropdown-menu">
            <a v-if="hasViewListener" v-on:click="$emit('view')" class="dropdown-item"><i class="far fa-eye"></i><span>{{getLocalMsg('global.view')}}</span></a>
            <a v-if="hasEditListener" v-on:click="$emit('edit')" class="dropdown-item"><i class="far fa-edit"></i><span>{{getLocalMsg('global.edit')}}</span></a>
            <a v-if="hasDeleteListener" v-on:click="$emit('delete')" class="dropdown-item"><i class="far fa-trash-alt"></i><span>{{getLocalMsg('global.delete')}}</span></a>
            <a v-if="hasPurchaseListener" v-on:click="$emit('purchase')" class="dropdown-item"><i class="fas fa-shopping-bag"></i><span>{{getLocalMsg('global.purchase')}}</span></a>
            <a v-if="hasWriteOffListener" v-on:click="$emit('writeoff')" class="dropdown-item"><i class="fas fa-table"></i><span>{{getLocalMsg('global.writeOffTable')}}</span></a>
        </div>
    </div>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';


export default {
    name: 'DropdownMenu',
    mixins: [sharedMixin],
    props: {
        propDarkMode: Boolean
    },
    computed: {
        darkOrLightMode () {
            return this.propDarkMode ? 'dark-mode' : 'light-mode';
        },
        hasViewListener(){
            return this.$listeners && this.$listeners.view
        },
        hasEditListener(){
            return this.$listeners && this.$listeners.edit
        },
        hasDeleteListener(){
            return this.$listeners && this.$listeners.delete
        },
        hasPurchaseListener(){
            return this.$listeners && this.$listeners.purchase
        },
        hasWriteOffListener(){
            return this.$listeners && this.$listeners.writeoff
        }
    }
}

</script>

<style lang="scss" scoped>

    .btn-dots {
        margin-top: -5px;

    }

    .btn-dots button, .btn-dots button:active, .btn-dots button:focus {
        background-color: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        padding:0;
    }

    .dark-mode {
        color: white !important;
    }

    .light-mode {
        color: #40464d !important;
    }

    .btn-dots button::before {
        display: none;
    }

    .btn-dots i {
        width:30px;
    }

</style>
