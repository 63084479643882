       import Helpers from '../../../../admin/helpers';
       import calculations from './calculations';

       const mainActions = {
            /**
             * Eigenschap van de boeklijn aanpassen
             * @param {*} param0 
             * @param {*} param1 
             */
            setBooklineProperty({state,commit}, {booklineId,property,value}) {
                commit('setBooklineProperty', {booklineId,property,value});
            },
            /**
             * Nieuwe lege bookline toevoegen als alles correct is ingevuld en het totaal van de factuur
             * niet overeenkomt met de onderliggende boeklijnen
             * Na het toevoegen van de boeklijn worden nog een aantal acties uitgevoerd:
             * - De rekening, categorie en het btw-type worden automatisch ingevuld indien er defaults 
             *   ingevuld zijn bij de relatie en de categorie
             * - Het restbedrag wordt ingevuld
             * - De bedragen (incl btw, aftrekbaar, ...) in de boeklijn worden berekend
             * - De totalen voor de boeking worden berekend
             * @param {*} param0 
             */
            addBookline({state, commit, dispatch}) {

                const item = state.activeItem;

                //Controle of header van de boeking correct is ingevuld
                if (!Helpers.bookingHeaderIsValid(item)){
                    return false;
                }

                // Controle of alle boeklijnen correct zijn ingevuld
                for(let i = 0; i < item.booklines.length;i++) {
                    if (!Helpers.booklineIsValid(item.booklines[i])) {
                        return false;
                    }
                }


                //Controle of totalen verschillend zijn
                if (!Helpers.totalsNotMatching(item)) {
                    return false;
                }

                // Id is negatief en incrementeel om geen conflict te hebben met de id 's uit de database
                const booklineId = -(item.addedBooklineCounter + 1);
                
                const bookline = {
                    isNew: true,
                    id: booklineId,
                    account: {},
                    category: {},
                    description: null,
                    amountExclVat: null,
                    amountInclVat: null,
                    amountVat: null,
                    deductibleVatShare: 100,
                    deductibleCostsShare: 100,
                    vattype: {},
                    deductibleVat: null,
                    deductibleCosts: null,
                    term: null
                }
                //Commit nieuwe boeklijn
                commit('addBookline', {bookline});
                dispatch('setDefaultAccount', booklineId).then(
                    () => dispatch('setDefaultCategory', booklineId).then(
                        () => dispatch('setDefaultVattype', booklineId).then(
                            () => dispatch('setRestAmount', booklineId).then(
                                () => dispatch('applyCalculations', booklineId).then(
                                    () => dispatch('setBookingTotals')
                                )
                            )
                        )
                    )
                );

                return true;
            },
           /**
            * Nieuwe lege bookline toevoegen als alles correct is ingevuld en het totaal van de factuur
            * niet overeenkomt met de onderliggende boeklijnen
            * Na het toevoegen van de boeklijn worden nog een aantal acties uitgevoerd:
            * - De rekening, categorie en het btw-type worden automatisch ingevuld indien er defaults 
            *   ingevuld zijn bij de relatie en de categorie
            * - Het restbedrag wordt ingevuld
            * - De bedragen (incl btw, aftrekbaar, ...) in de boeklijn worden berekend
            * - De totalen voor de boeking worden berekend
            * @param {*} param0 
            */
           recalcBookline({ state, commit, dispatch }) {

               const item = state.activeItem;

               //Controle of header van de boeking correct is ingevuld
               if (!Helpers.bookingHeaderIsValid(item)) {
                   return false;
               }

               // Controle of alle boeklijnen correct zijn ingevuld
               for (let i = 0; i < item.booklines.length; i++) {
                   if (!Helpers.booklineIsValid(item.booklines[i])) {
                       return false;
                   }
               }

               //Controle of totalen verschillend zijn
               if (!Helpers.totalsNotMatching(item)) {
                   return false;
               }

               if (item.booklines.length === 0) {
                   //Nieuwe boeklijn toevoegen als er nog geen bestaat.
                   dispatch('addBookline');
               }else {
                   //Resterende bedrag toepassen op de laatste bookline en de lijn opnieuw berekenen 
                   //het boekingtotaal ook opnieuw berekenen
                    const booklineId = item.booklines[item.booklines.length - 1].id;
                    dispatch('setRestAmount', booklineId).then(
                        () => dispatch('applyCalculations', booklineId).then(
                            () => dispatch('setBookingTotals')
                        )
                    );
                }

               return true;
           },
            /**
             * De rekening wordt automatisch ingevuld 
             * Voor verkopen is dit altijd omzet
             * Voor aankopen wordt de standaardrekening van de relatie ingevuld, indien gekend
             * @param {*} param0 
             * @param {*} booklineId 
             */
            setDefaultAccount({state, rootState, commit}, booklineId) {

                const item = state.activeItem;
                const bookline = Helpers.getItem(item.booklines, booklineId);
                let account = {};

                //Voor verkopen wordt automatisch de rekening Omzet ingevuld
                if(item.book === 'S')
                    account = rootState.accounts.items.find(item => {
                        return item.type === 'R';
                    });

                if (item.book === 'P'){
                    if (item.relation) {
                        if (item.relation.defaultAccount) {
                            account = item.relation.defaultAccount;
                        }
                    }
                }

                if (bookline) {
                    commit('setBooklineProperty', {
                        booklineId: bookline.id,
                        property: 'account',
                        value: account
                    });
                }

            },
            /**
             * De Categorie wordt automatisch ingevuld obv de defaultcategorie van de relatie
             * 
             * @param {*} param0 
             * @param {*} booklineId 
             */
            setDefaultCategory({state,commit,dispatch}, booklineId) {
                const item = state.activeItem;
                const bookline = Helpers.getItem(item.booklines, booklineId);
                let defaultCategory = {};
                if (item.relation) {
                    if (item.relation.defaultCategory) {
                        defaultCategory = item.relation.defaultCategory;
                    }
                }
                if (bookline) {
                    commit('setBooklineProperty', {
                        booklineId: bookline.id,
                        property: 'category',
                        value: defaultCategory
                    });
                    dispatch('setDefaultDeductibleVatAndCostsShare',bookline.id);
                }

            },
            /**
             * Het BTW-type wordt automatisch ingevuld obv het standaard btwtype van de categorie
             * @param {*} param0 
             * @param {*} booklineId 
             */
            setDefaultVattype({state,commit}, booklineId) {

                const item = state.activeItem;
                const bookline = Helpers.getItem(item.booklines, booklineId);

                let defaultVattype = {};
                if (item.relation) {
                    if (item.relation.defaultVattype) {
                        defaultVattype = item.relation.defaultVattype;
                    }
                }

                if (bookline) {
                    commit('setBooklineProperty', {
                        booklineId: bookline.id,
                        property: 'vattype',
                        value: defaultVattype
                    });
                }

            },
            /**
             * Verwijderen van een boeklijn in de actieve boeking
             * @param {*} param0 
             * @param {*} param1 
             */
            deleteBookline({commit,dispatch}, {booklineId}) {
                
                commit('deleteBookline', {booklineId});
            },
            /**
             * Alle boeklijnen van de actieve boeking worden verwijderd
             * @param {*} param0 
             */
            // deleteAllBooklines({commit,dispatch}) {
            //     commit('deleteAllBooklines');
            // },

       }

       export default {...mainActions, ...calculations};
