       
export default {

    /**
     * Totaal ophalen voor een specifiek BTW-rooster
     * Selectie jaar en periode gebeurt obv selection properties in bookings.state
     */
    getVatcodeTotal: (state) => (vatcode) => {

        const bookings = state.items.filter(item => {
            return (
                item.year === state.selection.year &&
                item.period === state.selection.period
            )
        });

        let initialValue = 0;
        const result = bookings.reduce((accumulator, currentValue) => {
            const vcode = currentValue.vatcodes.find(item => {
                return Number(item.code) === Number(vatcode);
            })

            let newValue = 0;
            if (vcode) {
                newValue = vcode.amount
            }

            return accumulator + newValue;
        }, initialValue);

        return result;
    },

}


