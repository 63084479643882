<template>
    <div v-if="loaded">  
        <div v-if="hasCompanies">
            <nav-admin />
            <div class="wrapper">
                <router-view/>
            </div>
        </div>
        <div v-else>
            <nav-site />
            <div class="wrapper">
                <register-company/>
            </div>
        </div>
    </div>
</template>

<script>

import NavAdmin from './NavAdmin.vue'
import NavSite from './NavSite.vue'
import RegisterCompany from '../admin/companies/RegisterCompany.vue'

export default {
  components: {
    RegisterCompany,
    NavAdmin,
    NavSite
  },
  watch: {
    $route: function () {
        this.initiate(this.loaded);
    },
    loaded: {
        handler(val) {
            this.initiate(val)
        },
        immediate: true
    }
},
  computed: {
    loaded () {
        let loaded = true;
        loaded = this.$store.state.user.loaded ? loaded : false;
        return loaded;
    },  
    hasCompanies () {
        return this.$store.state.user.companies.length;
    }
  },
  methods: {
    initiate (loaded) {
        if(!loaded) {
            this.$store.dispatch('fetchUser');
            return;
        }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>>

