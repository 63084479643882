var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [
              _vm._v(
                _vm._s(
                  _vm.book === "P"
                    ? _vm.getLocalMsg("booking.purchaseId")
                    : _vm.getLocalMsg("booking.saleId")
                )
              )
            ]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("global.year")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("global.period")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("booking.invoiceDate")))]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(
                  _vm.book === "P"
                    ? _vm.getLocalMsg("booking.supplier")
                    : _vm.getLocalMsg("booking.client")
                )
              )
            ]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("booking.reference")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("booking.description")))]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.getLocalMsg("booking.amountExclVat")))
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.getLocalMsg("booking.amountVat")))
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.getLocalMsg("booking.amountInclVat")))
            ]),
            _vm._v(" "),
            _c("th")
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.bookings, function(booking) {
              return [
                _c(
                  "tr",
                  { key: "header-" + booking.id, staticClass: "header-row" },
                  [
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "popover",
                            rawName: "v-popover",
                            value: _vm.popoverTechInfo(booking),
                            expression: "popoverTechInfo(booking)"
                          }
                        ],
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(booking.refNumber))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(booking.year))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("global." + booking.period))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(_vm.formatDate(booking.invoiceDate)))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(booking.relation.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(booking.reference))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [_vm._v(_vm._s(booking.description))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle text-right",
                        class: { "highlight-header-row": _vm.details }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.fixAmount(booking.invoiceAmountExclVat, 2))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle text-right",
                        class: {
                          "highlight-header-row": _vm.details,
                          "not-applicable": !_vm.vatApplicable(booking).value
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.fixAmount(booking.invoiceAmountVat, 2))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "align-middle text-right",
                        class: {
                          "highlight-header-row": _vm.details,
                          "not-applicable": !_vm.vatApplicable(booking).value
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.fixAmount(booking.invoiceAmountInclVat, 2))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.lockedPeriod(booking.year, booking.period)
                      ? _c(
                          "td",
                          {
                            staticClass: "align-middle text-right",
                            class: { "highlight-header-row": _vm.details }
                          },
                          [
                            _c("dropdown-menu", {
                              attrs: { propDarkMode: true },
                              on: {
                                edit: function($event) {
                                  return _vm.editBooking(booking.id)
                                },
                                delete: function($event) {
                                  return _vm.showDeleteModal(booking)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lockedPeriod(booking.year, booking.period)
                      ? _c(
                          "td",
                          {
                            staticClass: "align-middle text-right",
                            class: { "highlight-header-row": _vm.details }
                          },
                          [_c("i", { staticClass: "fas fa-lock" })]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.details
                  ? _c("tr", { key: "extra-info-" + booking.id }, [
                      _c("td", { attrs: { colspan: "11" } }, [
                        _c(
                          "div",
                          {
                            attrs: { id: "extra-info-accordion-" + booking.id }
                          },
                          [
                            _c("div", { staticClass: "card" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "card-header collapsed",
                                  attrs: {
                                    "data-toggle": "collapse",
                                    "data-target":
                                      "#collapse-four-" + booking.id,
                                    id: "heading-four"
                                  }
                                },
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    booking.transactions.length > 0
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-chevron-down mr-2"
                                          })
                                        ])
                                      : _c("span", { staticClass: "mr-4" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "mr-4" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getLocalMsg("booking.payments")
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    booking.invoiceAmountInput >
                                    _vm.transactionsTotal(booking)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "float-right btn btn-sm btn-primary",
                                            on: {
                                              click: function($event) {
                                                return _vm.showTransactionModal(
                                                  booking
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "booking.addPayment"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    booking.invoiceAmountInput >
                                    _vm.transactionsTotal(booking)
                                      ? _c(
                                          "span",
                                          { staticClass: "badge badge-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "booking.notPaid"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    booking.invoiceAmountInput ==
                                    _vm.transactionsTotal(booking)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "badge badge-success"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg("booking.paid")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    booking.invoiceAmountInput <
                                    _vm.transactionsTotal(booking)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "badge badge-warning"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "booking.overPaid"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              booking.transactions.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "collapse-four-" + booking.id,
                                        "data-parent":
                                          "#extra-info-accordion-" + booking.id
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card-body" }, [
                                        _c("div", { staticClass: "pl-4" }, [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table table-borderless"
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "transaction.reference"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "transaction.transactionDate"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "transaction.transactionAccount"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "transaction.description"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getLocalMsg(
                                                            "transaction.amount"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("th")
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  booking.transactions,
                                                  function(transaction) {
                                                    return _c(
                                                      "tr",
                                                      { key: transaction.id },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              transaction.reference
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                transaction.transactionDate
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              transaction
                                                                .transactionAccount
                                                                .name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              transaction.description
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.fixAmount(
                                                                  transaction.amount,
                                                                  2
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "mt-n1 float-right btn btn-sm",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteTransaction(
                                                                    transaction
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "far fa-trash-alt"
                                                              })
                                                            ]
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.detailsBooking
                              ? _c("div", { staticClass: "card" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header collapsed",
                                      attrs: {
                                        "data-toggle": "collapse",
                                        "data-target":
                                          "#collapse-one-" + booking.id,
                                        id: "heading-one"
                                      }
                                    },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c("i", {
                                          staticClass: "fa fa-chevron-down mr-2"
                                        }),
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.getLocalMsg("booking.details")
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.book === "P"
                                                ? _vm.getLocalMsg(
                                                    "booking.purchase"
                                                  )
                                                : _vm.getLocalMsg(
                                                    "booking.sale"
                                                  )
                                            ) +
                                            "\n                                        "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "collapse-one-" + booking.id,
                                        "data-parent":
                                          "#extra-info-accordion-" + booking.id
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card-body" }, [
                                        _c("div", { staticClass: "pl-4" }, [
                                          _c("p", [
                                            _vm.book === "P"
                                              ? _c("span", [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "booking.professionalShare"
                                                        )
                                                      ) + ":"
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.fixAmount(
                                                          booking.professionalShare,
                                                          0
                                                        )
                                                      ) +
                                                      " %"
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            booking.noVatDeclaration
                                              ? _c("span", [
                                                  _c("br"),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "booking.noVatDeclaration"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.vatApplicable(booking)
                                                    .text
                                                )
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table table-borderless"
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _vm.book === "P"
                                                    ? _c("th", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getLocalMsg(
                                                              "booking.account"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "booking.category"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "booking.vattype"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getLocalMsg(
                                                            "booking.amountExclVat"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getLocalMsg(
                                                            "booking.amountVat"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getLocalMsg(
                                                            "booking.amountInclVat"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLocalMsg(
                                                                "booking.deductibleVat"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c("th", {
                                                        staticClass:
                                                          "text-right"
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLocalMsg(
                                                                "booking.deductibleCosts"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c("th", {
                                                        staticClass:
                                                          "text-right"
                                                      })
                                                    : _vm._e()
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  booking.booklines,
                                                  function(bookline) {
                                                    return _c(
                                                      "tr",
                                                      { key: bookline.id },
                                                      [
                                                        _vm.book === "P"
                                                          ? _c("td", [
                                                              _vm._v(
                                                                "\n                                                                " +
                                                                  _vm._s(
                                                                    bookline
                                                                      .account
                                                                      .name
                                                                  ) +
                                                                  "\n                                                                "
                                                              ),
                                                              bookline.account
                                                                .type === "I"
                                                                ? _c("div", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-link",
                                                                        attrs: {
                                                                          type:
                                                                            "button"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.editWriteOff(
                                                                              bookline.id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getLocalMsg(
                                                                              "booking.term"
                                                                            )
                                                                          ) +
                                                                            ": " +
                                                                            _vm._s(
                                                                              bookline.term
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              bookline.category
                                                                ? bookline
                                                                    .category
                                                                    .name
                                                                : ""
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              bookline.vattype
                                                                .name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.fixAmount(
                                                                  bookline.amountExclVat,
                                                                  2
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                            class: {
                                                              "not-applicable": !_vm.vatApplicable(
                                                                booking
                                                              ).value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.fixAmount(
                                                                  bookline.amountVat,
                                                                  2
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                            class: {
                                                              "not-applicable": !_vm.vatApplicable(
                                                                booking
                                                              ).value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.fixAmount(
                                                                  bookline.amountInclVat,
                                                                  2
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.book === "P"
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                                class: {
                                                                  "not-applicable": !_vm.vatApplicable(
                                                                    booking
                                                                  ).value
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.fixAmount(
                                                                      bookline.deductibleVat,
                                                                      2
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.book === "P"
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                                class: {
                                                                  "not-applicable": !_vm.vatApplicable(
                                                                    booking
                                                                  ).value
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.fixAmount(
                                                                      bookline.deductibleVatShare,
                                                                      0
                                                                    )
                                                                  ) + " %"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.book === "P"
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.fixAmount(
                                                                      bookline.deductibleCosts,
                                                                      2
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.book === "P"
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.fixAmount(
                                                                      bookline.deductibleCostsShare,
                                                                      0
                                                                    )
                                                                  ) + " %"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c("tfoot", [
                                                _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLocalMsg(
                                                          "global.total"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c("th")
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("th"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fixAmount(
                                                            booking.amountExclVat,
                                                            2
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right",
                                                      class: {
                                                        "not-applicable": !_vm.vatApplicable(
                                                          booking
                                                        ).value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fixAmount(
                                                            booking.amountVat,
                                                            2
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass: "text-right",
                                                      class: {
                                                        "not-applicable": !_vm.vatApplicable(
                                                          booking
                                                        ).value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fixAmount(
                                                            booking.amountInclVat,
                                                            2
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          class: {
                                                            "not-applicable": !_vm.vatApplicable(
                                                              booking
                                                            ).value
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fixAmount(
                                                                booking.deductibleVat,
                                                                2
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c("th", {
                                                        staticClass:
                                                          "text-right"
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fixAmount(
                                                                booking.deductibleCosts,
                                                                2
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.book === "P"
                                                    ? _c("th", {
                                                        staticClass:
                                                          "text-right"
                                                      })
                                                    : _vm._e()
                                                ])
                                              ])
                                            ]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detailsRelation
                              ? _c("div", { staticClass: "card" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header collapsed",
                                      attrs: {
                                        "data-toggle": "collapse",
                                        "data-target":
                                          "#collapse-two-" + booking.id,
                                        id: "heading-two"
                                      }
                                    },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c("i", {
                                          staticClass: "fa fa-chevron-down mr-2"
                                        }),
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.getLocalMsg("booking.details")
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.book === "P"
                                                ? _vm.getLocalMsg(
                                                    "booking.supplier"
                                                  )
                                                : _vm.getLocalMsg(
                                                    "booking.client"
                                                  )
                                            ) +
                                            "\n                                        "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "collapse-two-" + booking.id,
                                        "data-parent":
                                          "#extra-info-accordion-" + booking.id
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card-body" }, [
                                        _c("div", { staticClass: "pl-4" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _c("p", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.book === "P"
                                                        ? _vm.getLocalMsg(
                                                            "relation.supplierId"
                                                          )
                                                        : _vm.getLocalMsg(
                                                            "relation.clientId"
                                                          )
                                                    ) + ":"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      booking.relation.refNumber
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getLocalMsg(
                                                        "relation.type"
                                                      )
                                                    ) + ":"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      booking.relation
                                                        .relationtype.name
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getLocalMsg(
                                                        "relation.name"
                                                      )
                                                    ) + ":"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      booking.relation.name
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getLocalMsg(
                                                        "relation.vatNumber"
                                                      )
                                                    ) + ":"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      booking.relation.vatnumber
                                                    )
                                                ),
                                                _c("br")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "col" }, [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.street"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    booking.relation.street
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.postcode"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    booking.relation.postcode
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.city"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(booking.relation.city)
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.country"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    booking.relation.country
                                                  )
                                              ),
                                              _c("br")
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "col" }, [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.website"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    booking.relation.website
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLocalMsg(
                                                      "relation.email"
                                                    )
                                                  ) + ":"
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(booking.relation.email)
                                              ),
                                              _c("br")
                                            ])
                                          ])
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            booking.vatcodes.length > 0 && _vm.detailsVatCodes
                              ? _c("div", { staticClass: "card" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header collapsed",
                                      attrs: {
                                        "data-toggle": "collapse",
                                        "data-target":
                                          "#collapse-three-" + booking.id,
                                        id: "heading-three"
                                      }
                                    },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c("i", {
                                          staticClass: "fa fa-chevron-down mr-2"
                                        }),
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.getLocalMsg(
                                                "booking.vatCodes"
                                              )
                                            ) +
                                            "\n                                        "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse",
                                      attrs: {
                                        id: "collapse-three-" + booking.id,
                                        "data-parent":
                                          "#extra-info-accordion-" + booking.id
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card-body" }, [
                                        _c(
                                          "div",
                                          { staticClass: "pl-4" },
                                          _vm._l(booking.vatcodes, function(
                                            vatcode
                                          ) {
                                            return _c(
                                              "p",
                                              { key: vatcode.code },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getLocalMsg(
                                                        "booking.code"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(vatcode.code) +
                                                      ": "
                                                  )
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.fixAmount(
                                                      vatcode.amount,
                                                      2
                                                    )
                                                  ) +
                                                    "\n                                                "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("modal-template", {
        ref: "modalDelete",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm.deleteWithRenumbering
                  ? _c("div", [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.header",
                              { book: _vm.bookName(true, true) }
                            )
                          )
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withoutRenumbering.header",
                              { book: _vm.bookName(true, true) }
                            )
                          )
                        )
                      ])
                    ])
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm.deleteWithRenumbering
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.body1",
                              {
                                books: _vm.bookName(false, true),
                                book: _vm.bookName(true, true)
                              }
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.body2"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "booking.delete.withRenumbering.body3"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "booking.delete.withRenumbering.body4",
                                { book: _vm.bookName(true, true) }
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.body5",
                              { book: _vm.bookName(true, true) }
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.body6"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withRenumbering.body7",
                              {
                                books: _vm.bookName(false, true),
                                book: _vm.bookName(true, true)
                              }
                            )
                          )
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg(
                              "booking.delete.withoutRenumbering.body",
                              { book: _vm.bookName(true, true) }
                            )
                          )
                        )
                      ])
                    ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _vm.deleteWithRenumbering
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function($event) {
                              return _vm.deleteBooking(_vm.selectedBooking)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "booking.delete.withRenumbering.deleteButton"
                              )
                            )
                          )
                        ]
                      )
                    ])
                  : _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function($event) {
                              return _vm.deleteBooking(_vm.selectedBooking)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "booking.delete.withoutRenumbering.deleteButton"
                              )
                            )
                          )
                        ]
                      )
                    ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("modal-template", {
        ref: "modalTransaction",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("div", [
                  _c("h5", [_vm._v(_vm._s(_vm.getLocalMsg("transaction.add")))])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c("transaction-edit", {
                  key: _vm.forceRerenderKey,
                  ref: "transactionEdit",
                  attrs: {
                    propTransaction: _vm.selectedTransaction,
                    propBooking: _vm.selectedBooking,
                    propTransactionTypeCode: _vm.selectedBooking.book
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.updateTransaction }
                    },
                    [_vm._v(_vm._s(_vm.getLocalMsg("transaction.update")))]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }