import { render, staticRenderFns } from "./ModalTemplate.vue?vue&type=template&id=70a3c4fb&scoped=true&"
import script from "./ModalTemplate.vue?vue&type=script&lang=js&"
export * from "./ModalTemplate.vue?vue&type=script&lang=js&"
import style0 from "./ModalTemplate.vue?vue&type=style&index=0&id=70a3c4fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a3c4fb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\User\\Documents\\webdev\\bookit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70a3c4fb')) {
      api.createRecord('70a3c4fb', component.options)
    } else {
      api.reload('70a3c4fb', component.options)
    }
    module.hot.accept("./ModalTemplate.vue?vue&type=template&id=70a3c4fb&scoped=true&", function () {
      api.rerender('70a3c4fb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/shared/ModalTemplate.vue"
export default component.exports