<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('vatDeclaration.title')}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="years">{{getLocalMsg('global.years')}}</label>
                    <select class="form-control" id="years" v-model="selectedYear">
                        <option v-once v-for="(year, index) in years" v-bind:key="index" v-bind:value = "year">{{year}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="periods">{{getLocalMsg('global.periods')}}</label>
                    <select class="form-control" id="periods" v-model="selectedPeriod">
                        <option v-once v-for="(period, index) in periods" v-bind:key="index" v-bind:value = "period">{{getLocalMsg('global.' + period)}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table">
                <tbody>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box2')}}</th>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2A')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(0)">{{getLocalMsg('vatDeclaration.box2A00')}}</button></td>                        
                        <td class="text-right">{{fixAmount(vatcode(0),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2B')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(1)">{{getLocalMsg('vatDeclaration.box2B01')}}</button></td>                        
                        <td class="text-right">{{fixAmount(vatcode(1),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(2)">{{getLocalMsg('vatDeclaration.box2B02')}}</button></td>                        
                        <td class="text-right">{{fixAmount(vatcode(2),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(3)">{{getLocalMsg('vatDeclaration.box2B03')}}</button></td>                        
                        <td class="text-right">{{fixAmount(vatcode(3),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2C')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(44)">{{getLocalMsg('vatDeclaration.box2C44')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(44),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2D')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(45)">{{getLocalMsg('vatDeclaration.box2D45')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(45),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2E')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(46)">{{getLocalMsg('vatDeclaration.box2E46')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(46),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2F')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(47)">{{getLocalMsg('vatDeclaration.box2F47')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(47),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box2G')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(48)">{{getLocalMsg('vatDeclaration.box2G48')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(48),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(49)">{{getLocalMsg('vatDeclaration.box2G49')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(49),2)}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box3')}}</th>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box3A')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(81)">{{getLocalMsg('vatDeclaration.box3A81')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(81),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(82)">{{getLocalMsg('vatDeclaration.box3A82')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(82),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(83)">{{getLocalMsg('vatDeclaration.box3A83')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(83),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box3B')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(84)">{{getLocalMsg('vatDeclaration.box3B84')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(84),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(85)">{{getLocalMsg('vatDeclaration.box3B85')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(85),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box3C')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(86)">{{getLocalMsg('vatDeclaration.box3C86')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(86),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box3D')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(87)">{{getLocalMsg('vatDeclaration.box3D87')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(87),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box3E')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(88)">{{getLocalMsg('vatDeclaration.box3E88')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(88),2)}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box4')}}</th>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box4A')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(54)">{{getLocalMsg('vatDeclaration.box4A54')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(54),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(55)">{{getLocalMsg('vatDeclaration.box4A55')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(55),2)}}</td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(56)">{{getLocalMsg('vatDeclaration.box4A56')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(56),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box4B')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(57)">{{getLocalMsg('vatDeclaration.box4B57')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(57),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box4C')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(61)">{{getLocalMsg('vatDeclaration.box4C61')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(61),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box4D')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(63)">{{getLocalMsg('vatDeclaration.box4D63')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(63),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box4E')}}</strong></td>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box4EXX')}}</td>
                        <td class="text-right">{{fixAmount(vatcodeXX,2)}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box5')}}</th>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box5A')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(59)">{{getLocalMsg('vatDeclaration.box5A59')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(59),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box5B')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(62)">{{getLocalMsg('vatDeclaration.box5B62')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(62),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box5C')}}</strong></td>
                    </tr>
                    <tr>
                        <td><button type="button" class="btn btn-link" v-on:click="showModalDetail(64)">{{getLocalMsg('vatDeclaration.box5C64')}}</button></td> 
                        <td class="text-right">{{fixAmount(vatcode(64),2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>{{getLocalMsg('vatDeclaration.box5D')}}</strong></td>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box5DYY')}}</td>
                        <td class="text-right">{{fixAmount(vatcodeYY,2)}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box6')}}</th>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box671')}}</td>
                        <td class="text-right">{{fixAmount(vatcode71,2)}}</td>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box672')}}</td>
                        <td class="text-right">{{ fixAmount(vatcode72,2)}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box7')}}</th>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box791')}}</td>
                        <td class="text-right">{{getLocalMsg('vatDeclaration.leaveEmpty')}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{getLocalMsg('vatDeclaration.box8')}}</th>
                    </tr>
                    <tr>
                        <td>{{getLocalMsg('vatDeclaration.box8KL')}}</td>
                        <td class="text-right">{{getLocalMsg('vatDeclaration.none')}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <modal-template ref="modalDetail" dialogClasses="modal-xxl modal-dialog-scrollable">
            <template v-slot:header>
                <h5>{{getLocalMsg('vatDeclaration.detailVatcode')}} {{selectedVatcode}}</h5>
            </template>
            <template v-slot:body>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{getLocalMsg('booking.bookingId')}}</th>
                            <th>{{getLocalMsg('booking.invoiceDate')}}</th>
                            <th>{{getLocalMsg('booking.relation')}}</th>
                            <th>{{getLocalMsg('booking.reference')}}</th>
                            <th>{{getLocalMsg('booking.description')}}</th>
                            <th class="text-right">{{getLocalMsg('booking.amount')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="booking in selectedBookings">
                            <tr class = "header-row" v-bind:key="booking.id">
                                <td class="align-middle"><button type="button" class="btn btn-link" v-on:click="showModalBooking(booking.id)">{{booking.book === 'P' ? getLocalMsg('booking.purchase') : getLocalMsg('booking.sale')}} {{booking.refNumber}}</button></td>
                                <td class="align-middle">{{formatDate(booking.invoiceDate)}}</td>
                                <td class="align-middle">{{booking.relation.name}}</td>
                                <td class="align-middle">{{booking.reference}}</td>
                                <td class="align-middle">{{booking.description}}</td>
                                <td class="align-middle text-right">{{fixAmount(booking.vatcodes.find(item=>{return Number(item.code)===Number(selectedVatcode)}).amount,2)}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table> 
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
            </template>
        </modal-template>
        <modal-template ref="modalBooking" dialogClasses="modal-xxl modal-dialog-scrollable">
            <template v-slot:header>
                <h5>{{getLocalMsg('vatDeclaration.detailBooking')}}</h5>
            </template>
            <template v-slot:body>
                <booking-items 
                    v-if="selectedBooking.length > 0"
                    v-bind:book="selectedBooking[0].book" 
                    v-bind:bookings="selectedBooking"
                    v-bind:details="true"
                    v-bind:detailsRelation="true"
                ></booking-items>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
            </template>
        </modal-template>
    </div>
    
</template>

<script>



import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue';
import BookingItems from '../bookings/BookingItems.vue';

export default {
    name: 'VatDeclaration',
    mixins: [sharedMixin],
    watch: {
       $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data(){
        return {
            selectedBooking: [],
            selectedBookings: [],
            selectedVatcode: null
        }
    },
    components: {
        ModalTemplate,
        BookingItems
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.periods.loaded ? loaded : false;
            loaded = this.$store.state.years.loaded ? loaded : false;
            return loaded;
        },
        years () {
            return this.getItems('years');
        },
        periods () {
            return this.getItems('periods');
        },
        selectedYear: {
            get: function () {
                return this.getSelectionProperty('bookings','year');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','year',value);
            }   
        },
        selectedPeriod: {
            get: function () {
                return this.getSelectionProperty('bookings','period');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','period',value);
            }   
        },
        vatcodeYY(){
            return (this.vatcode(59) + this.vatcode(62) + this.vatcode(64));
        },
        vatcodeXX(){
            return (this.vatcode(54) + this.vatcode(55) + this.vatcode(56) + this.vatcode(57) + this.vatcode(61) + this.vatcode(63));
        },
        vatcode71(){
            let x = this.vatcodeXX - this.vatcodeYY;
            if(x > 0) {
                return x;
            } else {
                return 0;
            }
        },
        vatcode72(){
            let x = this.vatcodeYY - this.vatcodeXX;
            if(x > 0) {
                return x;
            } else {
                return 0;
            }
        }
        
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        vatcode(vatcode){
            return this.$store.getters['bookings/getVatcodeTotal'](vatcode);
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        showModalDetail(vatcode) {

            this.selectedVatcode = vatcode;
            this.selectedBookings = this.$store.state.bookings.items
                .filter(bookingItem=>{
                    const result = bookingItem.vatcodes.filter(vatcodeItem=>{
                        return Number(vatcodeItem.code) === Number(vatcode)
                    })
                    return (
                        bookingItem.year === this.selectedYear &&
                        bookingItem.period === this.selectedPeriod &&
                        result.length > 0
                    )
            });
            this.showModal('modalDetail');
        },      
        showModalBooking(bookingId) {

            this.selectedBooking = this.selectedBookings
                .filter(bookingItem=>{
                    return bookingItem.id === bookingId;
            });
            this.showModal('modalBooking');
        },   
    }
}

</script>

<style lang="scss" scoped>

    .btn-link {
        padding: 0;
    }
</style>
