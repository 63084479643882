<template>
    <label class="switch">
        <input type="checkbox" v-on:click="$emit('toggle',$event.target.checked)" v-bind:checked="propChecked">
        <span class="slider" v-bind:class="classList"></span>
    </label>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';

export default {
    name: 'ToggleSwitch',
    mixins: [sharedMixin],
    props: {
        propChecked: Boolean,
        propRounded: Boolean
    },
    computed: {
        classList () {
            return {
                'round': this.propRounded
            }
        },
    }
}

</script>

<style lang="scss" scoped>

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-bottom: 0;
        zoom:0.7;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #56c0a6;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #56c0a6;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

</style>
