var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "topDiv" } }),
      _vm._v(" "),
      _vm.showSpinner
        ? _c("div", { staticClass: "spinner-centered" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      this.$router.history.current.name !== "404"
        ? _c("modal-template", {
            ref: "modalError",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      !_vm.mailSent && _vm.errorHeader
                        ? _c("h5", { staticClass: "modal-title" }, [
                            _vm._v(_vm._s(_vm.errorHeader))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.mailSent
                        ? _c("h5", { staticClass: "modal-title" }, [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("error.mailSentHeader"))
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      !_vm.mailSent && _vm.errorBody
                        ? _c("p", [_vm._v(_vm._s(_vm.errorBody))])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.mailSent && _vm.errorTechnicalMessage
                        ? _c("p", [
                            _c("small", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.errorTechnicalTitle))
                              ]),
                              _c("br"),
                              _vm._v(_vm._s(_vm.errorTechnicalMessage))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.mailSent && _vm.errorFooter1
                        ? _c("p", [_vm._v(_vm._s(_vm.errorFooter1))])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.mailSent && _vm.errorFooter2
                        ? _c("p", [_vm._v(_vm._s(_vm.errorFooter2))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.mailSent
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("error.mailSentBody"))
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _vm.mailSent || _vm.errorReloadPageButton
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: { click: _vm.reloadPage }
                            },
                            [_vm._v(_vm._s(_vm.errorReloadPageButton))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.mailSent && _vm.errorSendMailButton
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: { click: _vm.sendMail }
                            },
                            [_vm._v(_vm._s(_vm.errorSendMailButton))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.mailSent && _vm.errorOkButton
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: { click: _vm.hideErrorModal }
                            },
                            [_vm._v(_vm._s(_vm.errorOkButton))]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2798950226
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }