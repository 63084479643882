<template>
  <div v-if="loaded">  
    <nav-site />
    <div class="wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>

import NavSite from './NavSite.vue'

export default {
  components: {
    NavSite
  },
  watch: {
    $route: function () {
        // this.initiate(this.loaded);
    },
    loaded: {
        // handler(val) {
        //     this.initiate(val)
        // },
        // immediate: true
    }
},
  computed: {
    loaded () {
        let loaded = true;
        // loaded = this.$store.state.user.loaded ? loaded : false;
        return loaded;
    },  
  },
  methods: {
    initiate (loaded) {
        // if(!loaded) {
        //     this.$store.dispatch('fetchUser');
        //     return;
        // }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>>

