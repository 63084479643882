import axios from 'axios';
import { reject } from 'lodash';
//import { helpers } from 'chart.js';
import Helpers from '../admin/helpers';

export default {
    
    /**
     * Fetch informatie over de aangelogde user vanuit de backend via axios
     * @param {*} param0 
     */
    fetchUser({state,commit}) {
        if (state.user.loaded) {
            return Promise.resolve('user already Loaded');
        }
        return axios.get('/api/user')
            .then(result => {
                commit('fetchUser', {
                    data: result.data.data
                })
            })
            .catch(error => {
                commit('fetchUser', {
                    data: {
                        name: '',
                        email: '',
                        companies: [],
                        activeCompany: ''
                    }
                })
                console.log(error);
                // commit('handleError', error.response.data);
            });
    },
    checkUserStatus({ state }) {
        const user = state.user;
        return axios.get('/api/user')
            .then(result => {
                const fetchedUserEmail = result.data.data.email;
                const fetchedActiveCompanyId = result.data.data.activeCompany.id;
                if (fetchedUserEmail !== user.email) {
                    return Promise.reject();
                }
                if (fetchedActiveCompanyId !== user.selection.activeCompany.id) {
                    return Promise.reject();
                }
                return Promise.resolve ();
            })
            .catch(() => {
                const error = {
                    code: 'ERR_SESSION_EXPIRED'
                }
                return Promise.reject(error);
            });
    },
        
    /**
     * Fetch data voor de opgegeven module vanuit de backend via axios
     * @param {*} param0 
     * @param {*} moduleName 
     */
    fetchData({state,commit}, moduleName) {
        if(state[moduleName].loaded){
            return Promise.resolve(moduleName + ' already Loaded');
        }
        return axios.get('/api/' + moduleName.toLowerCase())
            .then(result => {
                commit('fetchData', {
                    moduleName: moduleName, 
                    data: result.data.data
                })
            })
            .catch(error => {
                commit('handleError', error.response.data);
            });
    },
    /**
     * Zetten van de actieve company
     * In de backend
     * Indien we in een editscherm zitten, teruggaan naar lijst 
     * om te voorkomen dat er edits gebeuren op de verkeerde company
     * @param {*} param0 
     * @param {*} activeCompanyId 
     */
    setActiveCompany({commit,dispatch}, activeCompanyId) {

        const checkUserStatus = dispatch('checkUserStatus');

        const setActiveCompany = checkUserStatus.then(() => {
            return axios.post('/api/setactivecompany/' + activeCompanyId)
            .catch(error => {
                throw error.response.data;
            });
        }).catch(error => {
            throw error;
        });

        return setActiveCompany
        .catch(error => {
            commit('handleError', error);
            throw error;
        })  

    },
    /**
     * Zetten van de actieve company
     * In de backend
     * Indien we in een editscherm zitten, teruggaan naar lijst 
     * om te voorkomen dat er edits gebeuren op de verkeerde company
     * @param {*} param0 
     * @param {*} activeCompanyId 
     */
    createCompany({ commit, dispatch }, item) {

        return axios.post('/api/company/create', item)
        .then(() => {
            commit('setLoadedState', {
                moduleName: 'user',
                loaded: false
            });
            return dispatch('fetchUser');
        })
        .catch(error => {
            commit('handleError', error.response.data);
            throw error.response.data;
        });

    },
    /**
     * 
     * Selectie property zetten in de opgegeven module
     * Deze selectie kan in alle componenten gebruikt worden
     * @param {*} param0 
     * @param {*} param1 
     */
    setSelectionProperty({commit}, {moduleName,property,value}) {
        commit('setSelectionProperty', {moduleName,property,value});
    },
    /**
     * Zetten van een property in het actieve item van de gekozen module
     * Bijvoorbeeld zetten van de relatie in de actieve boeking
     * 
     * @param {*} param0 
     * @param {*} param1 
     */
    setItemProperty({commit}, {moduleName,property,value}) { 
        commit('setItemProperty', {moduleName,property,value});
    },
    /**
     * Zetten van het actieve item in de gekozen module
     * Bijvoorbeeld bij editeren van een boeking, wordt deze boeking het actieve item
     * Bij nieuw item:
     * - Bepalen van het referentienr (uniek nummer voor aankoop en verkoopsboeking, klant of leverancier, ...)
     * - Nieuw item aanmaken met referentienr en parameters uit state.selection
     * Voor reeds bestaand item:
     * - Dit item toekennen als activeItem
     * @param {*} param0 
     * @param {*} param1 
     */
    setActiveItem({commit,getters,state}, {moduleName,newItem,item}) {
        //In geval van toevoegen van een item (vb boeking of relatie)
        if (newItem) {
            let refNumber = null;
            let data = {};
            switch (moduleName) { 
                case 'relations':
                    refNumber = getters['getNewRefNumber']('relations');
                    data = {
                        clientSupplier: state.relations.selection.clientSupplier,
                        refNumber: refNumber
                    }
                    item = newRelation(data);
                    break;
                case 'bookings':
                    refNumber = getters['getNewRefNumber']('bookings');
                    data = {
                        period: state.bookings.selection.period,
                        year: state.bookings.selection.year,
                        book: state.bookings.selection.book,
                        refNumber: refNumber
                    }
                    item = newBooking(data);
                    break;
               case 'categories':
                    data = {
                        type: state.categories.selection.type
                    }
                    item = newCategory(data);
                    break;
                case 'transactions':
                    item = newTransaction();
                    break;
            }      
        }
        commit('setActiveItem', {
            moduleName: moduleName,
            item: JSON.parse(JSON.stringify(item))
        })
    },
    updateBooking({state, dispatch}) {
        
        let bookingBase = state.bookings.activeItem;
        let booking = {};
        booking.id = bookingBase.id;
        booking.isNew = bookingBase.isNew;
        booking.isChanged = bookingBase.isChanged;
        booking.description = bookingBase.description;
        booking.reference = bookingBase.reference;
        booking.period = bookingBase.period;
        booking.year = bookingBase.year;
        booking.book = bookingBase.book;

        booking.relationId = bookingBase.relation.id;
        booking.invoiceDate = bookingBase.invoiceDate;
        booking.invoiceAmountInput = bookingBase.invoiceAmountInput;
        booking.vatShifted = bookingBase.vatShifted;
        booking.noVatDeclaration = bookingBase.noVatDeclaration;
        booking.professionalShare = bookingBase.professionalShare;
        booking.booklines = [];

        for(let i = 0; i < bookingBase.booklines.length; i++) {
            booking.booklines.push({
                accountId: bookingBase.booklines[i].account.id,
                categoryId: bookingBase.booklines[i].category.id,
                vattypeId: bookingBase.booklines[i].vattype.id,
                description: bookingBase.booklines[i].description,
                amountExclVat: bookingBase.booklines[i].amountExclVat,
                deductibleVatShare: bookingBase.booklines[i].deductibleVatShare,
                deductibleCostsShare: bookingBase.booklines[i].deductibleCostsShare,
                term: bookingBase.booklines[i].term,
            })
        }
        return dispatch('updateItem',{
            moduleName: 'bookings',
            item: booking
        })

    },
    updateRelation({state, dispatch}) {
        
        let relationBase = state.relations.activeItem;
        let relation = {};

        relation.id = relationBase.id;
        relation.isNew = relationBase.isNew;
        relation.isChanged = relationBase.isChanged;
        relation.name = relationBase.name; 
        relation.relationtypeId = relationBase.relationtype.id;
        relation.vatnumber = relationBase.vatnumber; 
        relation.clientSupplier = relationBase.clientSupplier; 
        relation.street = relationBase.street; 
        relation.postcode = relationBase.postcode; 
        relation.city = relationBase.city; 
        relation.country = relationBase.country; 
        relation.website = relationBase.website; 
        relation.email = relationBase.email; 

        if (relationBase.defaultAccount && !Helpers.emptyObject(relationBase.defaultAccount)){
            relation.defaultAccountId = relationBase.defaultAccount.id;
        }
        if (relationBase.defaultCategory && !Helpers.emptyObject(relationBase.defaultCategory)) {
            relation.defaultCategoryId = relationBase.defaultCategory.id;
        }
        if (relationBase.defaultVattype && !Helpers.emptyObject(relationBase.defaultVattype)) {
            relation.defaultVattypeId = relationBase.defaultVattype.id;
        }
        return dispatch('updateItem',{
            moduleName: 'relations',
            item: relation
        });

    },
    updateCategory({ state, dispatch }) {

        let categoryBase = state.categories.activeItem;
        let category = {};

        category.id = categoryBase.id;
        category.isNew = categoryBase.isNew;
        category.isChanged = categoryBase.isChanged;
        category.name = categoryBase.name;
        category.type = categoryBase.type;
        category.defaultDeductibleVatShare = categoryBase.defaultDeductibleVatShare;
        category.defaultDeductibleCostsShare = categoryBase.defaultDeductibleCostsShare;

        return dispatch('updateItem', {
            moduleName: 'categories',
            item: category
        });
    
    },
    updateTransaction({ state, dispatch }) {

        let transactionBase = state.transactions.activeItem;
        let transaction = {};

        transaction.id = transactionBase.id;
        transaction.isNew = transactionBase.isNew;
        transaction.isChanged = transactionBase.isChanged;
        transaction.isDeleted = transactionBase.isDeleted;
        transaction.description = transactionBase.description;
        transaction.transactionDate = transactionBase.transactionDate;
        transaction.reference = transactionBase.reference;
        transaction.factor = transactionBase.factor;
        if (transactionBase.booking && !Helpers.emptyObject(transactionBase.booking)) {
            transaction.bookingId = transactionBase.booking.id;
        }
        transaction.transactionAccountId = transactionBase.transactionAccount.id;
        transaction.transactionTypeId = transactionBase.transactionType.id;
        transaction.amount = transactionBase.amount;

        console.log(transaction, transactionBase);

        return dispatch('updateItem', {
            moduleName: 'transactions',
            item: transaction
        });

    },
    /**
     * Updaten van het actieve item in de database en in de items - array
     * Enkel wanneer er wijzigingen gebeurd zijn
     * Indien nieuw item, toevoegen, anders updaten
     * @param {*} param0 
     * @param {*} param1 
     */
    updateItem({commit, dispatch}, {moduleName, item}) {

        console.log(item)

        const commitItem = data => {
            for (const property in data) {
                if (data[property].action == 'create') {
                    commit('createItem', {
                        moduleName: property,
                        data: data[property].data
                    });
                } else if (data[property].action == 'update') {
                    commit('updateItem', {
                        moduleName: property,
                        data: data[property].data
                    });
                } else if (data[property].action == 'delete') {
                    commit('deleteItem', {
                        moduleName: property,
                        data: data[property].data
                    });
                }
            }
        }

        const createItem = () => {
            const endpoint = createEndpointFromModuleName(moduleName);
            return axios.post('/api/' + endpoint + '/create', item)
            .then((result) => {
                commitItem(result.data);
            })
            .catch(error => {
                throw error.response.data;
            });
        } 
        const updateItem = () => {
            //Updaten item in database
            const endpoint = createEndpointFromModuleName(moduleName);
            return axios.post('/api/' + endpoint + '/update/' + item.id, item)
            .then((result) => {
                commitItem(result.data);
            })
            .catch(error => {
                throw error.response.data;
            });
        } 
        const deleteItem = () => {
            //Deleten item in database
            const endpoint = createEndpointFromModuleName(moduleName);
            return axios.post('/api/' + endpoint + '/delete/' + item.id)
                .then((result) => {
                    commitItem(result.data);
                })
                .catch(error => {
                    throw error.response.data;
                });
        } 
        const checkUserStatus = dispatch('checkUserStatus');

        const postItem = checkUserStatus.then(() => {
            if(item.isDeleted){
                //verwijderen item
                return deleteItem();
            }else{
                if (item.isChanged) {
                    if (item.isNew) {
                        //nieuw item toevoegen in database
                        return createItem();
                    } else {
                        //Updaten item in database
                        return updateItem();
                    }
                } else {
                    return Promise.resolve('belachelijk om dit te saven, want er is niks veranderd');
                }
            }
        }).catch(error => {
            throw error;
        });

        return postItem
        .catch(error => {
            commit('handleError', error);
            throw error;
        })        

    },
    /**
     * Verwijderen van een item in de database en in de items-array
     * @param {*} param0 
     * @param {*} param1 
     */
    deleteItem({dispatch, commit}, {moduleName, itemId}) {

        const checkUserStatus = dispatch('checkUserStatus');

        const deleteItem = checkUserStatus.then(() => {
            //Opslaan in database
            const endpoint = createEndpointFromModuleName(moduleName);
            return axios.post('/api/' + endpoint + '/delete/' + itemId)
            .then(() => {
                commit('setLoadedState', {
                    moduleName: moduleName,
                    loaded: false
                });
                return dispatch('fetchData', moduleName).then(() => {
                    console.log('gelukt, item verwijderd');
                });
            })
            .catch(error => {
                throw error.response.data;
            });
        }).catch(error => {
            throw error;
        });

        return deleteItem
        .catch(error => {
            commit('handleError', error);
            throw error;
        }) 

    },

};


const newRelation = data => {
    return {
        id: null,
        refNumber: data.refNumber,
        name: null,
        defaultAccount: {},
        defaultCategory: {},
        defaultVattype: {},
        relationtype: {},
        vatnumber: null,
        clientSupplier: data.clientSupplier,
        street: null,
        postcode: null,
        city: null,
        country: null,
        website: null,
        email: null,
        isChanged: false,
        isNew: true,
        isDeleted: false
    }
}

const newBooking = data => {
    return {
        id: null,
        refNumber: data.refNumber,
        relation: {},
        book: data.book,
        period: data.period,
        year: data.year,
        reference: null,
        description: null,
        invoiceDate: null,
        invoiceAmountInput: null,
        invoiceAmountExclVat: null,
        invoiceAmountInclVat: null,
        invoiceAmountVat: null,
        booklines: [],
        transactions: [],
        isChanged: false,
        isNew: true,
        isDeleted: false,
        addedBooklineCounter: 0,
        amountExclVat: null,
        amountInclVat: null,
        amountVat: null,
        deductibleVat: null,
        deductibleCosts: null,
        vatShifted: false,
        professionalShare: 100,
        noVatDeclaration: false,
        vatcodes: []
    }

}

const newTransaction = () => {
    return {
        id: null,
        transactionDate: null,
        booking: {},
        transactionType: {},
        transactionAccount: {},
        reference: null,
        refNumber: null,
        description: null,
        amount: null,  
        factor: 1,
        isChanged: false,
        isNew: true, 
        isDeleted: false     
    }
}

const createEndpointFromModuleName = (moduleName) => {
    let endpoint = moduleName.slice(0, -1);
    if (endpoint.substr(-2) === 'ie') {
        endpoint = endpoint.slice(0, -2) + 'y';
    }
    return endpoint;
}

