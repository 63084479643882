<template>
    <div v-if="this.loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 v-if="!propModalStyle" class="d-inline">{{title()}}</h1>
            </div>
        </div>
        <validation-alert 
            v-bind:errors = "validationErrors"
        />
        <div v-once v-if="!propModalStyle" class="row mb-3">
            <div class="col">
                <div class="box bg-white">
                    {{getLocalMsg('category.info')}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="box bg-white">
                    <div class="row">
                        <div class="col-4" v-bind:class="colClass">
                            <div class="form-group">
                                <label :for="noAutoComplete.name">{{getLocalMsg('category.name')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.name" class="form-control" :id="noAutoComplete.name" v-model="name">
                            </div>
                        </div>
                        <div class="col-4" v-bind:class="colClass">
                            <div class="form-group">
                                <label :for="noAutoComplete.defaultDeductibleVatShare">{{getLocalMsg('category.defaultDeductibleVatShare')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.defaultDeductibleVatShare" class="form-control" :id="noAutoComplete.defaultDeductibleVatShare" v-model="defaultDeductibleVatShare">
                            </div>
                        </div>
                        <div class="col-4" v-bind:class="colClass">
                            <div class="form-group">
                                <label :for="noAutoComplete.defaultDeductibleCostsShare">{{getLocalMsg('category.defaultDeductibleCostsShare')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.defaultDeductibleCostsShare" class="form-control" :id="noAutoComplete.defaultDeductibleCostsShare" v-model="defaultDeductibleCostsShare">
                            </div>
                        </div>
                    </div>
                    <a v-if="!propModalStyle" v-on:click="cancel" class="btn btn-secondary float-right">{{getLocalMsg('global.cancel')}}</a>
                    <a v-if="!propModalStyle && propEditable" v-on:click="save" class="btn btn-primary float-right mr-3">{{getLocalMsg('global.save')}}</a>

                </div>
                <div v-if="!propNewItem">
                    <h4 class="mt-4">{{(book==='P' ? getLocalMsg('relation.purchases') : getLocalMsg('relation.sales')) + ' (' + bookings.length + ')'}}</h4>
                    <div class="box bg-white">
                        <booking-items 
                            v-bind:book="book" 
                            v-bind:bookings="bookings"
                            v-bind:details="true"
                            v-bind:detailsRelation="true"
                        ></booking-items>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import BookingItems from '../bookings/BookingItems.vue'
import ValidationAlert from '../../shared/ValidationAlert';


export default {
    name: 'CategoryEdit',
    mixins: [sharedMixin],
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    // components: {
    //     datePicker,
    // },
    props: {
        propModalStyle: Boolean,
        propType: String,
        propNewItem: Boolean,
        propEditable: Boolean
    },
    data () {
        return {
            validationErrors: [],
            noAutoComplete: {
                name: this.noAutoCompleteId(),
            },
        }
    },
    components: {
        BookingItems,
        ValidationAlert
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.categories.loaded ? loaded : false;
            loaded = this.$store.state.vattypes.loaded ? loaded : false;
            return loaded;
        },
        bookings () {
            return this.$store.state.bookings.items
            .filter(booking=>{
                const result = booking.booklines.filter(bookline=>{
                    if(bookline.category) {
                        return Number(bookline.category.id) === Number(this.id)
                    }
                    return false;
                })
                return result.length > 0;
            })
            .sort((a,b)=>{
                return (a.invoiceDate < b.invoiceDate) ? 1 : -1;
            });
        },
        book () {
            return this.propType;
        },
        colClass () {
            if(this.propModalStyle){
                return {
                    'col-12': true
                }
            }
        },
        id () {
            return this.getItemProperty('categories','id');
        },
        name: {
            get: function () {
                return this.getItemProperty('categories','name');
            },
            set: function (value) {
                this.setItemProperty('categories','name',value);
            }   
        },
        defaultDeductibleVatShare: {
            get: function () {
                return this.getItemProperty('categories','defaultDeductibleVatShare');
            },
            set: function (value) {
                this.setItemProperty('categories','defaultDeductibleVatShare',value);
            }   
        },
        defaultDeductibleCostsShare: {
            get: function () {
                return this.getItemProperty('categories','defaultDeductibleCostsShare');
            },
            set: function (value) {
                this.setItemProperty('categories','defaultDeductibleCostsShare',value);
            }   
        },
        vattypes(){
            return this.getItems('vattypes');
        },
        redirectUrlPart(){
            return this.book === 'P' ? 'purchases' : 'sales';
        }
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'categories',
                property: 'type',
                value: this.propType
            })
            this.$store.dispatch('setActiveItem', {
                moduleName: 'categories',
                newItem: this.propNewItem,
                item: this.propNewItem ? {} : this.getItems('categories').find (item => {
                    return item.id === Number(this.$route.params.id)
                })
            })
            this.$store.commit('showSpinner',false);
        },
        title () {
            if(this.id){
                let prepend = '';
                if(this.propEditable){
                    prepend = this.getLocalMsg('global.edit') + ' ';
                }
                return prepend + this.getLocalMsg('category.category') + ' ' + this.name;
            }else {
                return this.getLocalMsg('global.newe') + ' ' + this.getLocalMsg('category.category');
            }
        },
        validate(){

            let validate = true;
            this.validationErrors = [];

            //Array met alle categorieen. GetItems beperkt de categorieen reeds tot aankoop of verkoop
            let categories = this.getItems('categories');
            //Verwijderen huidige categorie om te vermijden dat bij bewerken van de categorie onterecht een foutmelding op dubbels wordt weergegeven
            categories = categories.filter(function( item ) {
                return item.id !== this.id;
            }.bind(this));

            if(!this.name){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('category.name')
                });
                validate=false;
            }

            const categoryNames = categories.map(item => {
                return item.name.toLowerCase();
            })

            if(this.name && categoryNames.includes(this.name.toLowerCase())){
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('category.name')
                });
                validate=false;
            }

            let check = true;
            check = !this.isEmpty(this.defaultDeductibleVatShare) ? check : false;
            check = this.defaultDeductibleVatShare >=0 ? check : false;
            check = this.defaultDeductibleVatShare <=100 ? check : false;

            if(!check){
                this.validationErrors.push({
                    type: 'between',
                    field: this.getLocalMsg('category.defaultDeductibleVatShare'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }

            check = this.isInteger(this.defaultDeductibleVatShare);

            if(!check){
                this.validationErrors.push({
                    type: 'integer',
                    field: this.getLocalMsg('category.defaultDeductibleVatShare')
                });
                validate=false;
            }

            check = true;
            check = !this.isEmpty(this.defaultDeductibleCostsShare) ? check : false;
            check = this.defaultDeductibleCostsShare >=0 ? check : false;
            check = this.defaultDeductibleCostsShare <=100 ? check : false;

            if(!check){
                this.validationErrors.push({
                    type: 'between',
                    field: this.getLocalMsg('category.defaultDeductibleCostsShare'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }
            check = this.isInteger(this.defaultDeductibleCostsShare);

            if(!check){
                this.validationErrors.push({
                    type: 'integer',
                    field: this.getLocalMsg('category.defaultDeductibleCostsShare')
                });
                validate=false;
            }

            return validate;

        },
        save(){
            this.updateCategory();
        },
        cancel(){
            this.$router.push('/admin/' + this.redirectUrlPart + '/categories');
        },
        updateCategory() {

            if(!this.validate()){
                return;
            };

            this.$store.commit('showSpinner', true);
            
            return this.$store.dispatch('updateCategory').then(
                () => {
                    this.$router.push('/admin/' + this.redirectUrlPart + '/categories');
                }).catch(error => {
                    this.log(error);
                }).finally(
                    () => this.$store.commit('showSpinner', false)
                )

        },

    }
}

</script>

<style lang="scss" scoped>

    

</style>>
    
</style>
