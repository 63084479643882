<template>
    <div v-if="loaded">
        <div>
            <validation-alert 
                v-bind:errors = "validationErrors"
            />
            <!-- <div class="box bg-white mb-4">
                {{getLocalMsg('companies.info')}}
            </div> -->
            <ul class="nav nav-pills mb-3">
                <li v-for="company in companies" v-bind:key="company.id" class="nav-item">
                    <a class="nav-link" v-bind:class="{'active' : company.id === id}" v-on:click="setActiveCompany(company.id)" href="#">{{company.name}}</a>
                </li>
            </ul>
            <div class="box bg-white">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label :for="noAutoComplete.startDate">{{getLocalMsg('companies.startDate')}}</label>
                            <!-- https://www.npmjs.com/package/vue-bootstrap-datetimepicker -->
                            <date-picker :autocomplete="noAutoComplete.startDate" :id="noAutoComplete.startDate" v-model.lazy="startDate" :config="dateTimePickerOptions"></date-picker>
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.vatNumber">{{getLocalMsg('companies.vatNumber')}} ({{getLocalMsg('global.notRequired')}})</label>
                            <input type="text" :autocomplete="noAutoComplete.vatNumber" class="form-control" :id="noAutoComplete.vatNumber" v-bind:placeholder="getLocalMsg('companies.vatNumberPlaceHolder',{siteName:getLocalMsg('global.siteName')})" v-model.lazy="vatNumber">
                            <small v-if="vatNumberValidationText" class="form-text text-muted fg-color-primary">
                                {{this.vatNumberValidationText}}
                            </small>
                        </div>
                        <div class="form-group">
                            <label for="company-type">{{getLocalMsg('companies.type')}}</label>
                            <select disabled v-model="companyTypeId" class="form-control" id="type">
                                <option v-once v-for="companyType in companyTypes" v-bind:key="companyType.id" v-bind:value="companyType.id">{{companyType.name}}</option>
                            </select>
                        </div>
                        <div class="form-check mt-1 mb-4">
                            <input type="checkbox" tabindex="-1" class="form-check-input" id="vat-exempt" v-model="vatExempt">
                            <label class="form-check-label" for="vat-exempt">
                                <span>{{getLocalMsg('companies.vatExempt')}}</span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.name">{{getLocalMsg('companies.name')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.name" class="form-control" :id="noAutoComplete.name" v-model.lazy="name">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.street">{{getLocalMsg('companies.street')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.street" class="form-control" :id="noAutoComplete.street" v-model.lazy="street">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.postcode">{{getLocalMsg('companies.postcode')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.postcode" class="form-control" :id="noAutoComplete.postcode" v-model.lazy="postcode">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.city">{{getLocalMsg('companies.city')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.city" class="form-control" :id="noAutoComplete.city" v-model.lazy="city">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.country">{{getLocalMsg('companies.country')}}</label>
                            <input type="text" disabled :autocomplete="noAutoComplete.country" class="form-control" :id="noAutoComplete.country" v-model.lazy="country">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.countryCode">{{getLocalMsg('companies.countryCode')}}</label>
                            <input type="text" disabled :autocomplete="noAutoComplete.countryCode" class="form-control" :id="noAutoComplete.countryCode" v-model.lazy="countryCode">
                        </div>
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Admin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in users" :key="user.id">
                                        <td>{{user.email}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="input-group mb-3 col-2">
                                <input type="text" :autocomplete="noAutoComplete.userEmail" class="form-control" :id="noAutoComplete.userEmail" v-model.lazy="userEmail">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" @click="addUser(userEmail)">Button</button>
                                </div>
                            </div>
                        </div>
                        <div class="float-right">
                            <button v-on:click="validateAndUpdateItem" tabindex="0" class="btn btn-primary">{{getLocalMsg('companies.register')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import sharedMixin from '../../mixins/shared-mixin';
import ValidationAlert from '../../shared/ValidationAlert';

export default {
    name: 'RegisterCompany',
    mixins: [sharedMixin],
    components: {
        datePicker,
        ValidationAlert
    },
    mounted(){
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded)
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        },
    },
    data () {
        return {
            validationErrors: [],
            companyTypes: this.getLocalMsg('companies.types'),
            noAutoComplete: {
                name: this.noAutoCompleteId(),
                vatNumber: this.noAutoCompleteId(),
                startDate: this.noAutoCompleteId(),
                userEmail: this.noAutoCompleteId()
            },
            vatNumberValidationText: '',
            userEmail: ''        
        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.companies.loaded ? loaded : false;
            return loaded;
        },
        id() {
            return this.getItemProperty('companies','id');
        },
        name: {
            get: function () {
                return this.getItemProperty('companies','name');
            },
            set: function (value) {
                this.setItemProperty('companies','name',value);
            }   
        },
        street: {
            get: function () {
                return this.getItemProperty('companies','street');
            },
            set: function (value) {
                this.setItemProperty('companies','street',value);
            }   
        },
        postcode: {
            get: function () {
                return this.getItemProperty('companies','postcode');
            },
            set: function (value) {
                this.setItemProperty('companies','postcode',value);
            }   
        },
        city: {
            get: function () {
                return this.getItemProperty('companies','city');
            },
            set: function (value) {
                this.setItemProperty('companies','city',value);
            }   
        },
        country: {
            get: function () {
                return this.getItemProperty('companies','country');
            },
            // set: function (value) {
            //     this.setItemProperty('companies','country',value);
            // }   
        },
        users: {
            get: function () {
                return this.getItemProperty('companies','users');
            },
            set: function (value) {
                this.setItemProperty('companies','users',value);
            }   
        },
        countryCode: {
            get: function () {
                return this.getItemProperty('companies','countryCode');
            },
            // set: function (value) {
            //     this.setItemProperty('companies','countryCode',value);
            // }   
        },
        vatExempt: {
            get: function () {
                return this.getItemProperty('companies','vatExempt');
            },
            set: function (value) {
                this.setItemProperty('companies','vatExempt',value);
            }   
        },
        startDate: {
            get: function () {
                const dateString = this.getItemProperty('companies','startDate');
                if(dateString) {
                    return new Date(dateString);
                }
                return null;

            },
            set: function (value) {
                if(value){
                    const date = moment(value,this.dateTimePickerOptions.format);
                    value = date.format('YYYY-MM-DD');
                }else{
                    value = null;
                }

                this.setItemProperty('companies','startDate',value);
                
            }   
        },
        vatNumber: {
            get: function () {
                return this.getItemProperty('companies','vatNumber');
            },
            set: function (value) {
                this.setItemProperty('companies','vatNumber',value);
            }   
        },
        companyTypeId: {
            get: function () {
                return this.getItemProperty('companies','companyTypeId');
            },
            // set: function (value) {
            //     this.setItemProperty('companies','companyTypeId',value);
            // }   
        },
        startDateYYYYMMDD () {
            const date = moment(this.startDate,this.dateTimePickerOptions.format);
            return date.format('YYYY-MM-DD');
        },
        companies() {
            return this.$store.state.user.companies;
        },
    },
    methods: {
        addUser(email){
            const user = {
                email: email,
            }
            this.users = [...this.users, ...[user]];
            this.userEmail = '';
        },
        setActiveCompany(id){

            this.$store.dispatch('setActiveItem', {
                moduleName: 'companies',
                newItem: false,
                item: this.$store.state.companies.items.find (item => {
                    this.log(id);
                    return Number(item.id) === Number(id)
                })
            })
        },
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            const firstCompanyId = this.$store.state.companies.items[0].id;
            this.setActiveCompany(firstCompanyId);
            this.$store.commit('showSpinner',false);
        },
        setCompanyVatNumber(vatNumber) {
            vatNumber = this.formatVatNumber(vatNumber);
            this.vatNumberValidationText = '';
            this.checkVatNumber('BE' + vatNumber).then(result => {
                if(result.data.valid){
                    this.companyName = result.data.name;
                    this.companyStreet = result.data.address.street + ' ' + result.data.address.number;
                    this.companyCity = result.data.address.city;
                    this.companyPostcode = result.data.address.zip_code
                    this.companyCountry = result.data.address.country
                    this.companyCountryCode = result.data.countryCode
                } else {
                    this.vatNumberValidationText = this.getLocalMsg('global.vatNumberNotFound');
                    this.resetCompanyInfo();
                }
                this.companyVatNumber = vatNumber;
            });
        },
        resetCompanyInfo () {
            this.name= '';
            this.street= '';
            this.city= '';
            this.postcode= '';
            this.country= '';
            this.countryCode= '';
        },
        validate() {

            let validate = true;
            this.validationErrors = [];


            if(this.companyVatNumber){
                if(!this.isInteger(this.companyVatNumber) || this.companyVatNumber.length !=10){
                    this.validationErrors.push({type: 'digits', quantity: '10', field: this.getLocalMsg('companies.vatNumber')});
                    validate=false;
                }
            }

            const companyVatNumbers = this.companies.map(item => {
                return item.vatNumber.toUpperCase();
            })

            if (this.companyVatNumber && companyVatNumbers.includes('BE' + this.companyVatNumber.toUpperCase())) {
                console.log(this.companyVatNumber, companyVatNumbers);
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('companies.vatNumber')
                });
                validate = false;
            }


            if(!this.companyName){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.name')});
                validate=false;
            }

            const companyNames = this.companies.map(item => {
                return item.name.toLowerCase();
            })

            if (this.companyName && companyNames.includes(this.companyName.toLowerCase())) {
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('companies.name')
                });
                validate = false;
            }

            if (this.companyName.length > 40) {
                this.validationErrors.push({
                    type: 'maxLength',
                    max:40,
                    field: this.getLocalMsg('companies.name')
                });
                validate = false;
            }

            if(!this.companyStartDate){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.startDate')});
                validate=false;
            }

            if(this.emptyObject(this.companyType)){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.type')});
                validate=false;
            }

            return validate;

        },

        validateAndUpdateItem(){

            this.setActiveCompany(39);
            return;

            if(!this.validate()){
                return;
            };


            return this.updateItem();

        },
        
        updateItem() {


            this.$store.commit('showSpinner', true);

            if(this.companyType.id != 1){
                this.validationErrors.push({type: 'custom', message: this.getLocalMsg('companies.unsuitable',{siteName: this.getLocalMsg('global.siteName')})});
            }
            
            const data = {
                name: this.companyName,
                street: this.companyStreet,
                city: this.companyCity,
                postcode: this.companyPostcode,
                country: this.companyCountry,
                countryCode: this.companyCountryCode ? this.companyCountryCode : 'BE',
                vatNumber: this.companyVatNumber,
                vatExempt: this.companyVatExempt ? true : false,
                startDate: this.companyStartDateYYYYMMDD,
                companyType: this.companyType
            }

            return axios.post('/api/company/create', data)
            .then(() => {
                if(this.companyType.id === 1){
                    this.$store.commit('setLoadedState', {
                        moduleName: 'user',
                        loaded: false
                    });
                    return this.$store.dispatch('fetchUser').then(()=>{
                        this.$router.push('/admin');
                    });

                }
                return Promise.resolve();
            })
            .catch(error => {
                this.$store.commit('handleError', error.response.data);
                throw error.response.data;
            })
            .finally(() => 
                this.$store.commit('showSpinner', false)
            );

        },
    }
}

</script>

<style lang="scss" scoped>

   

</style>
