var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("h1", { staticClass: "d-inline" }, [
              _vm._v(_vm._s(_vm.title()))
            ]),
            _vm._v(" "),
            !_vm.lockedPeriod(_vm.selectedYear, _vm.selectedPeriod)
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary float-right",
                    on: {
                      click: function($event) {
                        return _vm.newBooking()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.getLocalMsg("global.new")))]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "years" } }, [
                _vm._v(_vm._s(_vm.getLocalMsg("global.years")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedYear,
                      expression: "selectedYear"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "years" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedYear = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._m(0),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "periods" } }, [
                _vm._v(_vm._s(_vm.getLocalMsg("global.periods")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedPeriod,
                      expression: "selectedPeriod"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "periods" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedPeriod = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._m(1),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "search-bookings" } }, [
                _vm._v(_vm._s(_vm.getLocalMsg("global.search")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchInput,
                    expression: "searchInput"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "search-bookings" },
                domProps: { value: _vm.searchInput },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchInput = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box bg-white" },
          [
            _c("booking-items", {
              attrs: { book: _vm.book, bookings: _vm.bookings, details: true }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.years, function(year, index) {
      return _c("option", { key: index, domProps: { value: year } }, [
        _vm._v(_vm._s(year))
      ])
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.periods, function(period, index) {
      return _c("option", { key: index, domProps: { value: period } }, [
        _vm._v(_vm._s(_vm.getLocalMsg("global." + period)))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }