var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errors.length > 0
    ? _c(
        "div",
        { staticClass: "alert alert-danger", attrs: { role: "alert" } },
        [
          _c("h4", { staticClass: "alert-heading" }, [
            _vm._v(_vm._s(_vm.getLocalMsg("validation.header")))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.errors, function(error, index) {
              return _c("li", { key: index }, [
                _vm._v(_vm._s(_vm.errorMessage(error)))
              ])
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }