var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "navbar navbar-expand-sm bg-dark navbar-dark" }, [
      _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
        _vm._v(_vm._s(_vm.getLocalMsg("global.siteName").toUpperCase()))
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/dashboard")
                }
              }
            },
            [_c("i", { staticClass: "fas fa-home" })]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/dashboard")
                }
              }
            },
            [_vm._v(_vm._s(_vm.getLocalMsg("navSite.price")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/dashboard")
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.getLocalMsg("navSite.about")) +
                  " " +
                  _vm._s(_vm.getLocalMsg("global.siteName"))
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/admin/dashboard")
                }
              }
            },
            [_vm._v(_vm._s(_vm.getLocalMsg("navSite.contact")))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav ml-auto" }, [
        !_vm.isLoggedIn
          ? _c("li", { staticClass: "nav-item" }, [
              _c("a", { staticClass: "nav-link", attrs: { href: "/login" } }, [
                _vm._v(_vm._s(_vm.getLocalMsg("navSite.login")))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoggedIn
          ? _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                { staticClass: "nav-link", attrs: { href: "/register" } },
                [_vm._v(_vm._s(_vm.getLocalMsg("navSite.register")))]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoggedIn
          ? _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  attrs: { href: "#", "data-toggle": "dropdown" }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.userName) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.logout($event)
                      }
                    }
                  },
                  [_vm._v("Logout")]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "logout",
            staticStyle: { display: "none" },
            attrs: { action: "/logout", method: "POST" }
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "_token" },
              domProps: { value: _vm.csrf }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }