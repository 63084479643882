export default {
    namespaced: true,
    state: {
        items: [],
        loaded: false,
    },
    getters: {
        getAllByYear: (state) => (year) => {
            return state.items.filter(item => {
                return item.year === year;
            })
        },
        getSoldByYear: (state) => (year) => {
            return state.items.filter(item => {
                return item.saleId && item.year === year;
            })
        },
        getPurchasedByYear: (state) => (year) => {

            //Enkel het eerste jaar ophalen van elke purchaseId omdat dit het jaar is waarin de aankoop gebeurd is
            let result = state.items.filter((item, idx) => {
                if (state.items[idx - 1]) {
                    return item.purchaseId !== state.items[idx - 1].purchaseId;
                } else {
                    return true;
                }

                    
            })
            return result.filter(item => {
                return item.year === year;
            })
        },
        getOthersByYear: (state,getters) => (year) => {

            const sold = getters['getSoldByYear'](year).map(item => {
                return item.id;
            });

            const purchased = getters['getPurchasedByYear'](year).map(item => {
                return item.id;
            });
            
            const others = getters['getAllByYear'](year).filter(item => {
                return !sold.includes(item.id) && !purchased.includes(item.id);
            });
            
            return others;
        },
        getNotPurchasedByYear: (state, getters) => (year) => {

            const purchased = getters['getPurchasedByYear'](year).map(item => {
                return item.id;
            });

            const notPurchased = getters['getAllByYear'](year).filter(item => {
                return !purchased.includes(item.id);
            });

            return notPurchased;



        },
        getTotalValueStartByYear: (state, getters) => (year) => {
            return getters['getNotPurchasedByYear'](year).reduce((a, b) => a + (b.valueStart || 0), 0);
        },
        getTotalPurchasedByYear: (state, getters) => (year) => {
            return getters['getPurchasedByYear'](year).reduce((a, b) => a + (b.valueStart || 0), 0);
        },
        getTotalWriteOffByYear: (state, getters) => (year) => {
            return getters['getAllByYear'](year).reduce((a, b) => a + (b.writeOff || 0), 0);
        },
        getTotalValueEndByYear: (state, getters) => (year) => {
            return getters['getAllByYear'](year).reduce((a, b) => a + (b.valueEnd || 0), 0);
        },
        getTotalDeductibleByYear: (state, getters) => (year) => {
            return getters['getAllByYear'](year).reduce((a, b) => a + (b.deductible || 0), 0);
        },
        
    },
    actions: {
        fullWriteOffManual({ dispatch }, data) {
            return dispatch('postData', {
                url: '/api/writeoff/manual',
                data: data
            });
        },
        fullWriteOffSale({ dispatch }, data) {
            return dispatch('postData', {
                url: '/api/writeoff/sale',
                data: data
            });
        },
        update({ dispatch }, data) {
            return dispatch('postData', {
                url: '/api/writeoff/update',
                data: data
            });
        },
        create({ dispatch }, data) {
            return dispatch('postData', {
                url: '/api/writeoff/create',
                data: data
            });
        },
        reset({ dispatch }, data) {
            return dispatch('postData', {
                url: '/api/writeoff/reset',
                data: data
            });
        },
        postData({ commit, dispatch }, {url, data}) {

            const checkUserStatus = dispatch('checkUserStatus', null, { root: true });

            const postData = checkUserStatus.then(() => {
                return axios.post(url, data)
                .then((result) => {
                    commit('update', result.data.data);
                })
                .catch(error => {
                    throw error.response.data;
                });
            }).catch(error => {
                throw error;
            });

            return postData
                .catch(error => {
                    commit('handleError', error, { root: true });
                    throw error;
                }) 

        },

    },
    mutations: {
        update(state, data) {
            state.items = data;
        },
    },
};
