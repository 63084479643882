var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.getLocalMsg("transaction.overview")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTransactionModalNew("S")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("transaction.newS")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right mr-1",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTransactionModalNew("P")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("transaction.newP")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right mr-1",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTransactionModalNew("I")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("transaction.newI")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right mr-1",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTransactionModalNew("O")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("transaction.newO")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right mr-1",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTransactionModalNew("T")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("transaction.newT")))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "transactionAccounts" } }, [
                  _vm._v(
                    _vm._s(_vm.getLocalMsg("transaction.transactionAccounts"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.transactionAccount,
                        expression: "transactionAccount"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "transactionAccounts" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.transactionAccount = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._m(0),
                  0
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table " }, [
              _c(
                "tbody",
                [
                  _vm._l(_vm.transactionsByDate, function(transactionDate) {
                    return [
                      _c("tr", { key: "header-" + transactionDate.date }, [
                        _c(
                          "td",
                          { staticClass: "align-middle fg-color-blue" },
                          [
                            _c(
                              "div",
                              { staticClass: "highlight-header-row p-2 mb-3" },
                              [
                                _vm._v(
                                  _vm._s(_vm.formatDate(transactionDate.date))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("small", { staticClass: "pl-1 pb-0" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getLocalMsg("transaction.dateTotal")
                                  ) +
                                    " : " +
                                    _vm._s(
                                      _vm.fixAmount(
                                        transactionDate.dateTotal,
                                        2
                                      )
                                    ) +
                                    " / " +
                                    _vm._s(
                                      _vm.getLocalMsg(
                                        "transaction.rollingTotal"
                                      )
                                    ) +
                                    " : " +
                                    _vm._s(
                                      _vm.fixAmount(
                                        transactionDate.rollingTotal,
                                        2
                                      )
                                    )
                                )
                              ])
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        { key: "detail-transactions-" + transactionDate.date },
                        [
                          _c("td", { staticClass: "hide-border-top pt-0" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-borderless table-sm"
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      { staticStyle: { width: "30%" } },
                                      [
                                        _c("small", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "transaction.reference"
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      { staticStyle: { width: "25%" } },
                                      [
                                        _c("small", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "transaction.booking"
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      { staticStyle: { width: "35%" } },
                                      [
                                        _c("small", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "transaction.description"
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-right",
                                        staticStyle: { width: "5%" }
                                      },
                                      [
                                        _c("small", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLocalMsg(
                                                  "transaction.amount"
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("th", { staticStyle: { width: "5%" } })
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(transactionDate.transactions, function(
                                    transaction,
                                    index
                                  ) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        class: {
                                          "fg-red": _vm.noBookingLinkedToPurchaseOrSale(
                                            transaction
                                          )
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "align-middle" },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(transaction.reference)
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "align-middle" },
                                          [
                                            _c("small", [
                                              ["S", "P"].includes(
                                                transaction.transactionType.code
                                              )
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "transaction-select-booking",
                                                        {
                                                          attrs: {
                                                            propTransaction: transaction
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "align-middle" },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(transaction.description)
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "align-middle text-right"
                                          },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.fixAmount(
                                                    transaction.amount *
                                                      transaction.factor,
                                                    2
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "align-middle text-right"
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "mt-n1 float-right btn btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showDeleteModal(
                                                      transaction
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "far fa-trash-alt"
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalDelete",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("transaction.delete.header"))
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("transaction.delete.body"))
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.deleteTransaction }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.delete")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              446447012
            )
          }),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalTransaction",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("div", [
                        _c("h5", [
                          _vm._v(_vm._s(_vm.getLocalMsg("transaction.add")))
                        ])
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("transaction-edit", {
                        key: _vm.forceRerenderKey,
                        ref: "transactionEdit",
                        attrs: { propTransaction: _vm.selectedTransaction }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: { click: _vm.updateTransaction }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("transaction.update"))
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              833300685
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.transactionAccounts, function(transactionAccount, index) {
      return _c(
        "option",
        { key: index, domProps: { value: transactionAccount } },
        [_vm._v(_vm._s(transactionAccount.name))]
      )
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }