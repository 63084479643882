var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _vm.hasCompanies
          ? _c(
              "div",
              [
                _c("nav-admin"),
                _vm._v(" "),
                _c("div", { staticClass: "wrapper" }, [_c("router-view")], 1)
              ],
              1
            )
          : _c(
              "div",
              [
                _c("nav-site"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "wrapper" },
                  [_c("register-company")],
                  1
                )
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }