var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _c(
          "div",
          [
            _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "nav nav-pills mb-3" },
              _vm._l(_vm.companies, function(company) {
                return _c("li", { key: company.id, staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: company.id === _vm.id },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.setActiveCompany(company.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(company.name))]
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "box bg-white" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        { attrs: { for: _vm.noAutoComplete.startDate } },
                        [_vm._v(_vm._s(_vm.getLocalMsg("companies.startDate")))]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          autocomplete: _vm.noAutoComplete.startDate,
                          id: _vm.noAutoComplete.startDate,
                          config: _vm.dateTimePickerOptions
                        },
                        model: {
                          value: _vm.startDate,
                          callback: function($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.vatNumber } },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("companies.vatNumber")) +
                            " (" +
                            _vm._s(_vm.getLocalMsg("global.notRequired")) +
                            ")"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.vatNumber,
                          expression: "vatNumber",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.vatNumber,
                        id: _vm.noAutoComplete.vatNumber,
                        placeholder: _vm.getLocalMsg(
                          "companies.vatNumberPlaceHolder",
                          { siteName: _vm.getLocalMsg("global.siteName") }
                        )
                      },
                      domProps: { value: _vm.vatNumber },
                      on: {
                        change: function($event) {
                          _vm.vatNumber = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.vatNumberValidationText
                      ? _c(
                          "small",
                          {
                            staticClass: "form-text text-muted fg-color-primary"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(this.vatNumberValidationText) +
                                "\n                        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "company-type" } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("companies.type")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.companyTypeId,
                            expression: "companyTypeId"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: "", id: "type" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.companyTypeId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._m(0),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check mt-1 mb-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.vatExempt,
                          expression: "vatExempt"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        tabindex: "-1",
                        id: "vat-exempt"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.vatExempt)
                          ? _vm._i(_vm.vatExempt, null) > -1
                          : _vm.vatExempt
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.vatExempt,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.vatExempt = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.vatExempt = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.vatExempt = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "vat-exempt" }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getLocalMsg("companies.vatExempt")))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: _vm.noAutoComplete.name } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("companies.name")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.name,
                          expression: "name",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.name,
                        id: _vm.noAutoComplete.name
                      },
                      domProps: { value: _vm.name },
                      on: {
                        change: function($event) {
                          _vm.name = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: _vm.noAutoComplete.street } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("companies.street")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.street,
                          expression: "street",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.street,
                        id: _vm.noAutoComplete.street
                      },
                      domProps: { value: _vm.street },
                      on: {
                        change: function($event) {
                          _vm.street = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.postcode } },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.postcode")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.postcode,
                          expression: "postcode",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.postcode,
                        id: _vm.noAutoComplete.postcode
                      },
                      domProps: { value: _vm.postcode },
                      on: {
                        change: function($event) {
                          _vm.postcode = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: _vm.noAutoComplete.city } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("companies.city")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.city,
                          expression: "city",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.city,
                        id: _vm.noAutoComplete.city
                      },
                      domProps: { value: _vm.city },
                      on: {
                        change: function($event) {
                          _vm.city = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.country } },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.country")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.country,
                          expression: "country",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        disabled: "",
                        autocomplete: _vm.noAutoComplete.country,
                        id: _vm.noAutoComplete.country
                      },
                      domProps: { value: _vm.country },
                      on: {
                        change: function($event) {
                          _vm.country = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.noAutoComplete.countryCode } },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.countryCode")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.countryCode,
                          expression: "countryCode",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        disabled: "",
                        autocomplete: _vm.noAutoComplete.countryCode,
                        id: _vm.noAutoComplete.countryCode
                      },
                      domProps: { value: _vm.countryCode },
                      on: {
                        change: function($event) {
                          _vm.countryCode = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("table", { staticClass: "table" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.users, function(user) {
                          return _c("tr", { key: user.id }, [
                            _c("td", [_vm._v(_vm._s(user.email))]),
                            _vm._v(" "),
                            _c("td")
                          ])
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group mb-3 col-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.userEmail,
                            expression: "userEmail",
                            modifiers: { lazy: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.userEmail,
                          id: _vm.noAutoComplete.userEmail
                        },
                        domProps: { value: _vm.userEmail },
                        on: {
                          change: function($event) {
                            _vm.userEmail = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.addUser(_vm.userEmail)
                              }
                            }
                          },
                          [_vm._v("Button")]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { tabindex: "0" },
                        on: { click: _vm.validateAndUpdateItem }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("companies.register")))]
                    )
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.companyTypes, function(companyType) {
      return _c(
        "option",
        { key: companyType.id, domProps: { value: companyType.id } },
        [_vm._v(_vm._s(companyType.name))]
      )
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Admin")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }