<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('incomeStatement.title')}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="years">{{getLocalMsg('global.years')}}</label>
                    <select class="form-control" id="years" v-model="selectedYear">
                        <option v-once v-for="(year, index) in years" v-bind:key="index" v-bind:value = "year">{{year}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="periodicity">{{getLocalMsg('global.periods')}}</label>
                    <select class="form-control" id="periodicity" v-model="periodicity">
                        <option value = "quarterly">{{getLocalMsg('global.quarterly')}}</option>
                        <option value = "monthly">{{getLocalMsg('global.monthly')}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th v-if="periodicity==='quarterly'" class="text-right">{{getLocalMsg('global.Q1')}}</th>
                        <th v-if="periodicity==='quarterly'" class="text-right">{{getLocalMsg('global.Q2')}}</th>
                        <th v-if="periodicity==='quarterly'" class="text-right">{{getLocalMsg('global.Q3')}}</th>
                        <th v-if="periodicity==='quarterly'" class="text-right">{{getLocalMsg('global.Q4')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M01')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M02')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M03')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M04')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M05')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M06')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M07')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M08')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M09')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M10')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M11')}}</th>
                        <th v-if="periodicity==='monthly'" class="text-right">{{getLocalMsg('global.M12')}}</th>
                        <th class="text-right">{{selectedYear}}</th>
                    </tr>
                </thead>
                <tbody>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="sales.Total"
                    ><template v-slot:title><button type="button" v-on:click="showModalDetail('rowA1')" class="btn btn-link">{{getLocalMsg('incomeStatement.rowA1')}}</button></template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="retailCosts.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowB1')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="retailGoods.Total"
                    ><template v-slot:title><button type="button" v-on:click="showModalDetail('rowB2')" class="btn btn-link">{{getLocalMsg('incomeStatement.rowB2')}}</button></template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="stockChange.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowB3',{yearMin1: selectedYear-1, year: selectedYear})}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="grossProfit.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowC1')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="grossMargin.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowC2')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="otherGoods.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowD1')}}</template></income-row>
                    <income-row 
                        v-for="(value, key, index) in otherGoodsByCategory" 
                        v-bind:key="'otherGoods' + index"
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="value"
                    ><template v-slot:title><button type="button" v-on:click="showModalDetail('rowD2',key)" class="btn btn-link">{{key}}</button></template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="operatingProfit.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowE1')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="operatingMargin.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowE2')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="writeOff.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowF1')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold']"
                        v-bind:value="taxableProfit.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowG1')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="netMargin.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowG2')}}</template></income-row>
                    <tr class="row-bold">
                        <td>{{getLocalMsg('incomeStatement.rowH1')}}</td>
                    </tr>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="nonDeductibleCosts.Total"
                    ><template v-slot:title><button type="button" v-on:click="showModalDetail('rowH2')" class="btn btn-link">{{getLocalMsg('incomeStatement.rowH2')}}</button></template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="privatePart.Total"
                    ><template v-slot:title><button type="button" v-on:click="showModalDetail('rowH3')" class="btn btn-link">{{getLocalMsg('incomeStatement.rowH3')}}</button></template></income-row>
                    <tr class="row-bold">
                        <td>{{getLocalMsg('incomeStatement.rowI1')}}</td>
                    </tr>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="nonDeductibleWriteOff.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowI2')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="privatePartWriteOff.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowI3')}}</template></income-row>
                    <tr class="row-bold">
                        <td>{{getLocalMsg('incomeStatement.rowJ1')}}</td>
                    </tr>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="addedValueInvestmentSales.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowJ2')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-indent']"
                        v-bind:value="notWrittenOffInvestments.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowJ3')}}</template></income-row>
                    <income-row 
                        v-bind:periodicity="periodicity"
                        v-bind:classes="['row-bold','row-indent']"
                        v-bind:value="addedValueInvestments.Total"
                    ><template v-slot:title>{{getLocalMsg('incomeStatement.rowJ4')}}</template></income-row>
                </tbody>
            </table>
        </div>
        <modal-template ref="modalDetail" dialogClasses="modal-xxl modal-dialog-scrollable">
            <template v-slot:header>
                <h5>{{getLocalMsg('incomeStatement.detail')}}</h5>
            </template>
            <template v-slot:body>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{getLocalMsg('booking.bookingId')}}</th>
                            <th>{{getLocalMsg('global.period')}}</th>
                            <th>{{getLocalMsg('booking.invoiceDate')}}</th>
                            <th>{{getLocalMsg('booking.relation')}}</th>
                            <th>{{getLocalMsg('booking.reference')}}</th>
                            <th>{{getLocalMsg('booking.description')}}</th>
                            <th class="text-right">{{getLocalMsg('booking.amount')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="booking in selectedBookings">
                            <tr class = "header-row" v-bind:key="booking.id">
                                <td class="align-middle"><button type="button" class="btn btn-link" v-on:click="showModalBooking(booking.id)">{{booking.book === 'P' ? getLocalMsg('booking.purchase') : getLocalMsg('booking.sale')}} {{booking.refNumber}}</button></td>
                                <td class="align-middle">{{getLocalMsg('global.' + booking.period)}}</td>
                                <td class="align-middle">{{formatDate(booking.invoiceDate)}}</td>
                                <td class="align-middle">{{booking.relation.name}}</td>
                                <td class="align-middle">{{booking.reference}}</td>
                                <td class="align-middle">{{booking.description}}</td>
                                <td class="align-middle text-right">{{fixAmount(booking.amountIncomeStatement,2)}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table> 
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
            </template>
        </modal-template>
        <modal-template ref="modalBooking" dialogClasses="modal-xxl modal-dialog-scrollable">
            <template v-slot:header>
                <h5>{{getLocalMsg('vatDeclaration.detailBooking')}}</h5>
            </template>
            <template v-slot:body>
                <booking-items 
                    v-if="selectedBooking.length > 0"
                    v-bind:book="selectedBooking[0].book" 
                    v-bind:bookings="selectedBooking"
                    v-bind:details="true"
                    v-bind:detailsRelation="true"
                ></booking-items>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
            </template>
        </modal-template>
    </div>
    
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue';
import IncomeRow from '../incomestatement/IncomeRow.vue';
import BookingItems from '../bookings/BookingItems.vue';

export default {
    name: 'IncomeStatement',
    mixins: [sharedMixin],
    components: {
        IncomeRow,
        ModalTemplate,
        BookingItems
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data(){
        return {
            selectedBooking: [],
            selectedBookings: [],
            periodicity: 'quarterly'
        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.stockchanges.loaded ? loaded : false;
            loaded = this.$store.state.years.loaded ? loaded : false;
            return loaded;
        },
        years () {
            return this.getItems('years');
        },
        selectedYear: {
            get: function () {
                return this.getSelectionProperty('bookings','year');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','year',value);
            }   
        },
        otherGoodsByCategory () {
            return this.$store.getters['bookings/getOtherGoodsByCategory'];
        },
        retailGoods () {
            return this.$store.getters['bookings/getRetailGoods'];
        },
        otherGoods () {
            return this.$store.getters['bookings/getOtherGoods'];
        },
        nonDeductibleCosts () {
            return this.$store.getters['bookings/getNonDeductibleCosts'];
        },
        privatePart () {
            return this.$store.getters['bookings/getPrivatePart'];
        },
        sales () {
            return this.$store.getters['bookings/getSales'];
        },
        stockChange () {
            return this.$store.getters['bookings/getStockChange'];
        },
        writeOff () {
            return this.$store.getters['bookings/getWriteOff'];
        },
        grossProfit () {
            return this.$store.getters['bookings/getGrossProfit'];
        },
        grossMargin () {
            return this.$store.getters['bookings/getGrossMargin'];
        },
        operatingProfit () {
            return this.$store.getters['bookings/getOperatingProfit'];
        },
        operatingMargin () {
            return this.$store.getters['bookings/getOperatingMargin'];
        },
        netMargin () {
            return this.$store.getters['bookings/getNetMargin'];
        },
        retailCosts () {
            return this.$store.getters['bookings/getRetailCosts'];
        },
        taxableProfit () {
            return this.$store.getters['bookings/getTaxableProfit'];
        },
        nonDeductibleWriteOff () {
            return this.$store.getters['bookings/getNonDeductibleWriteOff'];
        },
        privatePartWriteOff () {
            return this.$store.getters['bookings/getPrivatePartWriteOff'];
        },
        addedValueInvestmentSales () {
            return this.$store.getters['bookings/getAddedValueInvestmentSales'];
        },
        notWrittenOffInvestments () {
            return this.$store.getters['bookings/getNotWrittenOffInvestments'];
        },
        addedValueInvestments () {
            return this.$store.getters['bookings/getAddedValueInvestments'];
        },
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        showModalDetail(row,key) {

            switch(row) {
            case 'rowA1':
                this.selectedBookings = this.$store.state.bookings.items
                .filter(booking=>{
                    return (
                        booking.year === this.selectedYear &&
                        booking.book === 'S'
                    )
                });
                for(let i=0; i<this.selectedBookings.length; i++) {
                    this.selectedBookings[i].amountIncomeStatement = this.selectedBookings[i].deductibleCosts;
                }
                break;
            case 'rowB2':
                this.selectedBookings = this.$store.state.bookings.items
                .filter(booking=>{
                    const result = booking.booklines.filter(bookline=>{
                        return bookline.account.type === 'G'
                    })
                    return (
                        booking.year === this.selectedYear &&
                        booking.book === 'P' &&
                        result.length > 0

                    )
                });
                for(let i=0; i<this.selectedBookings.length; i++) {
                    this.selectedBookings[i].amountIncomeStatement = this.selectedBookings[i].booklines.reduce((a,b) => {
                        return a + (b.account.type === 'G' ? b.deductibleCosts : 0);
                    },0)
                }
                break;
            case 'rowD2':
                this.selectedBookings = this.$store.state.bookings.items
                .filter(booking=>{
                    const result = booking.booklines.filter(bookline=>{
                        if(bookline.category){
                            return bookline.category.name === key && (bookline.account.type === 'O' || bookline.account.type === 'S')
                        }else{
                            return false;
                        }
                    })
                    return (
                        booking.year === this.selectedYear &&
                        booking.book === 'P' &&
                        result.length > 0

                    )
                });
                for(let i=0; i<this.selectedBookings.length; i++) {
                    this.selectedBookings[i].amountIncomeStatement = this.selectedBookings[i].booklines.reduce((a,b) => {
                        return a + ((b.category.name === key && (b.account.type === 'O' || b.account.type === 'S')) ? b.deductibleCosts : 0);
                    },0)
                }
                break;
            case 'rowH2':
                this.selectedBookings = this.$store.state.bookings.items
                .filter(booking=>{
                    return (
                        booking.year === this.selectedYear &&
                        booking.book === 'P'
                    )
                });
                for(let i=this.selectedBookings.length-1; i>=0; i--) {
                    const amountInclVat = this.selectedBookings[i].amountInclVat;
                    const deductibleVat = this.selectedBookings[i].deductibleVat;
                    const deductibleCosts = this.selectedBookings[i].deductibleCosts;
                    const amountIncomeStatement = amountInclVat - deductibleVat - deductibleCosts;
                    if(amountIncomeStatement===0) {
                        this.selectedBookings.splice(i, 1);
                    } else {
                        this.selectedBookings[i].amountIncomeStatement = amountIncomeStatement;
                    }
                }
                break;
            case 'rowH3':
                this.selectedBookings = this.$store.state.bookings.items
                .filter(booking=>{
                    return (
                        booking.year === this.selectedYear &&
                        booking.book === 'P'
                    )
                });
                for(let i=this.selectedBookings.length-1; i>=0; i--) {
                    const amountExclVat = this.selectedBookings[i].amountExclVat;
                    const invoiceAmountExclVat = this.selectedBookings[i].invoiceAmountExclVat;
                    const amountIncomeStatement = invoiceAmountExclVat - amountExclVat;
                    if(amountIncomeStatement===0) {
                        this.selectedBookings.splice(i, 1);
                    } else {
                        this.selectedBookings[i].amountIncomeStatement = amountIncomeStatement;
                    }
                }
                break;
            default:
                this.selectedBookings = [];
            }

            this.showModal('modalDetail');
        },  
        showModalBooking(bookingId) {

            this.selectedBooking = this.selectedBookings
                .filter(bookingItem=>{
                    return bookingItem.id === bookingId;
            });
            this.showModal('modalBooking');
        },    
    }
}

</script>

<style lang="scss">

    .row-bold{
        td {
            font-weight: 900;
        }
    }
    .row-indent{
        td {
            padding-left: 30px;
        }
    }
    .btn-link {
        padding: 0;
    }

</style>
