export default {
    namespaced: true,
    state: {
        items: activeYears(),
        loaded: true
    },
};

function activeYears() {

    const dt = new Date();
    const curYear = dt.getFullYear();

    const startYear = 2015;
    const endYear = curYear+1;

    const years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push(i.toString());
    }
    return years;

}