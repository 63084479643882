export default class Helpers {

    static currentTimestamp () {
        const date = new Date(Date.now());
        const yyyy = date.getFullYear().toString();
        const mm = ('00' + (date.getMonth() + 1).toString()).substr(-2);
        const dd = ('00' + date.getDate().toString()).substr(-2);
        const hour = ('00' + date.getHours().toString()).substr(-2);
        const min = ('00' + date.getMinutes().toString()).substr(-2);
        const sec = ('00' + date.getSeconds().toString()).substr(-2);
        const ms = ('000' + date.getMilliseconds().toString()).substr(-3);
        return dd + '/' + mm + '/' + yyyy + ' ' + hour + ':' + min + ':' + sec + '.' + ms;
        
    }

    static emptyObject(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static calcAmountVat (amountExclVat,vattypePct) {
        return amountExclVat * vattypePct;
    }
    static formatDate (date,inFormat,outFormat) {
        date = moment(date, inFormat);
        return date.format(outFormat);
    }
    static calcAmountInclVat(amountExclVat, vattypePct) {
        return amountExclVat * (1 + vattypePct);
    }
    static calcAmountExclVat(amountInclVat, vattypePct) {
        return amountInclVat / (1 + vattypePct);
    }
    //Geeft number terug, afgerond naar gevraagd aantal decimalen
    static roundAmount (amount, decimals) {
        if(isNaN(parseInt(amount))){
            return null;
        }else {
            return Number(Number(amount).toFixed(decimals));
        }
    }
    //Geeft string terug met vast aantal decimalen
    static fixAmount(amount, decimals) {
        if (isNaN(parseInt(amount))) {
            return null;
        } else {
            return Number(amount).toFixed(decimals);
        }
    }
    static getQuarter(date) {
        const month = date.getMonth() + 1;
        return (Math.ceil(month / 3));
    }

    static getItem(items, itemId) {
        return items.find(item => {
            return item.id === Number(itemId)
        });
    };
    static log(val) {
        console.log(val);
    }
    static logts(val) {
        console.log(this.currentTimestamp(),val);
    }
    static isNumeric(val) {
        if (val === null || val === '') {
            return false;
        }
        if (isNaN(val)) {
            return false;
        }
        return true;
    }
    static isInteger(val) {
        if (val===null || val==='') {
            return false;
        }
        if (isNaN(val)) {
            return false;
        }
        return Number.isInteger(Number(val));
    }
    static isEmpty(val) {
        if (val === null || val === '' || val === undefined) { 
            return true;
        }
        return false;
    }
    /**
     * Checken BTW nummer op geldigheid + naam en adres van het bedrijf ophalen
     * Alleen bij een geldig BTW-nummer worden de velden ingevuld, anders waarschuwing dat het BTW-nummer mogelijk niet klopt.
     * Bij error doen we niks, vermoedelijk is de service dan tijdelijk niet beschikbaar
     */
    static checkVatNumber(vatNumber) {
        return axios.get('/api/checkvatnumber/' + vatNumber)
    }
    static formatVatNumber(vatNumber){
        return vatNumber
            .split(' ').join('')
            .split('.').join('')
            .split('-').join('')
            .split('_').join('')
            .split(',').join('')
            .split(', ').join('').trim().toUpperCase();
    }
    static generateRandomGuid() {
        const S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
    static noAutoCompleteId() {
        return 'jdl' + this.generateRandomGuid();
    }

    /**
     * Check of de hoofding van de boeking correct is ingevuld
     * We checken velden:
     * relatie = verplicht
     * datum = verplicht
     * factuurbedrag = verplicht
     * % professioneel gedeelte = verplicht
     * @param {*} state 
     */
    static bookingHeaderIsValid (booking) {
        if (!booking.relation || this.emptyObject(booking.relation)) { return false };
        if (!booking.invoiceAmountInput && booking.invoiceAmountInput !== 0) { return false };
        if (!booking.professionalShare && booking.professionalShare !== 0) { return false };
        return true;
    }

    /**
     * Check of een boeklijn geldig ingevuld is 
     * We checken velden:
     * rekening = verplicht
     * categorie = verplicht
     * btwtype = verplicht
     * bedrag excl btw = verplicht
     * % aftrekbare btw = verplicht
     * % aftrekbare kosten = verplicht
     */
    static booklineIsValid (bookline) {
        if(!bookline.account || this.emptyObject(bookline.account)) { return false };
        if (!bookline.category || this.emptyObject(bookline.category)) { return false };
        if (!bookline.vattype || this.emptyObject(bookline.vattype)) { return false };
        if (!bookline.amountExclVat && bookline.amountExclVat !== 0) { return false };
        if (!bookline.deductibleVatShare && bookline.deductibleVatShare !== 0) { return false };
        if (!bookline.deductibleCostsShare && bookline.deductibleCostsShare !== 0) { return false };
        return true;
    }

    /**
     * Bepalen of de factuur inclusief of exclusief BTW is
     * Dit is afhankelijk van een aantal factoren:
     * - Als BTW Verlegd of Werken in Onroerende Staat is aangevinkt (vatShifted)
     * - Als het over een buitenlandse leverancier gaat (EU en buiten EU) 
     * @param {*} state 
     */
    static invoiceExclVat(booking) {

        if (booking.vatShifted) {
            return true;
        }

        if (!this.emptyObject(booking.relation)) {
            if (!this.emptyObject(booking.relation.relationtype)) {
                if (booking.relation.relationtype.code === 'EU') {
                    return true;
                }
                if (booking.relation.relationtype.code === 'NOEU') {
                    return true;
                }

            }
        }

        return false;

    }

    /**
     * Check of het factuurtotaal overeenkomt met de optelling van de boeklijnen
     * Optelling van de boeklijnen incl of excl btw, afhankelijk van resultaat funcie getInvoiceExclVat
     * Vergelijking gebeurt met factuurtotaal * % van het professionele gedeelte
     * @param {*} state 
     * @param {*} getters 
     */
    static totalsNotMatching(booking) {

        let amountSumBooklines = 0;
        if (this.invoiceExclVat(booking)) {
            amountSumBooklines = this.roundAmount(booking.amountExclVat, 2);
        } else {
            amountSumBooklines = this.roundAmount(booking.amountInclVat, 2);
        }

        const invoiceAmountInput = booking.invoiceAmountInput ? booking.invoiceAmountInput : 0;
        const professionalShare = booking.professionalShare ? booking.professionalShare : 0;
        const amountInvoice = this.roundAmount(invoiceAmountInput * (professionalShare / 100), 2);

        return amountSumBooklines !== amountInvoice;

    }

}