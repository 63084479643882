var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { class: _vm.classes }, [
    _c("td", [_vm._t("title")], 2),
    _vm._v(" "),
    _vm.periodicity === "quarterly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.Q1 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "quarterly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.Q2 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "quarterly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.Q3 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "quarterly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.Q4 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M01 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M02 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M03 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M04 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M05 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M06 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M07 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M08 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M09 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M10 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M11 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodicity === "monthly"
      ? _c("td", { staticClass: "text-right" }, [
          _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.M12 : 0)))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("td", { staticClass: "text-right" }, [
      _vm._v(_vm._s(_vm.fixedValue(_vm.value ? _vm.value.Total : 0)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }