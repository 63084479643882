<template>
    <div v-if="loaded">
        <div v-if="lockedPeriod(year, period)">
            {{getLocalMsg('booking.locked')}}
        </div>
        <div v-else>
            <div class="row mb-3">
                <div class="col">
                    <h1 class="d-inline">{{title()}}</h1>
                </div>
            </div>
            <validation-alert 
                v-bind:errors = "validationErrors"
            />
            <div class="row">
                <div class="col-2">
                    <div class="form-group">
                        <label for="relations">{{relationLabel}}</label>
                        <search-and-select
                            ref="relation"
                            v-bind:data="relations"
                            v-bind:currentSearchValue="relation.name"
                            v-bind:next-field-id="noAutoComplete.invoiceDate"
                            v-bind:placeholder="getLocalMsg('global.search') + ' ' + relationLabel"
                            v-on:hit="commitRelation($event)" 
                        >
                            <template slot="append">
                                <button @click="addNewRelation" type="button" class="btn btn-secondary">
                                    {{getLocalMsg('global.new')}}
                                </button>
                            </template>
                        </search-and-select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label :for="noAutoComplete.invoiceDate">{{getLocalMsg('booking.invoiceDate')}}</label>
                        <!-- https://www.npmjs.com/package/vue-bootstrap-datetimepicker -->
                        <date-picker :autocomplete="noAutoComplete.invoiceDate" :id="noAutoComplete.invoiceDate" v-model.lazy="invoiceDate" :config="dateTimePickerOptions"></date-picker>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label :for="noAutoComplete.reference">{{getLocalMsg('booking.reference')}}</label>
                        <input type="text" :autocomplete="noAutoComplete.reference" class="form-control" :id="noAutoComplete.reference" v-model.lazy="reference">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label :for="noAutoComplete.description">{{getLocalMsg('booking.description')}}</label>
                        <input type="text" :autocomplete="noAutoComplete.description" class="form-control" :id="noAutoComplete.description" v-model.lazy="description">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label :for="noAutoComplete.invoiceAmount">{{getLocalMsg('booking.invoiceAmount')}}</label>
                        <input type="text" :autocomplete="noAutoComplete.invoiceAmount" class="form-control" :id="noAutoComplete.invoiceAmount" v-model.lazy="invoiceAmountInput">
                    </div>
                </div>
                <div v-if="book === 'P'" class="col-2">
                    <div class="form-group">
                        <label :for="noAutoComplete.professionalShare">{{getLocalMsg('booking.professionalShare')}}</label>
                        <div class="input-group">
                            <input type="text" :autocomplete="noAutoComplete.professionalShare" class="form-control" :id="noAutoComplete.professionalShare" v-model.lazy="professionalShare">
                            <div class="input-group-append">
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <label class="detail ml-2">{{getLocalMsg('booking.professionalShare')}} : {{invoiceAmountProfessionalPart}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group pl-4 d-inline-block">
                        <input type="checkbox" tabindex="-1" class="form-check-input" id="no-vat-declaration" v-model="noVatDeclaration">
                        <label class="form-check-label" for="no-vat-declaration">
                            <span>{{getLocalMsg('booking.noVatDeclaration')}}</span>
                        </label>
                    </div>
                    <div class="form-group ml-4 pl-4 d-inline-block">
                        <div v-if="relationtypeCode==='BE' || relationtypeCode==='EU'">
                            <input type="checkbox" tabindex="-1" class="form-check-input" id="vat-shifted" v-model="vatShifted">
                            <label class="form-check-label" for="vat-shifted">
                                <span v-if="relationtypeCode==='BE'">{{getLocalMsg('booking.vatShifted')}}</span>
                                <span v-if="relationtypeCode==='EU'">{{getLocalMsg('booking.realEstate')}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box bg-white">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th v-if="book === 'P'">{{getLocalMsg('booking.account')}}</th>
                            <th>{{getLocalMsg('booking.category')}}</th>
                            <!-- <th>{{getLocalMsg('booking.description')}}</th> -->
                            <th>{{getLocalMsg('booking.vattype')}}</th>
                            <th class="text-right">{{getLocalMsg('booking.amountExclVat')}}</th>
                            <th class="text-right">{{getLocalMsg('booking.amountVat')}}</th>
                            <th class="text-right">{{getLocalMsg('booking.amountInclVat')}}</th>
                            <th></th>
                            <th v-if="book === 'P'">{{getLocalMsg('booking.deductibleVat')}}</th>
                            <th v-if="book === 'P'">{{getLocalMsg('booking.deductibleCosts')}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <bookline-edit 
                            v-bind:ref="'bookline-' + bookline.id"
                            v-for="(bookline, index) in booklines" 
                            v-bind:key="index" 
                            v-bind:id="bookline.id" 
                        />
                    </tbody>
                    <tfoot>
                        <th v-if="book === 'P'"></th>
                        <th></th>
                        <!-- <th></th> -->
                        <th></th>
                        <th class="text-right"><span class="pr-2">{{amountExclVat ? amountExclVat : fixAmount(0,2)}}</span></th>
                        <th class="text-right" v-bind:class="{'not-applicable': !vatApplicable($store.state.bookings.activeItem).value}">{{amountVat ? amountVat : fixAmount(0,2)}}</th>
                        <th class="text-right" v-bind:class="{'not-applicable': !vatApplicable($store.state.bookings.activeItem).value}">{{amountInclVat ? amountInclVat : fixAmount(0,2)}}</th>
                        <th v-if="book === 'P'"></th>
                        <th v-if="book === 'P'"></th>
                        <th></th>
                    </tfoot>
                </table>
                <div class="float-right">
                    <button v-on:click="generateInvoice" tabindex="0" class="btn btn-primary mr-3">Invoice</button>
                    <button v-on:click="save('back')" tabindex="0" class="btn btn-primary mr-3">{{getLocalMsg('global.save')}}</button>
                    <button v-if="propNewItem" v-on:click="save('next')" tabindex="0" class="btn btn-primary mr-3">{{getLocalMsg('global.saveAndNext')}}</button>
                    <button v-on:click="cancel" tabindex="0" class="btn btn-secondary">{{getLocalMsg('global.cancel')}}</button>
                </div>
                <modal-template ref="modalNewRelation">
                    <template v-slot:header>
                        <h5 class="modal-title">{{book === 'P' ? getLocalMsg('booking.newSupplier') : getLocalMsg('booking.newClient')}}</h5>
                        <button type="button" class="close" data-dismiss="modal">
                            <span>&times;</span>
                        </button>
                    </template>
                    <template v-slot:body>
                        <relation-edit 
                            ref="relationEdit"
                            v-bind:propModalStyle="true"
                            v-bind:propNewItem="true"
                            v-bind:propClientSupplier="book === 'P' ? 'S' : 'C'"
                            v-bind:propEditable="true"
                        />
                    </template>
                    <template v-slot:footer>
                        <button type="button" v-on:click="updateRelation()" class="btn btn-primary">{{getLocalMsg('global.save')}}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button>
                    </template>
                </modal-template>
                <modal-template ref="modalConfirms">
                    <template v-slot:body>
                        <ul>
                            <li 
                                v-for="(confirm, index) in confirms" 
                                v-bind:key="index"
                            >{{confirm}}</li>
                        </ul>
                    </template>
                    <template v-slot:footer>
                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('booking.confirms.correct')}}</button>
                        <button type="button" v-on:click="updateItem" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('booking.confirms.proceed')}}</button>
                    </template>
                </modal-template>
            </div>
        </div>
    </div>
    
</template>

<script>

import BooklineEdit from '../bookings/BooklineEdit.vue'
import RelationEdit from '../relations/RelationEdit.vue'
import ModalTemplate from '../../shared/ModalTemplate.vue'
import SearchAndSelect from '../../shared/SearchAndSelect.vue'
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jsPDF from 'jspdf'

import sharedMixin from '../../mixins/shared-mixin';
import ValidationAlert from '../../shared/ValidationAlert';

export default {
    name: 'BookingEdit',
    mixins: [sharedMixin],
    components: {
        BooklineEdit,
        datePicker,
        ModalTemplate,
        RelationEdit,
        SearchAndSelect,
        ValidationAlert
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded)
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    mounted() {
        
    },
    props: {
        propBook: String,
        propNewItem: Boolean
    },
    data () {
        return {
            confirms: [],
            validationErrors: [],
            redirectAfterSave: '',
            noAutoComplete: {
                invoiceDate: this.noAutoCompleteId(),
                reference: this.noAutoCompleteId(),
                description: this.noAutoCompleteId(),
                invoiceAmount: this.noAutoCompleteId(),
                professionalShare: this.noAutoCompleteId()
            },
        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.categories.loaded ? loaded : false;
            loaded = this.$store.state.relations.loaded ? loaded : false;
            loaded = this.$store.state.accounts.loaded ? loaded : false;
            loaded = this.$store.state.vattypes.loaded ? loaded : false;
            loaded = this.$store.state.writeoffs.loaded ? loaded : false;
            return loaded;
        },
        vatShifted: {
            get: function () {
                return this.getItemProperty('bookings','vatShifted');
            },
            set: function (value) {

                this.setItemProperty('bookings','vatShifted',value).then(
                    () => this.$store.dispatch('bookings/setBookingTotals').then(
                        () => this.$store.dispatch('bookings/recalcBookline')
                    )
                );

            }  
        },
        noVatDeclaration: {
            get: function () {
                return this.getItemProperty('bookings','noVatDeclaration');
            },
            set: function (value) {

                this.setItemProperty('bookings','noVatDeclaration',value).then(
                    () => this.$store.dispatch('bookings/setBookingTotals').then(
                        () => this.$store.dispatch('bookings/recalcBookline')
                    )
                );

            }  
        },
        relationtypeCode: function () {
            if(!this.emptyObject(this.relation)){
                return this.relation.relationtype.code;
            }
            return;
        },
        relationLabel(){
            return this.book === 'P' ? this.getLocalMsg('booking.supplier') : this.getLocalMsg('booking.client');
        },
        professionalShare: {
            get: function () {
                return this.getItemProperty('bookings','professionalShare');
            },
            set: function (value) {
                value=value.replace(',','.');
                if(isNaN(parseInt(value))) {
                    value = null;
                } else {
                    value = this.roundAmount(value,0);
                }
                this.setItemProperty('bookings','professionalShare',value).then(
                    () => this.$store.dispatch('bookings/setBookingTotals').then(
                        () => this.$store.dispatch('bookings/recalcBookline')
                    )
                );
            }  
        },
        writeOffs () {
            
            return this.getItems('writeoffs');
        },
        relations () {
            
            return this.getItems('relations');
        },
        relationIsValid () {
            
            let valid = true;

            //Er moet een relatie ingevuld zijn
            if(this.emptyObject(this.relation)){
                valid = false
            }
            //De tekst in het inputveld relatie moet overeenkomen met de computed property relation
            const x = this.relations.find(item => {
                return item.name === this.$refs.relation.searchValue;
            });
            if(!x){
                valid = false;
            }
            return valid;
        },
        id () {
            return this.getItemProperty('bookings','id');
        },
        refNumber () {
            return this.getItemProperty('bookings','refNumber');
        },
        description: {
            get: function () {
                return this.getItemProperty('bookings','description');
            },
            set: function (value) {
                this.setItemProperty('bookings','description',value);

            }   
        },
        reference: {
            get: function () {
                return this.getItemProperty('bookings','reference');
            },
            set: function (value) {
                this.setItemProperty('bookings','reference',value);
            }   
        },
        book: function () {
                return this.getItemProperty('bookings','book');  
        },
        relation: {
            get: function () {
                return this.getItemProperty('bookings','relation');
            },

            set: function (value) {

                //Reset BTW Verlegd voor alles behalve EU of BE
                if(value.relationtype.code!=='EU' && value.relationtype.code!=='BE'){
                    this.setItemProperty('bookings','vatShifted',false)
                }


                this.setItemProperty('bookings','relation',value).then(
                    () => this.$store.dispatch('bookings/setBookingTotals').then(
                        () => this.$store.dispatch('bookings/recalcBookline')
                    )
                );

            }   
        },
        year: function () {
                return this.getItemProperty('bookings','year');
        },
        period: function () {
                return this.getItemProperty('bookings','period'); 
        },
        invoiceDate: {
            get: function () {
                const dateString = this.getItemProperty('bookings','invoiceDate');
                if(dateString) {
                    return new Date(dateString);
                }
                return null;

            },
            set: function (value) {
                if(value){
                    const date = moment(value,this.dateTimePickerOptions.format);
                    value = date.format('YYYY-MM-DD');
                }else{
                    value = null;
                }

                this.setItemProperty('bookings','invoiceDate',value);
                
            }   
        },
        invoiceAmountInput: {
            get: function () {
                const result = this.getItemProperty('bookings','invoiceAmountInput');
                return this.fixAmount(result,2);
            },
            set: function (value) {
                value=value.replace(',','.');
                if(isNaN(parseInt(value))) {
                    value = null;
                } else {
                    value = this.roundAmount(value,2);
                }

                this.setItemProperty('bookings','invoiceAmountInput',value).then(
                    () => this.$store.dispatch('bookings/setBookingTotals').then(
                        () => this.$store.dispatch('bookings/recalcBookline')
                    )
                );
            }   
        },
        booklines () {
            return this.getItemProperty('bookings','booklines');
        },
        vatcodes () {
            return this.getItemProperty('bookings','vatcodes');
        },
        amountExclVat () {
            const result = this.getItemProperty('bookings','amountExclVat');
            return this.fixAmount(result,2);
        },
        amountVat () {
            const result = this.getItemProperty('bookings','amountVat');
            return this.fixAmount(result,2);
        },
        amountInclVat () {
            const result = this.getItemProperty('bookings','amountInclVat');
            return this.fixAmount(result,2);
        },
        invoiceAmountProfessionalPart () {
            return this.fixAmount(this.invoiceAmountInput*(this.professionalShare/100),2);
        },
        redirectUrlPart(){
            return this.book === 'P' ? 'purchase' : 'sale';
        }
    },
    methods: {
        initiate (loaded) {

            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'bookings',
                property: 'book',
                value: this.propBook
            })
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'categories',
                property: 'type',
                value: this.propBook
            })                                     
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'relations',
                property: 'clientSupplier',
                value: this.propBook === 'P' ? 'S' : 'C'
            })
            this.$store.dispatch('setActiveItem', {
                moduleName: 'bookings',
                newItem: this.propNewItem,
                item: this.propNewItem ? {} : this.$store.state.bookings.items.find (item => {
                    return item.id === Number(this.$route.params.id)
                })
            })
            this.$nextTick(() => {
                this.$refs.relation.$refs.searchField.focus();
            })
            this.$store.commit('showSpinner',false);
        },
        updateRelation(){


           this.$refs.relationEdit.save().then((result) => {
               if(result !== 'NotValidated') {
                   this.relation = this.$store.state.relations.activeItem;
                    if(this.relation.name){
                        this.$refs.relation.searchValue = this.relation.name;
                    }
                    $(this.$refs.modalNewRelation.$el).modal('hide');

                }
           });
            
        },
        validate() {

            let validate = true;
            this.validationErrors = [];

            if(this.isEmpty(this.invoiceDate)){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('booking.invoiceDate')
                });
                validate=false;
            }

            if(this.isEmpty(this.invoiceAmountInput)){
                this.validationErrors.push({
                    type: 'numeric',
                    field: this.getLocalMsg('booking.invoiceAmount')
                });
                validate=false;
            }

            let check = true;
            check = !this.isEmpty(this.professionalShare) ? check : false;
            check = this.professionalShare >=0 ? check : false;
            check = this.professionalShare <=100 ? check : false;

            if(!check){
                this.validationErrors.push({
                    type: 'between',
                    field: this.getLocalMsg('booking.professionalShare'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }
            check = this.isInteger(this.professionalShare);

            if(!check){
                this.validationErrors.push({
                    type: 'integer',
                    field: this.getLocalMsg('booking.professionalShare')
                });
                validate=false;
            }

            if(!this.relationIsValid) {
                this.validationErrors.push({
                    type: 'searchandselect',
                    field: this.relationLabel
                });
                validate=false;
            }

            if(this.totalsNotMatching(this.$store.state.bookings.activeItem)) {
                this.validationErrors.push({
                    type: 'custom',
                    message: this.getLocalMsg('booking.totalsNotMatching')
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    return this.emptyObject(item.account);
                })
            ){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('booking.account')
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    let check = true;
                    check = item.account.type === 'I' && !this.isInteger(item.term) ? false : check;
                    check = item.account.type === 'I' && item.term < 2 ? false : check;
                    return !check;
                })
            ){
                this.validationErrors.push({
                    type: 'min',
                    field: this.getLocalMsg('booking.term'),
                    min: 2
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    return this.emptyObject(item.category);
                })
            ){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('booking.category')
                });
                validate=false;
            }

            
            if(
                this.booklines.find(item => {
                    return this.emptyObject(item.vattype);
                })
            ){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('booking.vattype')
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    return this.isEmpty(item.amountExclVat);
                })
            ){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('booking.amount')
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    let check = true;
                    check = !this.isEmpty(item.deductibleCostsShare) ? check : false;
                    check = item.deductibleCostsShare >=0 ? check : false;
                    check = item.deductibleCostsShare <=100 ? check : false;
                    return !check;
                })
            ){
                this.validationErrors.push({
                    type: 'between',
                    field: this.getLocalMsg('booking.deductibleCosts'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    const check = this.isInteger(item.deductibleCostsShare);
                    return !check;
                })
            ){
                this.validationErrors.push({
                    type: 'integer',
                    field: this.getLocalMsg('booking.deductibleCosts'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    let check = true;
                    check = !this.isEmpty(item.deductibleVatShare) ? check : false;
                    check = item.deductibleVatShare >=0 ? check : false;
                    check = item.deductibleVatShare <=100 ? check : false;
                    return !check;
                })
            ){
                this.validationErrors.push({
                    type: 'between',
                    field: this.getLocalMsg('booking.deductibleVat'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }

            if(
                this.booklines.find(item => {
                    const check = this.isInteger(item.deductibleVatShare);
                    return !check;
                })
            ){
                this.validationErrors.push({
                    type: 'integer',
                    field: this.getLocalMsg('booking.deductibleVat'),
                    min: 0,
                    max: 100
                });
                validate=false;
            }

            if(
                this.booklines.find(bookline => {
                    return this.writeOffs.find(writeOff => {
                        return Number(writeOff.purchaseId) === Number(bookline.id) &&
                        writeOff.saleId;
                    });
                })
            ){
                this.validationErrors.push({
                    type: 'custom',
                    message: this.getLocalMsg('booking.saleExists')
                });
                validate=false;
            }


            return validate;

        },
        save(redirectAfterSave){
            this.redirectAfterSave = redirectAfterSave;
            this.validateAndUpdateItem();
        },
        cancel(){
            this.$router.push('/admin/' + this.redirectUrlPart + 's');
        },
        validateAndUpdateItem(){

            if(!this.validate()){
                return;
            };

            this.confirms = [];

            //Check of er al manuele aanpassingen gedaan zijn aan de afschrijvingen indien het een investering betreft
            if(
                this.booklines.find(bookline => {
                    return this.writeOffs.find(writeOff => {
                        return Number(writeOff.purchaseId) === Number(bookline.id) &&
                        writeOff.manual;
                    });
                })
            ){
                this.confirms.push(this.getLocalMsg('booking.confirms.manualWriteOffs'));
            }

            //Check if reference already exist
            if (this.referenceAlreadyExists()) {
                this.confirms.push(this.getLocalMsg('booking.confirms.referenceAlreadyExists'));
            }

            //Check if date is in right period
            if (this.dateNotInBookingPeriod()) {
                this.confirms.push(this.getLocalMsg('booking.confirms.dateNotInBookingPeriod'));
            }

            if (this.confirms.length>0){
                this.showModal('modalConfirms');
                return;
            }

            return this.updateItem();

        },
        dateNotInBookingPeriod(){
            
            let result=false;
            
            const date = new Date(this.invoiceDate);
            const year = date.getFullYear().toString();
            const period = 'Q' + this.getQuarter(date);
            if(year !== this.year || period !== this.period) {
                result = true;
            }

            return result;
        },
        referenceAlreadyExists(){
            //Check if reference already exist on all bookings of the same relation and same book, except this one
            let result = false;
            if(this.reference) {
                let references = this.$store.state.bookings.items.filter((item) => {
                    return (
                        item.book === this.book && 
                        item.relation.id === this.relation.id && 
                        item.id !== this.id && item.reference
                    );
                })    
                if(references.length>0){
                    references = references.map((item) => {
                        return item.reference.replace(/\s/g,'').trim().toLowerCase();
                    })
                    result = references.includes(this.reference.replace(/\s/g,'').trim().toLowerCase());
                }
            }else{
                result=false;
            }

            return result;
        },
        addNewRelation() {
            const data = {
                moduleName: 'relations',
                newItem: true,
            };
            this.$store.dispatch('setActiveItem', data);
            this.showModal('modalNewRelation');
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        commitRelation(event){
            this.relation = event;
        },
        title () {

            let prepend = '';
            //let append = '';
            if(this.id){
                prepend = this.getLocalMsg('global.edit') + ' '
                //append = ' ' + this.refNumber
            }else{
                prepend = this.getLocalMsg('global.newe') + ' '
            }
            let append = ' ' + this.refNumber;
            if(this.book === 'P'){
                return prepend + this.getLocalMsg('booking.purchase') + append + ' - ' + this.year + ' - ' + this.getLocalMsg('global.' + this.period);
            }else{
                return prepend + this.getLocalMsg('booking.sale') + append + ' - ' + this.year + ' - ' + this.getLocalMsg('global.' + this.period);
            }
        },
        generateInvoice(){
            let pdfName = 'test'; 
            var doc = new jsPDF();
            doc.text("Hello World", 10, 10);
            doc.save(pdfName + '.pdf');
        },
        applyRelationDefaults(){
            //Relatie van de boeking instellen als actieve relatie
            return this.$store.dispatch('setActiveItem', {
                moduleName: 'relations',
                newItem: false,
                item: this.relation
            }).then(
                () => {
                    //Defaults in relatie toepassen obv waarden in de eerste boeklijn
                    this.setItemProperty('relations','defaultAccount',this.booklines[0].account);
                    this.setItemProperty('relations','defaultCategory',this.booklines[0].category);
                    this.setItemProperty('relations','defaultVattype',this.booklines[0].vattype);
                }
            )
        },
        updateItem() {

            this.$store.commit('showSpinner', true);
            
            const applyRelationDefaults = () => {
                return this.applyRelationDefaults();
            }
            const updateRelation = applyRelationDefaults().then(() => {
                return this.$store.dispatch('updateRelation');
            })
            const updateBooking = updateRelation.then(() => {
                return this.$store.dispatch('updateBooking');
            })

            const fetchWriteoffs = updateBooking.then(()=>{
                this.$store.commit('setLoadedState', {
                    moduleName: 'writeoffs',
                    loaded: false
                });
                return this.$store.dispatch('fetchData', 'writeoffs');
            })

            return fetchWriteoffs
            .then(() => {
                if(this.redirectAfterSave === 'back'){
                     this.$router.push('/admin/' + this.redirectUrlPart + 's');
                }else {
                    this.$router.push({
                        name: 'Redirect', params: {path: '/admin/' + this.redirectUrlPart + '/new' }
                    });
                }
            })
            .catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )
            

        },
    }
}

</script>

<style lang="scss">
    .detail-row {
        td {
            border: 0;
            padding-top: 0;
            padding-bottom:0.75rem;
        }
    }
    .detail-row:hover {
        background-color: transparent !important;
        color: #212529 !important;
    }
    .detail {
        font-size: 0.8rem;
    }
</style>
