       
export default {

    /**
     * [A] Omzet (excl BTW)
     * @param {*} state 
     * @param {*} getters 
     */
    getSales (state,getters) {
        const data = {
            measure: 'deductibleCosts',
            filters: {
                years: [state.selection.year],
                books: ['S']
            }
        }
        return getters.getTotals(data);
    },
    /**
     * Overige kosten per categorie
     * @param {*} state 
     * @param {*} getters 
     */
    getOtherGoodsByCategory(state, getters) {
        const data = {
            dimension: 'categoryName',
            measure: 'deductibleCosts',
            filters: {
                accountTypes: ['S', 'O'],
                years: [state.selection.year],
                books: ['P']
            }
        }
        return getters.getTotals(data);
    },
    /**
     * [D] Overige kosten
     * @param {*} state 
     * @param {*} getters 
     */
    getOtherGoods(state, getters) {
        const data = {
            measure: 'deductibleCosts',
            filters: {
                accountTypes: ['S', 'O'],
                years: [state.selection.year],
                books: ['P']
            }
        }
        return getters.getTotals(data);

    },
    /**
     * Voorraadwijzigingen (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getStockChange(state,getters,rootState) {
        const stockchange = rootState.stockchanges.items.find(item => {
            return item.year === state.selection.year;
        })
        if(!stockchange){
            return {};
        }
        const change = stockchange.end - stockchange.initial;
        return {
            Total: {
                Total: change
            }
        }
    },
    /**
     * [F] Jaarlijkse afschrijvingen (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getWriteOff(state, getters, rootState) {
        const writeOffs = rootState.writeoffs.items.filter(item => {
            return item.year === state.selection.year;
        })
        const total = writeOffs.reduce((a, b) => {
            return a + b.deductible;
        },0)
        return {
            Total: {
                Total: total
            }
        }

    },
    /**
     * Aankoop handelsgoederen, grond - en hulpstoffen
     * @param {*} state 
     * @param {*} getters 
     */
    getRetailGoods(state, getters) {
        const data = {
            measure: 'deductibleCosts',
            filters: {
                accountTypes: ['G'],
                years: [state.selection.year],
                books: ['P']
            }
        }
        return getters.getTotals(data);
    },
    /**
     * [B] Kosten handelsgoederen, grond - en hulpstoffen
     * @param {*} state 
     * @param {*} getters 
     */
    getRetailCosts(state,getters) {
        return calc(getters.getRetailGoods, getters.getStockChange, '-');
    },
    /**
     * [C] Bruto winst = [A]-[B]
     * @param {*} state 
     * @param {*} getters 
     */
    getGrossProfit(state,getters) {
        return calc(getters.getSales, getters.getRetailCosts, '-');
    },/**
     * Bruto marge = [C]/[A]
     * @param {*} state 
     * @param {*} getters 
     */
    getGrossMargin(state, getters) {
        return calc(getters.getGrossProfit, getters.getSales, '/%');
    },
    /**
     * [E] Operationele winst = [C]-[D]
     * @param {*} state 
     * @param {*} getters 
     */
    getOperatingProfit(state, getters) {
        return calc(getters.getGrossProfit, getters.getOtherGoods, '-');
    },
    /**
     * Operationele marge = [E] / [A]
     * @param {*} state 
     * @param {*} getters 
     */
    getOperatingMargin(state, getters) {
        return calc(getters.getOperatingProfit, getters.getSales, '/%');
    },
    /**
     * Netto marge = [G]/[A]
     * @param {*} state 
     * @param {*} getters 
     */
    getNetMargin(state, getters) {
        return calc(getters.getTaxableProfit, getters.getSales, '/%');
    },
    /**
     * [G] Belastbare winst = [E]-[F]
     * @param {*} state 
     * @param {*} getters 
     */
    getTaxableProfit(state, getters) {
        return calc(getters.getOperatingProfit, getters.getWriteOff, '-');
    },
    /**
     * Verworpen uitgaven (Niet aftrekbare kosten)
     * @param {*} state 
     * @param {*} getters 
     */
    getNonDeductibleCosts(state, getters) {

        let data = {};

        data = {
            measure: 'amountInclVat',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        const amountInclVat = getters.getTotals(data);

        data = {
            measure: 'deductibleVat',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        const deductibleVat = getters.getTotals(data);

        data = {
            measure: 'deductibleCosts',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        const deductibleCosts = getters.getTotals(data);

        const amount = calc(amountInclVat, deductibleVat, '-');
        return calc(amount, deductibleCosts, '-');

    },
    /**
     * Privé gedeelte aankopen
     * @param {*} state 
     * @param {*} getters 
     */
    getPrivatePart(state,getters) {

        let data = {};

        data = {
            measure: 'amountExclVat',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        const amountExclVat = getters.getTotals(data);

        data = {
            measure: 'invoiceAmountExclVat',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        const invoiceAmountExclVat = getters.getTotals(data);


        return calc(invoiceAmountExclVat, amountExclVat, '-');

    },
    /**
     * Verworpen uitgaven (Niet aftrekbare afschrijvingen) (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getNonDeductibleWriteOff(state, getters,rootState) {
        const writeOffs = rootState.writeoffs.items.filter(item => {
            return item.year === state.selection.year;
        })
        const total = writeOffs.reduce((a, b) => {
            let result = b.writeOff;
            const professional = b.writeOff * (b.professionalShare / 100);
            const deductible = professional * (b.deductibleShare / 100);
            const nonDeductible = professional - deductible
            return a + nonDeductible;
        }, 0);
        return {
            Total: {
                Total: total
            }
        }
    },
    /**
     * Privé gedeelte afschrijvingen (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getPrivatePartWriteOff(state, getters, rootState) {
        const writeOffs = rootState.writeoffs.items.filter(item => {
            return item.year === state.selection.year;
        })
        const total = writeOffs.reduce((a, b) => {
            const professional = b.writeOff * (b.professionalShare / 100);
            const privatePart = b.writeOff - professional
            return a + privatePart;
        }, 0);
        return {
            Total: {
                Total: total
            }
        }
    },
    /**
     * Gerealiseerde meerwaarde op verkoop van investeringen - Verkoop (beroepsgedeelte) (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getAddedValueInvestmentSales(state, getters) {
        return {}
    },
    /**
     * Gerealiseerde meerwaarde op verkoop van investeringen - Niet afgeschreven bedrag (nog uit te werken) (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getNotWrittenOffInvestments(state, getters) {
        return {}
    },
    /**
     * Gerealiseerde meerwaarde op verkoop van investeringen - Meerwaarde (nog uit te werken)
     * @param {*} state 
     * @param {*} getters 
     */
    getAddedValueInvestments(state, getters) {
        return {}
    },
    /**
     * Totaal Aankopen
     * @param {*} state 
     * @param {*} getters 
     */
    getPurchases(state, getters) {
        const data = {
            measure: 'deductibleCosts',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        return getters.getTotals(data);
    },
    /**
     * Totaal aankopen per categorie
     * @param {} state 
     * @param {*} getters 
     */
    getPurchasesByCategory(state, getters) {
        const data = {
            dimension: 'categoryName',
            measure: 'deductibleCosts',
            filters: {
                years: [state.selection.year],
                books: ['P']
            }
        }
        return getters.getTotals(data);
    },
    /**
     * Lijst met totalen van een bepaald veld over een bepaalde dimensie
     * Alle maanden en kwartalen + het jaartotaal
     * Wordt o.a. gebruikt voor de resultatenrekening
     * @param {*} state 
     * @param {*} dimension
     * @param {*} measure
     * @param {*} filters
     */
    
    
    getTotals: (state) => ({dimension, measure, filters}) => {

        //Alle boekingen opvragen en filteren obv parameter filters
        const bookings = state.items.filter(item => {
            
            //Filter jaar toepassen
            if (filters.years) {
                if (!filters.years.includes(item.year)) {
                    return false;
                }
            }
            //Filter boek toepassen
            if (filters.books) {
                if (!filters.books.includes(item.book)) {
                    return false;
                }
            }
            return true;
            
        });

        let result = {};

        //Door alle boekingen loopen
        for (let i = 0; i < bookings.length; i++) {

            const booking = bookings[i];

            //Bepalen maand en kwartaal van de boeking
            //Kwartaal & Maand kan afwijkend zijn van de BTW-periode aangezien er gewerkt wordt met de effectieve factuurdatum
            //De state.bookings.selection.period wordt gebruikt ifv BTW-aangifte
            const month = Number(booking.invoiceDate.substr(5, 2));
            //Indien factuurdatum uit vorig jaar dateert, wordt het op januari van huidig jaar gezet.
            const year = Number(booking.invoiceDate.substr(0, 4));
            if(year<Number(booking.year)) {
                month = 1;
            }
            //Indien factuurdatum uit volgend jaar dateert, wordt het op december van huidig jaar gezet.
            if(year>Number(booking.year)) {
                month = 12;
            }
            const monthKey = 'M' + ('00' + month).substr(-2);
            const quarter = Math.ceil(month / 3);
            const quarterKey = 'Q' + quarter;

            //Indien geen filter op rekening en indien geen dimensie wordt meegenomen
            //dan kan gewerkt worden met het totaal van de boeking
            if (!filters.accountTypes && !dimension) {
                
                let dim = 'Total';
                const amount = booking[measure];
                
                //Initialisatie van alle waarden
                if (!result[dim]) {
                    result[dim] = initResults();
                }

                //Optellen bedragen op maand, kwartaal en totaalbasis
                result[dim][monthKey] += amount;
                result[dim][quarterKey] += amount;
                result[dim]['Total'] += amount;

            } else {
                //In het andere geval moet er over de boeklijnen geloopt worden
                for (let j = 0; j < booking.booklines.length; j++) {

                    const bookline = booking.booklines[j];

                    //Filteren op rekeningen
                    if (filters.accountTypes) {
                        if (!filters.accountTypes.includes(bookline.account.type)) {
                            continue;
                        }
                    }

                    //Indien een dimensie meegegeven is, totalen berekenen per dimensie, anders Total
                    let dim = 'Total';
                    switch (dimension) {
                        case 'categoryName':
                            dim = bookline.category.name
                            break;
                        case 'accountName':
                            dim = bookline.account.name
                            break;
                    }
                    const amount = bookline[measure];

                    //Initialisatie van alle waarden
                    if (!result[dim]) {
                        result[dim] = initResults();
                    }

                    //Optellen bedragen op maand, kwartaal en totaalbasis
                    result[dim][monthKey] += amount;
                    result[dim][quarterKey] += amount;
                    result[dim]['Total'] += amount;

                }

            }
     
        }
        return result;


    },

}


/**
 * Helper functie om objecten met totalen bij mekaar op te tellen, te delen, af te trekken, ...
 * @param {*} val1 
 * @param {*} val2 
 * @param {*} operator 
 */
const calc = (val1, val2, operator) => {


    const operators = {
        '-': (a, b) => {
            return a - b
        },
        '+': (a, b) => {
            return a + b
        },
        '/': (a, b) => {
            return a / b
        },
        '/%': (a, b) => {
            return (a / b) * 100
        },
    };

    return {
        Total: {
            Q1: operators[operator]((val1.Total ? val1.Total.Q1 ? val1.Total.Q1 : 0 : 0), (val2.Total ? val2.Total.Q1 ? val2.Total.Q1 : 0 : 0)),
            Q2: operators[operator]((val1.Total ? val1.Total.Q2 ? val1.Total.Q2 : 0 : 0), (val2.Total ? val2.Total.Q2 ? val2.Total.Q2 : 0 : 0)),
            Q3: operators[operator]((val1.Total ? val1.Total.Q3 ? val1.Total.Q3 : 0 : 0), (val2.Total ? val2.Total.Q3 ? val2.Total.Q3 : 0 : 0)),
            Q4: operators[operator]((val1.Total ? val1.Total.Q4 ? val1.Total.Q4 : 0 : 0), (val2.Total ? val2.Total.Q4 ? val2.Total.Q4 : 0 : 0)),
            M01: operators[operator]((val1.Total ? val1.Total.M01 ? val1.Total.M01 : 0 : 0), (val2.Total ? val2.Total.M01 ? val2.Total.M01 : 0 : 0)),
            M02: operators[operator]((val1.Total ? val1.Total.M02 ? val1.Total.M02 : 0 : 0), (val2.Total ? val2.Total.M02 ? val2.Total.M02 : 0 : 0)),
            M03: operators[operator]((val1.Total ? val1.Total.M03 ? val1.Total.M03 : 0 : 0), (val2.Total ? val2.Total.M03 ? val2.Total.M03 : 0 : 0)),
            M04: operators[operator]((val1.Total ? val1.Total.M04 ? val1.Total.M04 : 0 : 0), (val2.Total ? val2.Total.M04 ? val2.Total.M04 : 0 : 0)),
            M05: operators[operator]((val1.Total ? val1.Total.M05 ? val1.Total.M05 : 0 : 0), (val2.Total ? val2.Total.M05 ? val2.Total.M05 : 0 : 0)),
            M06: operators[operator]((val1.Total ? val1.Total.M06 ? val1.Total.M06 : 0 : 0), (val2.Total ? val2.Total.M06 ? val2.Total.M06 : 0 : 0)),
            M07: operators[operator]((val1.Total ? val1.Total.M07 ? val1.Total.M07 : 0 : 0), (val2.Total ? val2.Total.M07 ? val2.Total.M07 : 0 : 0)),
            M08: operators[operator]((val1.Total ? val1.Total.M08 ? val1.Total.M08 : 0 : 0), (val2.Total ? val2.Total.M08 ? val2.Total.M08 : 0 : 0)),
            M09: operators[operator]((val1.Total ? val1.Total.M09 ? val1.Total.M09 : 0 : 0), (val2.Total ? val2.Total.M09 ? val2.Total.M09 : 0 : 0)),
            M10: operators[operator]((val1.Total ? val1.Total.M10 ? val1.Total.M10 : 0 : 0), (val2.Total ? val2.Total.M10 ? val2.Total.M10 : 0 : 0)),
            M11: operators[operator]((val1.Total ? val1.Total.M11 ? val1.Total.M11 : 0 : 0), (val2.Total ? val2.Total.M11 ? val2.Total.M11 : 0 : 0)),
            M12: operators[operator]((val1.Total ? val1.Total.M12 ? val1.Total.M12 : 0 : 0), (val2.Total ? val2.Total.M12 ? val2.Total.M12 : 0 : 0)),
            Total: operators[operator]((val1.Total ? val1.Total.Total ? val1.Total.Total : 0 : 0), (val2.Total ? val2.Total.Total ? val2.Total.Total : 0 : 0)),
        }
    }
}

/**
 * Helper functie om resultaten te initialiseren
 */
const initResults = () => {
    return {
        'Q1': 0,
        'Q2': 0,
        'Q3': 0,
        'Q4': 0,
        'M01': 0,
        'M02': 0,
        'M03': 0,
        'M04': 0,
        'M05': 0,
        'M06': 0,
        'M07': 0,
        'M08': 0,
        'M09': 0,
        'M10': 0,
        'M11': 0,
        'M12': 0,
        'Total': 0
    }
}
