       
import Helpers from '../../../../admin/helpers';
import results from './results';
import vatResults from './vatresults';

const mainGetters = {

    /**
     * Property van een boeklijn uit de actieve boeking ophalen
     */
    getBooklineProperty: (state) => ({booklineId,property}) => {
        const item = state.activeItem;
        const bookline = Helpers.getItem(item.booklines, booklineId);
        const result = bookline[property];
        return result;
    },
    
    

}

export default { ...mainGetters, ... results, ... vatResults};

