
<template>
    <div class="position-relative">
        <div class="input-group">
            <div class="input-group-prepend">
                <slot name="prepend"></slot>
            </div>
            <input 
                type="text" 
                v-bind:placeholder="placeholder"
                :autocomplete="noAutoComplete.searchField" 
                v-model="searchItem" 
                class="form-control" 
                v-on:keyup.down="down"
                v-on:keyup.up="up"
                v-on:keydown.tab.prevent="commit"
                v-on:keyup.enter="commit"
                ref="searchField"
            >
            <div class="input-group-append">
                <slot name="append"></slot>
            </div>
        </div>
        <div class="list-group collapse position-absolute" ref="resultList">
            <button 
                v-for="(foundItem, index) in foundItems"
                v-bind:key="index"
                type="button" 
                class="list-group-item list-group-item-action" 
                v-bind:class="{ 'active': index === 0 }"
                v-bind:data-id="foundItem.id"
                v-on:click="selectAndCommit($event)"
            >{{foundItem.name}}</button>
        </div>
    </div>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';

export default {
    name: 'SearchAndSelect',
    mixins: [sharedMixin],
    created(){
    },
    props: {    
        data: Array,
        nextFieldId: String,
        placeholder: String,
        currentSearchValue: String,
    },
    computed: {
        searchItem: {
            get: function () {
                return this.searchValue;
            },
            set: function (newValue) {
                this.searchValue = newValue;
                this.setFirstActive();
                this.showList();
            }
        },
        foundItems: function () {
            if(!this.searchItem){
                return [];
            }
            if(this.searchItem.length < 2){
                return [];
            }
            const foundItems = this.data.filter(item => {
                return item.name.toLowerCase().indexOf(this.searchItem.toLowerCase())!==-1;
            })
            return foundItems;
        }
    },
    data () {
        return {
            searchValue: this.currentSearchValue,
            noAutoComplete: {
                searchField: this.noAutoCompleteId()
            },
        }
    },
    methods: {
        down () {
            const resultList = this.$refs.resultList;
            const activeItem = resultList.querySelector('.active');
            if(!activeItem){
                return;
            }
            let nextItem;
            if(activeItem === resultList.lastChild){
                nextItem = resultList.firstChild;
            }else {
                nextItem = activeItem.nextElementSibling;
            }

            activeItem.classList.remove('active');
            nextItem.classList.add('active');
        },
        up () {
            const resultList = this.$refs.resultList;
            const activeItem = resultList.querySelector('.active');
            if(!activeItem){
                return;
            }
            let previousItem;
            if(activeItem === resultList.firstChild){
                previousItem = resultList.lastChild;
            }else {
                previousItem = activeItem.previousElementSibling;
            }

            activeItem.classList.remove('active');
            previousItem.classList.add('active');
        },
        selectAndCommit (event) {
            const resultList = this.$refs.resultList;
            const activeItem = resultList.querySelector('.active');
            if(activeItem){
                activeItem.classList.remove('active');
            }
            event.currentTarget.classList.add('active');
            this.commit();

        },
        commit () {
            const resultList = this.$refs.resultList;
            const activeItem = resultList.querySelector('.active');
            this.searchItem = activeItem.textContent;
            this.hideList();

            const nextField = document.querySelector('#' + this.nextFieldId);    
            nextField.focus();  
            
            const itemToCommit = this.data.find(item => {
                return item.id === Number(activeItem.getAttribute('data-id'));
            })
            this.$emit('hit',itemToCommit);

        },
        hideList() {
            let element = this.$refs.resultList
            $(element).collapse('hide');
        },
        showList() {
            let element = this.$refs.resultList
            $(element).collapse('show');
        },
        setFirstActive() {
            const resultList = this.$refs.resultList;
            if(resultList.firstChild){
                resultList.firstChild.classList.add('active');
            }
        }

    }
}
</script>

<style lang="scss" scoped>
    .list-group {
        z-index:100;
        button.active {
            background-color: #56c0a6;
        }
    }
    
</style>
   
    
