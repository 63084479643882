<template>
  <div>  
    <div id="topDiv"></div>
    <div v-if="showSpinner" class="spinner-centered">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <router-view/>
    <modal-template  v-if="this.$router.history.current.name!=='404'" ref="modalError">
        <template v-slot:header>
            <h5 v-if="!mailSent && errorHeader" class="modal-title">{{errorHeader}}</h5>
            <h5 v-if="mailSent" class="modal-title">{{getLocalMsg('error.mailSentHeader')}}</h5>

            <!-- <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
            </button> -->
        </template>
        <template v-slot:body>
            <p v-if="!mailSent && errorBody">{{errorBody}}</p>
            <p v-if="!mailSent && errorTechnicalMessage"><small><strong>{{errorTechnicalTitle}}</strong><br>{{errorTechnicalMessage}}</small></P>
            <p v-if="!mailSent && errorFooter1">{{errorFooter1}}</p>
            <p v-if="!mailSent && errorFooter2">{{errorFooter2}}</p>
            <p v-if="mailSent">{{getLocalMsg('error.mailSentBody')}}</p>
        </template>
        <template v-slot:footer>
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.close')}}</button> -->
            <button v-if="mailSent || errorReloadPageButton" v-on:click="reloadPage" type="button" class="btn btn-primary">{{errorReloadPageButton}}</button>
            <button v-if="!mailSent && errorSendMailButton" v-on:click="sendMail" type="button" class="btn btn-primary">{{errorSendMailButton}}</button>
            <button v-if="!mailSent && errorOkButton" v-on:click="hideErrorModal" type="button" class="btn btn-primary">{{errorOkButton}}</button>
        </template>
    </modal-template>
  </div>
</template>

<script>

import NavSite from './shared/NavSite.vue'
import NavAdmin from './shared/NavAdmin.vue'
import ModalTemplate from './shared/ModalTemplate.vue'
import sharedMixin from './mixins/shared-mixin';

export default {
  components: {
    NavSite,
    NavAdmin,
    ModalTemplate
  },
  mixins: [sharedMixin],
  data () {
    return {
      mailSent: false
    }
  },
  mounted(){
    $(document).on('hidden.bs.modal',this.$refs.modalError, () => {
      this.dismissError();
    });
  },
  computed: {
    isAdmin() {
      console.log()
      return this.$router.currentRoute.fullPath.includes('/admin/');
    },
    hasError() {
      return this.$store.state.errors.hasError;
    },
    errorTechnicalTitle() {
      return this.errorTechnicalMessage ? this.getLocalMsg('error.technicalTitle') : '';
    },
    errorTechnicalMessage() {
      const technicalMessage = this.$store.state.errors.technicalMessage;
      return technicalMessage ? technicalMessage : '';
    },
    errorCode() {
      return this.$store.state.errors.code;
    },
    errorHeader() {
      const code = 'error.' + this.errorCode + '.header';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorBody() {
      const code = 'error.' + this.errorCode + '.body';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorFooter1() {
      const code = 'error.' + this.errorCode + '.footer1';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorFooter2() {
      const code = 'error.' + this.errorCode + '.footer2';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorReloadPageButton() {
      const code = 'error.' + this.errorCode + '.reloadPageButton';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorSendMailButton() {
      const code = 'error.' + this.errorCode + '.sendMailButton';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    errorOkButton() {
      const code = 'error.' + this.errorCode + '.okButton';
      const msg = this.getLocalMsg(code);
      return msg === code ? '' : msg;
    },
    showSpinner() {
      return this.$store.state.showSpinner;
    }
  },
  watch: {
    hasError: function(value) {
      if(value){
        this.showErrorModal();
      }
    }
  },
  methods: {
    dismissError(){
      this.$store.commit('dismissError');
    },
    showErrorModal() {
        let element = this.$refs.modalError.$el
        $(element).modal({
          backdrop: 'static',
          keyboard: false
        });
    },
    hideErrorModal() {
      let element = this.$refs.modalError.$el
      this.dismissError();
      $(element).modal('hide');
    },
    // sendMail() {
    //   let mailtoLink = 'mailto:';
    //   mailtoLink += 'test@example.com';
    //   mailtoLink += '?subject=' + this.getLocalMsg('error.mailSubject');
    //   mailtoLink += '&body=';
    //   mailtoLink += this.getLocalMsg('error.mailUser') + '%3A%20' + this.$store.state.user.email;
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += this.getLocalMsg('error.mailTimestamp') + '%3A%20' + Helpers.currentTimestamp();
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += this.getLocalMsg('error.mailCode') + '%3A%20' + this.errorCode;
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += this.getLocalMsg('error.mailMessage') + '%3A%20' + this.getLocalMsg('error.' + this.errorCode);
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += '%0D%0A';
    //   mailtoLink += this.getLocalMsg('error.mailTechnicalMessage') + '%3A%20' + this.errorTechnicalMessage;
    //   window.location.href = mailtoLink;
    // },
    sendMail() {

      const data = {
        currentTimestamp: this.currentTimestamp(),
        user: this.$store.state.user,
        errorCode: this.errorCode,
        errorTechnicalMessage: this.errorTechnicalMessage,
      }

      return axios.post('/api/mails/error', data)
        .then((result) => {
            this.mailSent = true;
        })
        .catch(error => {
            console.log(error);
            //commit('handleError', error.response.data);
        });
    },
    reloadPage(){
      window.location.href = '/admin/dashboard';
      //location.reload();
    },
  
    errorOk(){


    }
    
    
  }
  
}
</script>

<style lang="scss" scoped>

  .spinner-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    z-index: 100;
  }

</style>>


</style>
