<template>
    <div>
        <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
            <!-- Brand -->
            <a class="navbar-brand" href="#">{{getLocalMsg('global.siteName').toUpperCase()}}</a>

            <!-- Links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/dashboard')" class="nav-link" href="#"><i class="fas fa-home"></i></a>
                </li>
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/dashboard')" class="nav-link" href="#">{{getLocalMsg('navSite.price')}}</a>
                </li>
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/dashboard')" class="nav-link" href="#">{{getLocalMsg('navSite.about')}} {{getLocalMsg('global.siteName')}}</a>
                </li>
                <li class="nav-item">
                    <a v-on:click.prevent="$router.push('/admin/dashboard')" class="nav-link" href="#">{{getLocalMsg('navSite.contact')}}</a>
                </li>                
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" v-if="!isLoggedIn">
                    <a class="nav-link" href="/login">{{getLocalMsg('navSite.login')}}</a>
                </li>
                <li class="nav-item" v-if="!isLoggedIn">
                    <a class="nav-link" href="/register">{{getLocalMsg('navSite.register')}}</a>
                </li>
                <li class="nav-item dropdown" v-if="isLoggedIn">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{userName}}
                    </a>
                    <div class="dropdown-menu">
                        <a v-on:click.prevent="logout" class="dropdown-item" href="#">Logout</a>
                    </div>
                </li>
                <form ref="logout" action="/logout" method="POST" style="display: none;">
                    <input type="hidden" name="_token" v-bind:value="csrf">
                </form>
            </ul>
        </nav>
    </div>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';

export default {
    name: 'NavSite',
    mixins: [sharedMixin],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('value')
        }
    },
    computed: {
        isLoggedIn () {
            return this.$store.state.user.email;
        },
        userName () {
            return this.$store.state.user.name;
        },
    },
    methods: {
        logout(){
            this.$refs.logout.submit();
        },

    }
}
</script>

<style scoped>
    .navbar {
        background-color: #3f556a !important;
        font-size: 1.5rem;
        color: white;
        padding-right: 120px !important;
        padding-left: 120px !important;
    }
    .nav-link {
        color:white !important;
        font-size: 1.1rem;
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    
</style>
