export default {
    namespaced: true,
    state: {
        loaded: false,
        name: '',
        email: '',
        companies: [],
        selection: {
            activeCompany: {}
        }
    },
    
};