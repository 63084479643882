<template>
    <div class="modal" tabindex="-1">
        <div class="modal-dialog" v-bind:class="this.dialogClasses">
            <div class="modal-content">
            <div class="modal-header">
                <slot name="header"></slot>
            </div>
            <div class="modal-body">
                <slot name="body"></slot>
            </div>
            <div class="modal-footer">
                <slot name="footer"></slot>
            </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'ModalTemplate',
    props: {
        dialogClasses: String
    },
}
</script>

<style lang="scss" scoped>
    .modal .close {
        font-size:2rem;
    }
    .modal-xxl {
        min-width: 90%;
    }

    .modal-xxl .modal-content {
        min-height: 90vh;
    }

</style>
