var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("h1", [_vm._v(_vm._s(_vm.title()))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.purchaseDescription))])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _vm.hasManual
              ? _c("div", { staticClass: "box bg-white" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("writeOff.hasManual")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.reset }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getLocalMsg("writeOff.reset")) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _c("div", { staticClass: "box bg-white" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getLocalMsg("writeOff.info")) +
                      "\n            "
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box bg-white" }, [
          _c("table", { staticClass: "table table-hover" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("writeOff.year")))]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.valueStart")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.writeOff")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.valueEnd")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.professionalShare")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.deductibleShare")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("writeOff.deductible")))
                ]),
                _vm._v(" "),
                _c("th")
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.writeOffs, function(writeOff, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(writeOff.year))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(writeOff.valueStart, 2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _c("input", {
                      staticClass: "form-control text-right",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.writeOff
                      },
                      domProps: { value: _vm.fixAmount(writeOff.writeOff, 2) },
                      on: {
                        change: function($event) {
                          return _vm.update(
                            "writeOff",
                            writeOff,
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(writeOff.valueEnd, 2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _c("input", {
                      staticClass: "form-control text-right",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.professionalShare
                      },
                      domProps: { value: writeOff.professionalShare },
                      on: {
                        change: function($event) {
                          return _vm.update(
                            "professionalShare",
                            writeOff,
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _c("input", {
                      staticClass: "form-control text-right",
                      attrs: {
                        type: "text",
                        autocomplete: _vm.noAutoComplete.deductibleShare
                      },
                      domProps: { value: writeOff.deductibleShare },
                      on: {
                        change: function($event) {
                          return _vm.update(
                            "deductibleShare",
                            writeOff,
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(writeOff.deductible, 2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle text-right" }, [
                    writeOff.manual
                      ? _c("span", { staticClass: "badge badge-info" }, [
                          _vm._v(_vm._s(_vm.getLocalMsg("writeOff.manual")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    writeOff.saleId
                      ? _c("span", { staticClass: "badge badge-success" }, [
                          _vm._v(_vm._s(_vm.getLocalMsg("writeOff.sold")))
                        ])
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary float-right",
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v(_vm._s(_vm.getLocalMsg("global.back")))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box bg-white mt-4" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getLocalMsg("writeOff.fullWriteOff")) +
              "\n        "
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.year,
                  expression: "year"
                }
              ],
              staticClass: "form-control mb-3",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.year = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.years, function(year) {
              return _c("option", { key: year, domProps: { value: year } }, [
                _vm._v("\n                " + _vm._s(year) + "\n            ")
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              on: { click: _vm.fullWriteOff }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getLocalMsg("writeOff.writeOff2")) +
                  "\n        "
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }