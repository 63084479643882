export default {
    namespaced: true,
    state: {
        items: [],
        loaded: false,
    },
    actions: {
        update({ commit, dispatch }, data) {

            const checkUserStatus = dispatch('checkUserStatus', null, { root: true });

            const update = checkUserStatus.then(() => {
                return axios.post('/api/lockedperiod/update', data)
                .then((result) => {
                    commit('update', result.data.data);
                })
                .catch(error => {
                    throw error.response.data;
                });
            }).catch(error => {
                throw error;
            });

            return update
            .catch(error => {
                commit('handleError', error, { root: true });
                throw error;
            }) 

        },
    },
    mutations: {
        update(state, data) {
            state.items = data;
        },
    },
};