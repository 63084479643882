<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 v-if="!propModalStyle" class="d-inline">{{title()}}</h1>
            </div>
        </div>
        <validation-alert 
            v-bind:errors = "validationErrors"
        />
        <div v-once v-if="!propModalStyle" class="row mb-3">
            <div class="col">
                <div class="box bg-white">
                    {{getLocalMsg('relation.info')}}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="box bg-white">
                    <div class="row">
                        <div class="col-2" v-bind:class="colClass">
                            <div class="form-group">
                                <label for="relation-type">{{getLocalMsg('relation.type')}}</label>
                                <select v-bind:disabled="!propEditable" v-model="relationtype" class="form-control" id="relation-type">
                                    <option v-once v-for="(relationtype, index) in relationtypes" v-bind:key="index" v-bind:value = "relationtype">{{relationtype.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="relationtypeCode !== 'NOEU' && relationtypeCode !== 'PAR'" class="col-2" v-bind:class="colClass">
                            <div class="form-group">
                                <label :for="noAutoComplete.vatnumber">{{getLocalMsg('relation.vatNumber')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.vatnumber" class="form-control" :id="noAutoComplete.vatnumber" v-model.lazy="vatnumber">
                               <small v-if="vatNumberValidationText" class="form-text text-muted fg-color-primary">
                                    {{this.vatNumberValidationText}}
                                </small>
                            </div>
                        </div>
                        <div class="col-4" v-bind:class="colClass">
                            <div class="form-group">
                                <label :for="noAutoComplete.name">{{getLocalMsg('relation.name')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.name" class="form-control" :id="noAutoComplete.name" v-model="name">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.street">{{getLocalMsg('relation.street')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.street" class="form-control" :id="noAutoComplete.street" v-model="street">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.postcode">{{getLocalMsg('relation.postcode')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.postcode" class="form-control" :id="noAutoComplete.postcode" v-model="postcode">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.city">{{getLocalMsg('relation.city')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.city" class="form-control" :id="noAutoComplete.city" v-model="city">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.country">{{getLocalMsg('relation.country')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.country" class="form-control" :id="noAutoComplete.country" v-model="country">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.website">{{getLocalMsg('relation.website')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.website" class="form-control" :id="noAutoComplete.website" v-model="website">
                            </div>
                        </div>
                        <div v-if="!propModalStyle" class="col-2">
                            <div class="form-group">
                                <label :for="noAutoComplete.email">{{getLocalMsg('relation.email')}}</label>
                                <input v-bind:disabled="!propEditable" type="text" :autocomplete="noAutoComplete.email" class="form-control" :id="noAutoComplete.email" v-model="email">
                            </div>
                        </div>
                    </div>
                    <a v-if="!propModalStyle" v-on:click="cancel" class="btn btn-secondary float-right">{{getLocalMsg('global.cancel')}}</a>
                    <a v-if="!propModalStyle && propEditable" v-on:click="save" class="btn btn-primary float-right mr-3">{{getLocalMsg('global.save')}}</a>

                </div>
                <div v-if="!propNewItem">
                    <h4 class="mt-4">{{(book==='P' ? getLocalMsg('relation.purchases') : getLocalMsg('relation.sales')) + ' (' + bookings.length + ')'}}</h4>
                    <div class="box bg-white">
                        <booking-items 
                            v-bind:book="book" 
                            v-bind:bookings="bookings"
                            v-bind:details="true"
                            v-bind:detailsRelation="false"
                        ></booking-items>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import BookingItems from '../bookings/BookingItems.vue';
import ValidationAlert from '../../shared/ValidationAlert';


export default {
    name: 'RelationEdit',
    mixins: [sharedMixin],
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    props: {
        propModalStyle: Boolean,
        propClientSupplier: String,
        propNewItem: Boolean,
        propEditable: Boolean
    },
    data () {
        return {
            validationErrors: [],
            vatNumberValidationText: '',
            noAutoComplete: {
                name: this.noAutoCompleteId(),
                street: this.noAutoCompleteId(),
                postcode: this.noAutoCompleteId(),
                vatnumber: this.noAutoCompleteId(),
                city: this.noAutoCompleteId(),
                country: this.noAutoCompleteId(),
                website: this.noAutoCompleteId(),
                email: this.noAutoCompleteId()
            },
        }
    },
    components: {
        BookingItems,
        ValidationAlert
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.relations.loaded ? loaded : false;
            loaded = this.$store.state.relationtypes.loaded ? loaded : false;
            return loaded;
        },
        bookings () {
            return this.$store.state.bookings.items
            .filter(booking=>{
                return booking.relation.id === this.id;
            })
            .sort((a,b)=>{
                return (a.invoiceDate < b.invoiceDate) ? 1 : -1;
            });
        },
        book () {
            return this.clientSupplier === 'C' ? 'S' : 'P';
        },
        colClass () {
            if(this.propModalStyle){
                return {
                    'col-12': true
                }
            }
        },
        id () {
            return this.getItemProperty('relations','id');
        },
        refNumber () {
            return this.getItemProperty('relations','refNumber');
        },
        name: {
            get: function () {
                return this.getItemProperty('relations','name');
            },
            set: function (value) {
                this.setItemProperty('relations','name',value);
            }   
        },
        vatnumber: {
            get: function () {
                return this.getItemProperty('relations','vatnumber');
            },
            set: function (value) {
                this.setRelationVatNumber(value);
            }   
        },
        street: {
            get: function () {
                return this.getItemProperty('relations','street');
            },
            set: function (value) {
                this.setItemProperty('relations','street',value);
            }   
        },
        postcode: {
            get: function () {
                return this.getItemProperty('relations','postcode');
            },
            set: function (value) {
                this.setItemProperty('relations','postcode',value);
            }   
        },
        city: {
            get: function () {
                return this.getItemProperty('relations','city');
            },
            set: function (value) {
                this.setItemProperty('relations','city',value);
            }   
        },
        country: {
            get: function () {
                return this.getItemProperty('relations','country');
            },
            set: function (value) {
                this.setItemProperty('relations','country',value);
            }   
        },
        website: {
            get: function () {
                return this.getItemProperty('relations','website');
            },
            set: function (value) {
                this.setItemProperty('relations','website',value);
            }   
        },
        email: {
            get: function () {
                return this.getItemProperty('relations','email');
            },
            set: function (value) {
                this.setItemProperty('relations','email',value);
            }   
        },
        relationtype: {
            get: function () {
                return this.getItemProperty('relations','relationtype');
            },
            set: function (value) {

                //Reset BTW Verlegd voor NOEU of PAR
                if(value.code==='NOEU' || value.code==='PAR'){
                    this.setItemProperty('relations','vatnumber','');
                }

                this.setItemProperty('relations','relationtype',value);
            }   
        },
        relationtypeCode: function () {
            if(this.relationtype){
                return this.relationtype.code;
            }
            return;
        },
        relationtypes(){
            return this.getItems('relationtypes');
        },
        clientSupplier () {
            return this.getSelectionProperty('relations','clientSupplier');
        },
        redirectUrlPart(){
            return this.clientSupplier === 'C' ? 'clients' : 'suppliers';
        }
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }            
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'relations',
                property: 'clientSupplier',
                value: this.propClientSupplier
            })
            this.$store.dispatch('setActiveItem', {
                moduleName: 'relations',
                newItem: this.propNewItem,
                item: this.propNewItem ? {} : this.getItems('relations').find (item => {
                    return item.id === Number(this.$route.params.id)
                })
            })
            this.$store.commit('showSpinner',false);
        },
        title () {
            let prepend = '';
            let append = '';
            if(this.id){
                if(this.propEditable){
                    prepend = this.getLocalMsg('global.edit') + ' ';
                }
                append = ' ' + this.refNumber + ' - ' + this.name;
            }else {
                prepend = this.getLocalMsg('global.newe') + ' ';
                append = ' ' + this.refNumber;
            }
            if(this.clientSupplier === 'S'){
                return prepend + this.getLocalMsg('relation.supplier') + append;
            }else{
                return prepend + this.getLocalMsg('relation.client') + append;
            }
        },
        validate() {

            let validate = true;
            this.validationErrors = [];

            //Array met alle relaties. GetItems beperkt de relaties reeds tot klanten of leveranciers
            let relations = this.getItems('relations');
            //Verwijderen huidige relatie om te vermijden dat bij bewerken van de relatie onterecht een foutmelding op dubbels wordt weergegeven
            relations = relations.filter(function (item) {
                return item.id !== this.id;
            }.bind(this));

            if (this.emptyObject(this.relationtype)) {
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('relation.type')
                });
                validate = false;
            }
            if (!this.name) {
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('relation.name')
                });
                validate = false;
            }

            const relationNames = relations.map(item => {
                return item.name.toLowerCase();
            })

            if (this.name && relationNames.includes(this.name.toLowerCase())) {
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('relation.name')
                });
                validate = false;
            }

            if (this.relationtypeCode === 'BE' || this.relationtypeCode === 'EU') {
                const relationVatnumbers = relations.map(item => {
                    return item.vatnumber ? item.vatnumber.toLowerCase() : '';
                })

                if (!this.vatnumber) {
                    this.validationErrors.push({
                        type: 'required',
                        field: this.getLocalMsg('relation.vatNumber')
                    });
                    validate = false;
                }
                if (this.vatnumber && relationVatnumbers.includes(this.vatnumber.toLowerCase())) {
                    this.validationErrors.push({
                        type: 'unique',
                        field: this.getLocalMsg('relation.vatNumber')
                    });
                    validate = false;
                }

            }

            return validate;
        },
        save(){
            return this.updateRelation();
        },
        cancel(){
            this.$router.push('/admin/' + this.redirectUrlPart);
        },
        updateRelation() {

            if(!this.validate()){
                return Promise.resolve('NotValidated');
            };

            this.$store.commit('showSpinner', true);
            
            return this.$store.dispatch('updateRelation').then(
                () => {
                    if(!this.propModalStyle){
                        this.$router.push('/admin/' + this.redirectUrlPart);
                    }
                }).catch(error => {
                    this.log(error);
                }).finally(
                    () => this.$store.commit('showSpinner', false)
                )

        },
        resetRelationInfo () {
            this.name= '';
            this.street= '';
            this.city= '';
            this.postcode= '';
            this.country= '';
        },
        setRelationVatNumber(vatNumber) {
            this.setItemProperty('relations','vatnumber',vatNumber);
            vatNumber = this.formatVatNumber(vatNumber);
            this.setItemProperty('relations','vatnumber',vatNumber);
            this.vatNumberValidationText = '';
            this.checkVatNumber(vatNumber).then(result => {
                if(result.data.valid){
                    this.name = result.data.name;
                    this.street = result.data.address.street + ' ' + result.data.address.number;
                    this.city = result.data.address.city;
                    this.postcode = result.data.address.zip_code
                    this.country = result.data.address.country
                } else {
                    this.vatNumberValidationText = this.getLocalMsg('global.vatNumberNotFound');
                    this.resetRelationInfo();
                }
            });
        },

    },
}

</script>

<style lang="scss">
    
</style>
