<template>
    <div v-if="!transaction.booking" class="input-group input-group-sm">
        <select v-model="booking" class="custom-select">
                <option v-for="(booking, index) in bookings" v-bind:key="index" v-bind:value="booking">{{bookingSelectString(booking)}}</option>
        </select>
        <div class="input-group-append">
            <button v-on:click="update" class="btn btn-primary" type="button"><i class="fas fa-check"></i></button>
        </div>
    </div>
    <div v-else>
        {{bookingSelectString(transaction.booking)}}
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';

export default {
    name: 'TransactionSelectBooking',
    mixins: [sharedMixin],
    props: {
        propTransaction: Object
    },
    data () {
        return {
            booking: null 
        }
    },
    computed: {
        transaction () {
            return this.propTransaction
        },
        bookings(){
            return this.$store.state.bookings.items
            .filter(booking => {
                const transactionAmount = booking.transactions.reduce((a,b) => {
                    return a + b.amount;
                },0)
                if(transactionAmount < booking.invoiceAmountInput && booking.book == this.transaction.transactionType.code){
                    return true;
                };
                return false;
            })
            .sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))
            .sort((a, b) => (a.invoiceDate > b.invoiceDate) ? -1 : ((b.invoiceDate > a.invoiceDate) ? 1 : 0));
        },
        // bookings(){
        //     return this.$store.state.bookings.items
        //     .filter(booking => {
        //         if(booking.book == this.transaction.transactionType.code){
        //             return true;
        //         };
        //         return false;
        //     })
        //     .sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))
        //     .sort((a, b) => (a.invoiceDate > b.invoiceDate) ? -1 : ((b.invoiceDate > a.invoiceDate) ? 1 : 0));
        // },
        
    },
    methods: {
        
        update () {

            if(!this.booking){
                return;
            }

            this.$store.commit('showSpinner', true);

            this.transaction.booking = this.booking;
            this.transaction.isChanged = true;
            this.transaction.reference = this.transaction.transactionType.name + ' ' + this.booking.refNumber;

            const setActiveItem = () => {
                return this.$store.dispatch('setActiveItem', {
                    moduleName: 'transactions',
                    newItem: false,
                    item: this.transaction
                })
            }

            const updateTransaction = setActiveItem().then(() => {
                return this.$store.dispatch('updateTransaction')
            })

            return updateTransaction.catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )

        },
        
        
        bookingSelectString(booking){

            const invoiceDate = moment(booking.invoiceDate,'YYYY-MM-DD').format('DD/MM/YYYY');

            let result = '';
            result += invoiceDate + ' - '; 
            result += (booking.book == 'S' ? this.getLocalMsg('transaction.sale') : this.getLocalMsg('transaction.purchase')) + ' ';
            result += booking.refNumber + ' - ';
            result += booking.relation.name + ' - ';
            result += this.fixAmount(booking.invoiceAmountInput,2);
            result += booking.reference ? ' - Ref ' + booking.reference : '';
            result += booking.description ?  ' - ' + booking.description : '';
            return result;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
