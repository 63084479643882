export default {

    /**
     * Lijst van items opvragen
     * Voor relaties: filter op klanten of leveranciers
     * Voor rekeningen: filter op alle rekeningen behalve de omzetrekeningen, die worden automatisch toegekend bij verkopen
     * Voor boekingen: filter op boek, jaar en periode
     * Voor categorieen: indien relaties gefilterd op klanten (verkopen), dan verkoopscategorieen, anders aankoopcategorieen
     */
    getItems: (state) => ({moduleName}) => {
        switch (moduleName) {
            case 'relations':
                return state[moduleName].items.filter((item) => {
                    return item.clientSupplier === state[moduleName].selection.clientSupplier;
                });
            //Alle rekeningen weergeven behalve Omzet. Deze rekening wordt default toegekend bij verkopen.
            case 'accounts':
            return state[moduleName].items.filter((item) => {
                return item.type !== 'R';
            });
            case 'bookings':
                return state[moduleName].items.filter(item => {
                    return (
                        item.year === state[moduleName].selection.year &&
                        item.period === state[moduleName].selection.period &&
                        item.book === state[moduleName].selection.book
                    )
                });
            case 'categories':
                return state.categories.items.filter((item) => {
                    return item.type === state.categories.selection.type;
                });
            case 'stockchanges':
                return state.stockchanges.items.filter((item) => {
                    return state.years.items.indexOf(item.year) !== -1;
                });
            case 'transactions':
                return state.transactions.items.filter((item) => {
                    return item.transactionAccount.id == state.transactions.selection.transactionAccount.id;
                });
            default:
                return state[moduleName].items;
        }

        
    },
    /**
     * Opvragen van property huidige selectie van de state
     */
    getSelectionProperty: (state) => ({moduleName,property}) => {
        const selection = state[moduleName].selection;
        return selection[property];
    },
    /**
     * Opvragen van property van het actieve item
     */
    getItemProperty: (state) => ({moduleName, property}) => {
        const item = state[moduleName].activeItem;
        return item[property];
    },
    /**
     * Getter om nieuw referentienr te bepalen
     * Dit nummer is uniek en oplopend en wordt apart geteld voor aankopen, verkopen, relaties en klanten
     * 
     */
    getNewRefNumber: (state, getters) => (moduleName) => {

        const maxRefNumber = getters['getMaxRefNumber'](moduleName);
        return maxRefNumber + 1;

    },
    /**
     * Getter om hoogste referentienr te bepalen
     * Dit nummer is uniek en oplopend en wordt apart geteld voor aankopen, verkopen, relaties en klanten
     * 
     */
    getMaxRefNumber: (state) => (moduleName) => {

        let items = [];

        if (moduleName === 'bookings') {
            items = state.bookings.items.filter(item => {
                return (
                    item.book === state.bookings.selection.book &&
                    item.year === state.bookings.selection.year
                )
            });
        }
        if (moduleName === 'relations') {
            items = state.relations.items.filter(item => {
                return (
                    item.clientSupplier === state.relations.selection.clientSupplier
                )
            });
        }

        let initialValue = 0;

        const max = items.reduce((accumulator, currentValue) => {
            return Math.max(accumulator, currentValue.refNumber);
        }, initialValue);

        return max;

    },

};