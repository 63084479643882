<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('category.overviewCategories')}}</h1>
                <a v-on:click.prevent="newCategory()" class="btn btn-primary float-right">{{getLocalMsg('global.new')}}</a>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{getLocalMsg('category.name')}}</th>
                        <th class="text-right">{{getLocalMsg('category.defaultDeductibleVatShare')}}</th>
                        <th class="text-right">{{getLocalMsg('category.defaultDeductibleCostsShare')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(category, index) in categories" v-bind:key="index">
                        <td class="align-middle">{{category.name}}</td>
                        <td class="align-middle text-right">{{category.defaultDeductibleVatShare}}</td>
                        <td class="align-middle text-right">{{category.defaultDeductibleCostsShare}}</td>
                        <td class="align-middle text-right">
                            <dropdown-menu
                                v-on:view="viewCategory(category.id)"
                                v-on:edit="editCategory(category.id)"
                                v-on:delete="showDeleteModal(category)"
                            />
                        </td>
                        

                    </tr>
                </tbody>
            </table>
            
        </div>
        <modal-template ref="modalDelete">
            <template v-slot:header>
                <h5>{{getLocalMsg('category.delete.header')}}</h5>
            </template>
            <template v-slot:body>
                <p>{{getLocalMsg('category.delete.body')}}</p>
            </template>
            <template v-slot:footer>
                <button type="button" v-on:click="deleteCategory" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('global.delete')}}</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue'
import DropdownMenu from '../../shared/DropdownMenu.vue'

export default {
    name: 'CategoryList',
    mixins: [sharedMixin],
    props: {
        propType: String
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
            categoryIdToDelete: null        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.categories.loaded ? loaded : false;
            return loaded;
        },
        categories () {
            return this.getItems('categories');
        },
        type () {
            return this.getSelectionProperty('categories','type');
        },
    },
    components: {
        ModalTemplate,
        DropdownMenu
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.dispatch('setSelectionProperty', {
                moduleName: 'categories',
                property: 'type',
                value: this.propType
            })
            this.$store.commit('showSpinner',false);
        },
        showDeleteModal(category) {
            this.categoryIdToDelete = category.id;
            this.showModal('modalDelete');
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        newCategory() {
            if(this.type === 'P'){
                this.$router.push('/admin/purchases/category/new')
            }else{
                this.$router.push('/admin/sales/category/new')
            }
        },
        editCategory(id) {
            if(this.type === 'P'){
                this.$router.push('/admin/purchases/category/' + id + '/edit')
            }else{
                this.$router.push('/admin/sales/category/' + id + '/edit')
            }
        },
        deleteCategory(){

            this.deleteItem('categories',this.categoryIdToDelete)
            .catch(error => {
                this.log(error);
            })

        },
        viewCategory(id) {
            if(this.type === 'P'){
                this.$router.push('/admin/purchases/category/' + id + '/view')
            }else{
                this.$router.push('/admin/sales/category/' + id + '/view')
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
