import { render, staticRenderFns } from "./WriteOffEdit.vue?vue&type=template&id=563dcfe4&scoped=true&"
import script from "./WriteOffEdit.vue?vue&type=script&lang=js&"
export * from "./WriteOffEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563dcfe4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\User\\Documents\\webdev\\bookit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('563dcfe4')) {
      api.createRecord('563dcfe4', component.options)
    } else {
      api.reload('563dcfe4', component.options)
    }
    module.hot.accept("./WriteOffEdit.vue?vue&type=template&id=563dcfe4&scoped=true&", function () {
      api.rerender('563dcfe4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/admin/writeoffs/WriteOffEdit.vue"
export default component.exports