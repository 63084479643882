var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.getLocalMsg("incomeStatement.title")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "years" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.years")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedYear,
                        expression: "selectedYear"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "years" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedYear = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._m(0),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "periodicity" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.periods")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.periodicity,
                        expression: "periodicity"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "periodicity" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.periodicity = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "quarterly" } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("global.quarterly")))
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "monthly" } }, [
                      _vm._v(_vm._s(_vm.getLocalMsg("global.monthly")))
                    ])
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _vm._v(" "),
                  _vm.periodicity === "quarterly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.Q1")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "quarterly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.Q2")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "quarterly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.Q3")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "quarterly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.Q4")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M01")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M02")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M03")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M04")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M05")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M06")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M07")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M08")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M09")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M10")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M11")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.periodicity === "monthly"
                    ? _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("global.M12")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.selectedYear))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.sales.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showModalDetail("rowA1")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLocalMsg("incomeStatement.rowA1")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2692216143
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.retailCosts.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowB1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3804364642
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.retailGoods.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showModalDetail("rowB2")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLocalMsg("incomeStatement.rowB2")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3461317135
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.stockChange.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.getLocalMsg("incomeStatement.rowB3", {
                                    yearMin1: _vm.selectedYear - 1,
                                    year: _vm.selectedYear
                                  })
                                )
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      641305537
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.grossProfit.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowC1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2950060227
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.grossMargin.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowC2"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3454260352
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.otherGoods.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowD1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2022446884
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.otherGoodsByCategory, function(value, key, index) {
                    return _c("income-row", {
                      key: "otherGoods" + index,
                      attrs: {
                        periodicity: _vm.periodicity,
                        classes: ["row-indent"],
                        value: value
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showModalDetail("rowD2", key)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(key))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.operatingProfit.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowE1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3842616965
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.operatingMargin.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowE2"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2865743558
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.writeOff.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowF1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2608333542
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold"],
                      value: _vm.taxableProfit.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowG1"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1754029127
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.netMargin.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowG2"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2258229252
                    )
                  }),
                  _vm._v(" "),
                  _c("tr", { staticClass: "row-bold" }, [
                    _c("td", [
                      _vm._v(_vm._s(_vm.getLocalMsg("incomeStatement.rowH1")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.nonDeductibleCosts.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showModalDetail("rowH2")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLocalMsg("incomeStatement.rowH2")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2038648271
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.privatePart.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showModalDetail("rowH3")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLocalMsg("incomeStatement.rowH3")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      649040623
                    )
                  }),
                  _vm._v(" "),
                  _c("tr", { staticClass: "row-bold" }, [
                    _c("td", [
                      _vm._v(_vm._s(_vm.getLocalMsg("incomeStatement.rowI1")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.nonDeductibleWriteOff.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowI2"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1221051978
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.privatePartWriteOff.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowI3"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2486503435
                    )
                  }),
                  _vm._v(" "),
                  _c("tr", { staticClass: "row-bold" }, [
                    _c("td", [
                      _vm._v(_vm._s(_vm.getLocalMsg("incomeStatement.rowJ1")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.addedValueInvestmentSales.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowJ2"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1820029545
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-indent"],
                      value: _vm.notWrittenOffInvestments.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowJ3"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      571882216
                    )
                  }),
                  _vm._v(" "),
                  _c("income-row", {
                    attrs: {
                      periodicity: _vm.periodicity,
                      classes: ["row-bold", "row-indent"],
                      value: _vm.addedValueInvestments.Total
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("incomeStatement.rowJ4"))
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2287185647
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalDetail",
            attrs: { dialogClasses: "modal-xxl modal-dialog-scrollable" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("incomeStatement.detail"))
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("table", { staticClass: "table table-hover" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.bookingId"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.getLocalMsg("global.period")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.invoiceDate"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.relation"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.reference"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.description"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(_vm.getLocalMsg("booking.amount")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.selectedBookings, function(booking) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: booking.id,
                                    staticClass: "header-row"
                                  },
                                  [
                                    _c("td", { staticClass: "align-middle" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showModalBooking(
                                                booking.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              booking.book === "P"
                                                ? _vm.getLocalMsg(
                                                    "booking.purchase"
                                                  )
                                                : _vm.getLocalMsg(
                                                    "booking.sale"
                                                  )
                                            ) +
                                              " " +
                                              _vm._s(booking.refNumber)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getLocalMsg(
                                            "global." + booking.period
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(booking.invoiceDate)
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.relation.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.reference))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.description))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "align-middle text-right"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fixAmount(
                                              booking.amountIncomeStatement,
                                              2
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.close")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1608732473
            )
          }),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalBooking",
            attrs: { dialogClasses: "modal-xxl modal-dialog-scrollable" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg("vatDeclaration.detailBooking")
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _vm.selectedBooking.length > 0
                        ? _c("booking-items", {
                            attrs: {
                              book: _vm.selectedBooking[0].book,
                              bookings: _vm.selectedBooking,
                              details: true,
                              detailsRelation: true
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.close")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              923284831
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.years, function(year, index) {
      return _c("option", { key: index, domProps: { value: year } }, [
        _vm._v(_vm._s(year))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }