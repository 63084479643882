import Vue from 'vue';
import Vuex from 'vuex';

import periodsModule from './modules/periods';
import yearsModule from './modules/years';
import accountsModule from './modules/accounts';
import relationsModule from './modules/relations';
import relationtypesModule from './modules/relationtypes';
import stockchangesModule from './modules/stockchanges';
import vattypesModule from './modules/vattypes';
import writeoffsModule from './modules/writeoffs';
import bookingsModule from './modules/bookings/index.js';
import lockedperiodsModule from './modules/lockedperiods';
import categoriesModule from './modules/categories';
import transactionsModule from './modules/transactions';
import transactionAccountsModule from './modules/transactionaccounts';
import transactionTypesModule from './modules/transactiontypes';
import companiesModule from './modules/companies';
import localizationModule from './modules/localization';
import errorsModule from './modules/errors';
import userModule from './modules/user';


import actions from './actions';
import getters from './getters';
import mutations from './mutations';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      showSpinner: false
      
  },
  modules: {
    periods: periodsModule,
    years: yearsModule,
    accounts: accountsModule,
    relations: relationsModule,
    relationtypes: relationtypesModule,
    stockchanges: stockchangesModule,
    vattypes: vattypesModule,
    writeoffs: writeoffsModule,
    bookings: bookingsModule,
    lockedperiods: lockedperiodsModule,
    categories: categoriesModule,
    transactions: transactionsModule,
    transactionAccounts: transactionAccountsModule,
    transactionTypes: transactionTypesModule,
    companies: companiesModule,
    localization: localizationModule,
    errors: errorsModule,
    user: userModule
  },
  actions,
  getters,
  mutations,
  strict: false
});