<template>
    <div v-if="errors.length>0" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">{{getLocalMsg('validation.header')}}</h4>
        <ul>   
            <li v-for="(error,index) in errors" v-bind:key="index">{{errorMessage(error)}}</li>
        </ul>
    </div>
</template>

<script>

import sharedMixin from '../mixins/shared-mixin';

export default {
    name: 'ValidationAlert',
    mixins: [sharedMixin],
    props: {
        header: String,
        errors: Array
    },
    watch: {
        errors: function () {
            this.scrollToTop();
        }
    },
    computed: {
        // errors () {
        //     return this.$store.state.validation.errors;
        // }
    },
    methods: {
        errorMessage(error) {

            switch(error.type) {
            case 'between':
                return this.getLocalMsg('validation.between', {field: error.field, min: error.min, max: error.max});
                break;
            case 'digits':
                return this.getLocalMsg('validation.digits', {field: error.field, quantity: error.quantity});
                break;
            case 'maxLength':
                return this.getLocalMsg('validation.maxLength', {field: error.field, max: error.max});
                break;
            case 'min':
                return this.getLocalMsg('validation.min', {field: error.field, min: error.min});
                break;
            case 'custom':
                return error.message;
                break;
            default:
                return this.getLocalMsg('validation.' + error.type, {field: error.field});
            }
        },
        scrollToTop(){
            const element = document.getElementById("topDiv");
            const top = element.offsetTop;
            window.scrollTo(0, top);
        }
    }
}

</script>

<style lang="scss" scoped>

</style>
