<template>
    <div v-if="loaded">
        <div class="row mb-3">
            <div class="col">
                <h1 class="d-inline">{{getLocalMsg('lockedperiod.overview')}}</h1>
            </div>
        </div>
        <div v-once class="row mb-3">
            <div class="col">
                <div class="box bg-white">
                    {{getLocalMsg('lockedperiod.info')}}
                </div>
            </div>
        </div>
        <div class="box bg-white">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{getLocalMsg('lockedperiod.period')}}</th>
                        <th class="text-right">{{getLocalMsg('lockedperiod.locked')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="period in periods" v-bind:key="period">
                        <td class="align-middle">{{period}}</td>
                        <td class="align-middle text-right">
                            <toggle-switch 
                                v-bind:propRounded="true" 
                                v-bind:propChecked="isLocked(period)"
                                v-on:toggle="update(period,$event)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ToggleSwitch from '../../shared/ToggleSwitch.vue';

export default {
    name: 'LockedperiodList',
    mixins: [sharedMixin],
    components: {
        ToggleSwitch
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
        return {
        };
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.lockedperiods.loaded ? loaded : false;
            loaded = this.$store.state.periods.loaded ? loaded : false;
            loaded = this.$store.state.years.loaded ? loaded : false;
            return loaded;
        },
        lockedperiods () {
            return this.getItems('lockedperiods');
        },
        periods () {
            const periods = this.getItems('periods');
            const years = this.getItems('years');
            let result = [];
            for (let i=0; i<years.length; i++) {
                for (let j=0; j<periods.length; j++) {
                    result.push(years[i] + '_' + periods[j]);
                }    
            }
            result.sort();
            result.reverse();
            return result;
        },
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        isLocked (period) {
            return this.lockedperiods.includes(period);
        },
        // onToggle (period,checked) {
        //     if(checked){
        //         if(!this.lockedperiods.includes(period)) {
        //             this.lockedperiods.push(period);
        //         }
        //     }else{
        //         const index = this.lockedperiods.indexOf(period);
        //         if (index > -1) {
        //         this.lockedperiods.splice(index, 1);
        //         }
        //     }
        // },
        update (period,checked) {
            this.$store.commit('showSpinner', true);
            this.$store.dispatch('lockedperiods/update', {
                period: period,
                checked: checked
            }).catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )
        },
    }
}
</script>

<style>

</style>
