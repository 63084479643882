<template>
    <div v-if="loaded">
        <div>
            <validation-alert 
                v-bind:errors = "validationErrors"
            />
            <div class="row">
                <div class="col-3" />
                <div class="col-6">
                    <div class="box bg-white mb-4">
                        {{getLocalMsg('companies.info')}}
                    </div>
                    <div class="box bg-white">
                        <div class="form-group">
                            <label :for="noAutoComplete.companyStartDate">{{getLocalMsg('companies.startDate')}}</label>
                            <!-- https://www.npmjs.com/package/vue-bootstrap-datetimepicker -->
                            <date-picker :autocomplete="noAutoComplete.companyStartDate" :id="noAutoComplete.companyStartDate" v-model.lazy="companyStartDate" :config="dateTimePickerOptions"></date-picker>
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.companyVatNumber">{{getLocalMsg('companies.vatNumber')}} ({{getLocalMsg('global.notRequired')}})</label>
                            <input type="text" :autocomplete="noAutoComplete.companyVatNumber" class="form-control" :id="noAutoComplete.companyVatNumber" v-bind:placeholder="getLocalMsg('companies.vatNumberPlaceHolder',{siteName:getLocalMsg('global.siteName')})" v-model.lazy="companyVatNumber">
                            <small v-if="vatNumberValidationText" class="form-text text-muted fg-color-primary">
                                {{this.vatNumberValidationText}}
                            </small>
                        </div>
                        <div class="form-group">
                            <label for="company-type">{{getLocalMsg('companies.type')}}</label>
                            <select v-model="companyType" class="form-control" id="company-type">
                                <option v-once v-for="(companyType, index) in companyTypes" v-bind:key="index" v-bind:value = "companyType">{{companyType.name}}</option>
                            </select>
                        </div>
                        <div class="form-check mt-1 mb-4">
                            <input type="checkbox" tabindex="-1" class="form-check-input" id="company-vat-exempt" v-model="companyVatExempt">
                            <label class="form-check-label" for="company-vat-exempt">
                                <span>{{getLocalMsg('companies.vatExempt')}}</span>
                            </label>
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.companyName">{{getLocalMsg('companies.name')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.companyName" class="form-control" :id="noAutoComplete.companyName" v-model.lazy="companyName">
                        </div>
                        <div class="form-group">
                            <label :for="noAutoComplete.companyName">{{getLocalMsg('companies.name')}}</label>
                            <input type="text" :autocomplete="noAutoComplete.companyName" class="form-control" :id="noAutoComplete.companyName" v-model.lazy="companyName">
                        </div>
                        <div class="float-right">
                            <button v-on:click="validateAndUpdateItem" tabindex="0" class="btn btn-primary">{{getLocalMsg('companies.register')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import sharedMixin from '../../mixins/shared-mixin';
import ValidationAlert from '../../shared/ValidationAlert';

export default {
    name: 'RegisterCompany',
    mixins: [sharedMixin],
    components: {
        datePicker,
        ValidationAlert
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded)
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        },
        companyVatNumber: function (vatNumber) {
            this.setCompanyVatNumber(vatNumber);
        }
    },
    data () {
        return {
            validationErrors: [],
            companyName: '',
            companyStreet: '',
            companyCity: '',
            companyPostcode: '',
            companyCountryCode: '',
            companyVatNumber: '',
            companyVatExempt: '',
            companyStartDate: null,
            companyTypes: this.getLocalMsg('companies.types'),
            companyType: {},
            noAutoComplete: {
                companyName: this.noAutoCompleteId(),
                companyVatNumber: this.noAutoCompleteId(),
                companyStartDate: this.noAutoCompleteId()
            },
            vatNumberValidationText: ''
        }
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.user.loaded ? loaded : false;
            return loaded;
        },
        companyStartDateYYYYMMDD () {
            const date = moment(this.companyStartDate,this.dateTimePickerOptions.format);
            return date.format('YYYY-MM-DD');
        },
        companies() {
            return this.$store.state.user.companies;
        }
    },
    methods: {
        initiate (loaded) {

            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        setCompanyVatNumber(vatNumber) {
            vatNumber = this.formatVatNumber(vatNumber);
            this.vatNumberValidationText = '';
            this.checkVatNumber('BE' + vatNumber).then(result => {
                if(result.data.valid){
                    this.companyName = result.data.name;
                    this.companyStreet = result.data.address.street + ' ' + result.data.address.number;
                    this.companyCity = result.data.address.city;
                    this.companyPostcode = result.data.address.zip_code
                    this.companyCountryCode = result.data.countryCode
                } else {
                    this.vatNumberValidationText = this.getLocalMsg('global.vatNumberNotFound');
                    this.resetCompanyInfo();
                }
                this.companyVatNumber = vatNumber;
            });
        },
        resetCompanyInfo () {
            this.companyName= '';
            this.companyStreet= '';
            this.companyCity= '';
            this.companyPostcode= '';
            this.companyCountryCode= '';
        },
        validate() {

            let validate = true;
            this.validationErrors = [];


            if(this.companyVatNumber){
                if(!this.isInteger(this.companyVatNumber) || this.companyVatNumber.length !=10){
                    this.validationErrors.push({type: 'digits', quantity: '10', field: this.getLocalMsg('companies.vatNumber')});
                    validate=false;
                }
            }

            const companyVatNumbers = this.companies.map(item => {
                
                if(item.vatNumber){
                    return item.vatnumber;
                }
            })

            if (this.companyVatNumber && companyVatNumbers.includes('BE' + this.companyVatNumber.toUpperCase())) {
                console.log(this.companyVatNumber, companyVatNumbers);
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('companies.vatNumber')
                });
                validate = false;
            }


            if(!this.companyName){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.name')});
                validate=false;
            }

            const companyNames = this.companies.map(item => {
                return item.name.toLowerCase();
            })

            if (this.companyName && companyNames.includes(this.companyName.toLowerCase())) {
                this.validationErrors.push({
                    type: 'unique',
                    field: this.getLocalMsg('companies.name')
                });
                validate = false;
            }

            if (this.companyName.length > 40) {
                this.validationErrors.push({
                    type: 'maxLength',
                    max:40,
                    field: this.getLocalMsg('companies.name')
                });
                validate = false;
            }

            if(!this.companyStartDate){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.startDate')});
                validate=false;
            }

            if(this.emptyObject(this.companyType)){
                this.validationErrors.push({type: 'required', field: this.getLocalMsg('companies.type')});
                validate=false;
            }

            return validate;

        },

        validateAndUpdateItem(){

            if(!this.validate()){
                return;
            };


            return this.updateItem();

        },
        
        updateItem() {

            this.$store.commit('showSpinner', true);

            if(this.companyType.id != 1){
                this.validationErrors.push({type: 'custom', message: this.getLocalMsg('companies.unsuitable',{siteName: this.getLocalMsg('global.siteName')})});
            }
            
            const data = {
                name: this.companyName,
                street: this.companyStreet,
                city: this.companyCity,
                postcode: this.companyPostcode,
                countryCode: this.companyCountryCode ? this.companyCountryCode : 'BE',
                vatNumber: this.companyVatNumber,
                vatExempt: this.companyVatExempt ? true : false,
                startDate: this.companyStartDateYYYYMMDD,
                companyType: this.companyType
            }

            return axios.post('/api/company/create', data)
            .then(() => {
                if(this.companyType.id === 1){
                    this.$store.commit('setLoadedState', {
                        moduleName: 'user',
                        loaded: false
                    });
                    return this.$store.dispatch('fetchUser').then(()=>{
                        this.$router.push('/admin');
                    });

                }
                return Promise.resolve();
            })
            .catch(error => {
                this.$store.commit('handleError', error.response.data);
                throw error.response.data;
            })
            .finally(() => 
                this.$store.commit('showSpinner', false)
            );

        },
    }
}

</script>

<style lang="scss" scoped>

   

</style>
