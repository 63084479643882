var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
    _c("div", { staticClass: "modal-dialog", class: this.dialogClasses }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }