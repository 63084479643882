<template>
    <div>
        <!-- <div class="box bg-white"> -->
            <validation-alert 
                v-bind:errors = "validationErrors"
            />
            <table class="table">
                <thead>
                    <tr>
                        <th>{{book === 'P' ? getLocalMsg('booking.purchaseId') : getLocalMsg('booking.saleId')}}</th>
                        <th>{{getLocalMsg('global.year')}}</th>
                        <th>{{getLocalMsg('global.period')}}</th>
                        <th>{{getLocalMsg('booking.invoiceDate')}}</th>
                        <th>{{book === 'P' ? getLocalMsg('booking.supplier') : getLocalMsg('booking.client')}}</th>
                        <th>{{getLocalMsg('booking.reference')}}</th>
                        <th>{{getLocalMsg('booking.description')}}</th>
                        <th class="text-right">{{getLocalMsg('booking.amountExclVat')}}</th>
                        <th class="text-right">{{getLocalMsg('booking.amountVat')}}</th>
                        <th class="text-right">{{getLocalMsg('booking.amountInclVat')}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="booking in bookings">
                        <tr class = "header-row" v-bind:key="'header-' + booking.id">
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}" v-popover="popoverTechInfo(booking)">{{booking.refNumber}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{booking.year}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{getLocalMsg('global.' + booking.period)}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{formatDate(booking.invoiceDate)}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{booking.relation.name}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{booking.reference}}</td>
                            <td class="align-middle" v-bind:class="{'highlight-header-row': details}">{{booking.description}}</td>
                            <td class="align-middle text-right" v-bind:class="{'highlight-header-row': details}">{{fixAmount(booking.invoiceAmountExclVat,2)}}</td>
                            <td class="align-middle text-right" v-bind:class="{'highlight-header-row': details, 'not-applicable': !vatApplicable(booking).value}">{{fixAmount(booking.invoiceAmountVat,2)}}</td>
                            <td class="align-middle text-right" v-bind:class="{'highlight-header-row': details, 'not-applicable': !vatApplicable(booking).value}">{{fixAmount(booking.invoiceAmountInclVat,2)}}</td>
                            <td v-if="!lockedPeriod(booking.year, booking.period)" class="align-middle text-right" v-bind:class="{'highlight-header-row': details}">
                                <dropdown-menu
                                    v-bind:propDarkMode="true"
                                    v-on:edit="editBooking(booking.id)"
                                    v-on:delete="showDeleteModal(booking)"
                                />
                            </td>
                            <td v-if="lockedPeriod(booking.year, booking.period)" class="align-middle text-right" v-bind:class="{'highlight-header-row': details}">
                                <i class="fas fa-lock"></i>
                            </td>
                        </tr>
                        <tr v-if="details" v-bind:key="'extra-info-' + booking.id">
                            <td colspan="11">
                                <div v-bind:id="'extra-info-accordion-' + booking.id">
                                    <div class="card">
                                        <div class="card-header collapsed" data-toggle="collapse" v-bind:data-target="'#collapse-four-' + booking.id" id="heading-four">
                                            <p class="mb-0">
                                                <span v-if="booking.transactions.length > 0">
                                                    <i class="fa fa-chevron-down mr-2"></i>
                                                </span>
                                                <span class="mr-4" v-else></span>
                                                <span class="mr-4">{{getLocalMsg('booking.payments')}}</span>
                                                <button v-if="booking.invoiceAmountInput > transactionsTotal(booking)" class="float-right btn btn-sm btn-primary" v-on:click="showTransactionModal(booking)">{{getLocalMsg('booking.addPayment')}}</button>
                                                <span v-if="booking.invoiceAmountInput > transactionsTotal(booking)" class="badge badge-danger">{{getLocalMsg('booking.notPaid')}}</span>
                                                <span v-if="booking.invoiceAmountInput == transactionsTotal(booking)" class="badge badge-success">{{getLocalMsg('booking.paid')}}</span>
                                                <span v-if="booking.invoiceAmountInput < transactionsTotal(booking)" class="badge badge-warning">{{getLocalMsg('booking.overPaid')}}</span>
                                            </p>
                                        </div>
                                        <div v-if="booking.transactions.length > 0" v-bind:id="'collapse-four-' + booking.id" class="collapse" v-bind:data-parent="'#extra-info-accordion-' + booking.id">
                                            <div class="card-body">
                                                <div class = "pl-4">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr>
                                                                <th>{{getLocalMsg('transaction.reference')}}</th>
                                                                <th>{{getLocalMsg('transaction.transactionDate')}}</th>
                                                                <th>{{getLocalMsg('transaction.transactionAccount')}}</th>
                                                                <th>{{getLocalMsg('transaction.description')}}</th>
                                                                <th class="text-right">{{getLocalMsg('transaction.amount')}}</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="transaction in booking.transactions" v-bind:key="transaction.id">
                                                                <td>{{transaction.reference}}</td>
                                                                <td>{{formatDate(transaction.transactionDate)}}</td>
                                                                <td>{{transaction.transactionAccount.name}}</td>
                                                                <td>{{transaction.description}}</td>
                                                                <td class="text-right">{{fixAmount(transaction.amount,2)}}</td>
                                                                <td><button class="mt-n1 float-right btn btn-sm" v-on:click="deleteTransaction(transaction)"><i class="far fa-trash-alt"></i></button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="detailsBooking" class="card">
                                        <div class="card-header collapsed" data-toggle="collapse" v-bind:data-target="'#collapse-one-' + booking.id" id="heading-one">
                                            <p class="mb-0">
                                                <i class="fa fa-chevron-down mr-2"></i>
                                                {{getLocalMsg('booking.details')}} {{book === 'P' ? getLocalMsg('booking.purchase') : getLocalMsg('booking.sale')}}
                                            </p>
                                        </div>
                                        <div v-bind:id="'collapse-one-' + booking.id" class="collapse" v-bind:data-parent="'#extra-info-accordion-' + booking.id">
                                            <div class="card-body">
                                                <div class = "pl-4">
                                                    <p>
                                                        <span v-if="book === 'P'"><strong>{{getLocalMsg('booking.professionalShare')}}:</strong> {{fixAmount(booking.professionalShare,0)}} %</span>
                                                        <span v-if="booking.noVatDeclaration"><br><strong>{{getLocalMsg('booking.noVatDeclaration')}}</strong></span>
                                                        <span v-html="vatApplicable(booking).text"></span>
                                                    </p>
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr>
                                                                <th v-if="book === 'P'">{{getLocalMsg('booking.account')}}</th>
                                                                <th>{{getLocalMsg('booking.category')}}</th>
                                                                <th>{{getLocalMsg('booking.vattype')}}</th>
                                                                <th class="text-right">{{getLocalMsg('booking.amountExclVat')}}</th>
                                                                <th class="text-right">{{getLocalMsg('booking.amountVat')}}</th>
                                                                <th class="text-right">{{getLocalMsg('booking.amountInclVat')}}</th>
                                                                <th v-if="book === 'P'" class="text-right">{{getLocalMsg('booking.deductibleVat')}}</th>
                                                                <th v-if="book === 'P'" class="text-right"></th>
                                                                <th v-if="book === 'P'" class="text-right">{{getLocalMsg('booking.deductibleCosts')}}</th>
                                                                <th v-if="book === 'P'" class="text-right"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="bookline in booking.booklines" v-bind:key="bookline.id">
                                                                <td v-if="book === 'P'">
                                                                    {{bookline.account.name}}
                                                                    <div v-if="bookline.account.type==='I'">
                                                                        <button type="button" v-on:click="editWriteOff(bookline.id)" class="btn btn-link">{{getLocalMsg('booking.term')}}: {{bookline.term}}</button>
                                                                    </div>
                                                                </td>
                                                                <td>{{bookline.category ? bookline.category.name : ''}}</td>
                                                                <td>{{bookline.vattype.name}}</td>
                                                                <td class="text-right">{{fixAmount(bookline.amountExclVat,2)}}</td>
                                                                <td class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(bookline.amountVat,2)}}</td>
                                                                <td class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(bookline.amountInclVat,2)}}</td>
                                                                <td v-if="book === 'P'" class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(bookline.deductibleVat,2)}}</td>
                                                                <td v-if="book === 'P'" class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(bookline.deductibleVatShare,0)}} %</td>
                                                                <td v-if="book === 'P'" class="text-right">{{fixAmount(bookline.deductibleCosts,2)}}</td>
                                                                <td v-if="book === 'P'" class="text-right">{{fixAmount(bookline.deductibleCostsShare,0)}} %</td>

                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th>{{getLocalMsg('global.total')}}</th>
                                                                <th v-if="book === 'P'"></th>
                                                                <th></th>
                                                                <th class="text-right">{{fixAmount(booking.amountExclVat,2)}}</th>
                                                                <th class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(booking.amountVat,2)}}</th>
                                                                <th class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(booking.amountInclVat,2)}}</th>
                                                                <th v-if="book === 'P'" class="text-right" v-bind:class="{'not-applicable': !vatApplicable(booking).value}">{{fixAmount(booking.deductibleVat,2)}}</th>
                                                                <th v-if="book === 'P'" class="text-right"></th>
                                                                <th v-if="book === 'P'" class="text-right">{{fixAmount(booking.deductibleCosts,2)}}</th>
                                                                <th v-if="book === 'P'" class="text-right"></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="detailsRelation" class="card">
                                        <div class="card-header collapsed" data-toggle="collapse" v-bind:data-target="'#collapse-two-' + booking.id" id="heading-two">
                                            <p class="mb-0">
                                                <i class="fa fa-chevron-down mr-2"></i>
                                                {{getLocalMsg('booking.details')}} {{book === 'P' ? getLocalMsg('booking.supplier') : getLocalMsg('booking.client')}}
                                            </p>
                                        </div>
                                        <div v-bind:id="'collapse-two-' + booking.id" class="collapse" v-bind:data-parent="'#extra-info-accordion-' + booking.id">
                                            <div class="card-body">
                                                <div class = "pl-4">
                                                    <div class="row">
                                                        <div class="col">
                                                            <p>
                                                                <strong>{{book === 'P' ? getLocalMsg('relation.supplierId') : getLocalMsg('relation.clientId')}}:</strong> {{booking.relation.refNumber}}<br>
                                                                <strong>{{getLocalMsg('relation.type')}}:</strong> {{booking.relation.relationtype.name}}<br>
                                                                <strong>{{getLocalMsg('relation.name')}}:</strong> {{booking.relation.name}}<br>
                                                                <strong>{{getLocalMsg('relation.vatNumber')}}:</strong> {{booking.relation.vatnumber}}<br>
                                                            </P>
                                                        </div>
                                                        <div class="col">
                                                            <strong>{{getLocalMsg('relation.street')}}:</strong> {{booking.relation.street}}<br>
                                                            <strong>{{getLocalMsg('relation.postcode')}}:</strong> {{booking.relation.postcode}}<br>
                                                            <strong>{{getLocalMsg('relation.city')}}:</strong> {{booking.relation.city}}<br>
                                                            <strong>{{getLocalMsg('relation.country')}}:</strong> {{booking.relation.country}}<br>
                                                        </div>
                                                        <div class="col">
                                                            <strong>{{getLocalMsg('relation.website')}}:</strong> {{booking.relation.website}}<br>
                                                            <strong>{{getLocalMsg('relation.email')}}:</strong> {{booking.relation.email}}<br>
                                                        </div>
                                                    </div>  
                                                </div>                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="booking.vatcodes.length > 0 && detailsVatCodes" class="card">
                                        <div class="card-header collapsed" data-toggle="collapse" v-bind:data-target="'#collapse-three-' + booking.id" id="heading-three">
                                            <p class="mb-0">
                                                <i class="fa fa-chevron-down mr-2"></i>
                                                {{getLocalMsg('booking.vatCodes')}}
                                            </p>
                                        </div>
                                        <div v-bind:id="'collapse-three-' + booking.id" class="collapse" v-bind:data-parent="'#extra-info-accordion-' + booking.id">
                                            <div class="card-body">
                                                <div class = "pl-4">
                                                    <p v-for="vatcode in booking.vatcodes" v-bind:key="vatcode.code">
                                                        <strong>{{getLocalMsg('booking.code')}} {{vatcode.code}}: </strong>{{fixAmount(vatcode.amount,2)}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            
        <!-- </div> -->
        <modal-template ref="modalDelete">
            <template v-slot:header>
                <div v-if="deleteWithRenumbering">
                    <h5>{{getLocalMsg('booking.delete.withRenumbering.header',{book: bookName(true,true)})}}</h5>
                </div>
                <div v-else>
                    <h5>{{getLocalMsg('booking.delete.withoutRenumbering.header',{book: bookName(true,true)})}}</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="deleteWithRenumbering">
                    <p>{{getLocalMsg('booking.delete.withRenumbering.body1',{books: bookName(false,true),book: bookName(true,true)})}}</p>
                    <h5>{{getLocalMsg('booking.delete.withRenumbering.body2')}}</h5>
                    <ul>
                        <li>{{getLocalMsg('booking.delete.withRenumbering.body3')}}</li>
                        <li>{{getLocalMsg('booking.delete.withRenumbering.body4',{book: bookName(true,true)})}}</li>
                    </ul>
                    <p>{{getLocalMsg('booking.delete.withRenumbering.body5',{book: bookName(true,true)})}}</p>
                    <h5>{{getLocalMsg('booking.delete.withRenumbering.body6')}}</h5>
                    <p>{{getLocalMsg('booking.delete.withRenumbering.body7',{books: bookName(false,true),book: bookName(true,true)})}}</p>
                </div>
                <div v-else>
                    <p>{{getLocalMsg('booking.delete.withoutRenumbering.body',{book: bookName(true,true)})}}</p>
                </div>
            </template>
            <template v-slot:footer>
                <div v-if="deleteWithRenumbering">
                    <button type="button" v-on:click="deleteBooking(selectedBooking)" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('booking.delete.withRenumbering.deleteButton')}}</button>
                </div>
                <div v-else>
                    <button type="button" v-on:click="deleteBooking(selectedBooking)" class="btn btn-primary" data-dismiss="modal">{{getLocalMsg('booking.delete.withoutRenumbering.deleteButton')}}</button>
                </div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
        <modal-template ref="modalTransaction">
            <template v-slot:header>
                <div>
                    <h5>{{getLocalMsg('transaction.add')}}</h5>
                </div>
            </template>
            <template v-slot:body>
                <transaction-edit 
                    ref="transactionEdit"
                    v-bind:key="forceRerenderKey"
                    v-bind:propTransaction="selectedTransaction"
                    v-bind:propBooking="selectedBooking" 
                    v-bind:propTransactionTypeCode="selectedBooking.book"
                />
            </template>
            <template v-slot:footer>
                <div>
                    <button type="button" v-on:click="updateTransaction" class="btn btn-primary">{{getLocalMsg('transaction.update')}}</button>
                </div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{getLocalMsg('global.cancel')}}</button>
            </template>
        </modal-template>
    </div>
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import ModalTemplate from '../../shared/ModalTemplate.vue'
import DropdownMenu from '../../shared/DropdownMenu.vue'
import TransactionEdit from '../../admin/transactions/TransactionEdit.vue'
import ValidationAlert from '../../shared/ValidationAlert';

export default {
    name: 'BookingItems',
    mixins: [sharedMixin],
    props: {
        book: String,
        bookings: Array,
        details: {
            type: Boolean,
            default: true
        },
        detailsRelation: {
            type: Boolean,
            default: true
        },
        detailsBooking: {
            type: Boolean,
            default: true
        },
        detailsVatCodes: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            selectedBooking: {},
            deleteWithRenumbering: true,
            validationErrors: [],
            forceRerenderKey: 0,
            selectedTransaction: {
                transactionType: {},
                transactionAccount: {},
                amount: ''
            },
        }
    },
    components: {
        ModalTemplate,
        DropdownMenu,
        ValidationAlert,
        TransactionEdit
    },
    computed: {
        writeOffs () {
            return this.getItems('writeoffs');
        },
    },
    methods: {
        popoverTechInfo (booking) {
            return {
                trigger:'click',
                html:true,
                title:this.getLocalMsg('global.techInfo'),
                content: `
                    ${this.getLocalMsg('global.techBookingId')}: ${booking.id}<br>
                    ${this.getLocalMsg('global.techRelationId')}: ${booking.relation.id}<br>
                    ${this.getLocalMsg('global.techAmountInput')}: ${this.fixAmount(booking.invoiceAmountInput,2)}
                `
                
            }
        },
        bookName (singular, lowercase) {
            let bookName = '';
            if(this.book === 'P'){
                bookName = singular ? this.getLocalMsg('booking.purchase') : this.getLocalMsg('booking.purchases');
            }else{
                bookName = singular ? this.getLocalMsg('booking.sale') : this.getLocalMsg('booking.sales');
            }
            return lowercase ? bookName.toLowerCase() : bookName;
        },
        isMaxRefNumber (bookingRefNumber) {
            const maxRefNumber = this.$store.getters['getMaxRefNumber']('bookings');
            if(bookingRefNumber == maxRefNumber){
                return true;
            }
            return false;
        },
        showDeleteModal(booking) {

            this.deleteWithRenumbering = this.isMaxRefNumber(booking.refNumber) ? false : true;
            this.selectedBooking = booking;
            this.showModal('modalDelete');
        },
        showTransactionModal(booking) {
            this.forceRerender();
            this.selectedTransaction = {
                booking: booking,
                transactionType: this.$store.state.transactionTypes.items.find(item => {
                    return item.code == booking.book;
                }),
            };
            this.showModal('modalTransaction');
        },
        showModal(refName) {
            let element = this.$refs[refName].$el
            $(element).modal();
        },
        transactionsTotal(booking) {
            return booking.transactions.reduce((a,b) => {
                return a + b.amount;
            },0)
        },
        deleteTransaction(transaction){

            this.$store.commit('showSpinner', true);

            const setActiveItem = () => {
                return this.$store.dispatch('setActiveItem', {
                    moduleName: 'transactions',
                    newItem: false,
                    item: transaction
                })
            }

            const setDeleted = setActiveItem().then(() => {
                return this.setItemProperty('transactions','isDeleted',true);
            })

            const updateTransaction = setDeleted.then(() => {
                return this.$store.dispatch('updateTransaction')
            })


            return updateTransaction.catch(error => {
                this.log(error);
            }).finally(
                () => this.$store.commit('showSpinner', false)
            )

        },
        updateTransaction() {
            const success = this.$refs.transactionEdit.updateTransaction().then(result =>{
                if (result == 'NotValidated') {
                    return;
                } else {
                    console.log('yes');
                    $(this.$refs.modalTransaction.$el).modal('hide');
                }
            });
        },
        validate (booking) {

            let validate = true;
            this.validationErrors = [];
            if(
                booking.booklines.find(bookline => {
                    return this.writeOffs.find(writeOff => {
                        return Number(writeOff.purchaseId) === Number(bookline.id) &&
                        writeOff.saleId;
                    });
                })
            ){
                this.validationErrors.push({
                    type: 'custom',
                    message: this.getLocalMsg('booking.saleExists')
                });
                validate=false;
            }

            return validate;
        },  
        deleteBooking(booking){

            if(!this.validate(booking)){
                return;
            };

            const deleteBooking = (booking) => {
                return this.deleteItem('bookings',booking.id);
            }

            const fetchWriteoffs = deleteBooking(booking).then(()=>{
                this.$store.commit('setLoadedState', {
                    moduleName: 'writeoffs',
                    loaded: false
                });
                return this.$store.dispatch('fetchData', 'writeoffs');
            })

            return fetchWriteoffs
            .catch(error => {
                this.log(error);
            })

        },
        editBooking(id) {
            if(this.book === 'P'){
                this.$router.push('/admin/purchase/' + id + '/edit')
            }else{
                this.$router.push('/admin/sale/' + id + '/edit')
            }
        },
        editWriteOff(purchaseId){
            this.$router.push('/admin/writeoff/' + purchaseId + '/edit')
        },
        forceRerender() {
            this.forceRerenderKey += 1;  
        }
        
    }
     
}
</script>

<style lang="scss" scoped>
    
    .table th {
        border: 0px;
    }

    .highlight-header-row {
        background-color: #3f556a !important;
        color: white;
    }

    .card {
        border:0px;
    }
    .card-header {
        cursor:pointer;
        background-color: white;
    }

    .card-body {
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .card-header .fa {
        transition: .3s transform ease-in-out;
    }

    .card-header.collapsed .fa {
        transform: rotate(-90deg);
    }
    

</style>
