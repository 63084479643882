import Helpers from '../../../../admin/helpers';

export default {

    /**
     * Totalen van de boeking berekenen
     * @param {*} param0 
     */
    setBookingTotals({commit}) {
        commit('setBookingTotals');
    },
    /**
     * Restbedrag berekenen voor de actieve boeking
     * Dit restbedrag kan dan automatisch ingevuld worden in de opgegeven boeklijn
     * @param {*} param0 
     * @param {*} booklineId 
     */
    setRestAmount({state,commit}, booklineId) {

        const booking = state.activeItem;
        const bookline = Helpers.getItem(booking.booklines, booklineId);

        //Alle boeklijnen ophalen
        const booklines = booking.booklines;

        //Indien er geen factuurbedrag, beroepspct of btw type is ingevuld, dan kan het restbedrag niet berekend worden
        //Bedrag wordt dus leeggemaakt en procedure wordt gestopt
        if ((!booking.invoiceAmountInput && booking.invoiceAmountInput !== 0) || 
            (!booking.professionalShare && booking.professionalShare !== 0) || 
            Helpers.emptyObject(bookline.vattype)) {
            //Toepassen restbedrag op de boeklijn
            commit('setBooklineProperty', {
                booklineId: bookline.id,
                property: 'amountExclVat',
                value: null
            });

            return;
        }

        //Bepalen of er incl of excl btw gerekend moet worden (afh van btw verlegd en leverancier EU/NEU)
        let typeOfInvoiceAmount = '';
        if (Helpers.invoiceExclVat(booking)) {
            typeOfInvoiceAmount = 'amountExclVat';
        } else {
            typeOfInvoiceAmount = 'amountInclVat';
        }

        //Totaal bedrag van alle boeklijnen die niet verwijderd zijn en met uitzondering van de huidige boeklijn
        let booklinesAmount = 0;
        for(let i = 0; i < booklines.length; i++) {
            if(booklines[i].id !== bookline.id )
            booklinesAmount += booklines[i][typeOfInvoiceAmount];
        }
        
        //Totaal professioneel gedeelte factuur
        const invoiceAmountProfessionalPart = booking.invoiceAmountInput * (booking.professionalShare / 100);

        //Berekening restbedrag
        let rest = invoiceAmountProfessionalPart - booklinesAmount;

        //Indien factuurbedrag inclusief btw is, herberekenen naar excl BTW
        if (typeOfInvoiceAmount === 'amountInclVat') {
            //Percentage voor btw-berekening
            const vattypePct = bookline.vattype.pct;
            //Berekenen restbedrag excl btw
            rest = Helpers.calcAmountExclVat(rest, vattypePct);
        }

        //Toepassen restbedrag op de boeklijn
        commit('setBooklineProperty', {
            booklineId: bookline.id,
            property: 'amountExclVat',
            value: rest
        });
        
    },
    //Actie die de default aftrekbare btw en kosten bepaalt. 
    /**
     * Bepalen van het % aftrekbare btw en kosten 
     * Voor verkopen altijd 100%
     * Voor aankopen obv categorie, indien niet opgenomen in btw aangifte is aangevinkt, dan steeds 0%
     * @param {*} param0 
     * @param {*} booklineId 
     */
    setDefaultDeductibleVatAndCostsShare({state,commit},booklineId) {

        const item = state.activeItem;
        const bookline = Helpers.getItem(item.booklines,booklineId);
        let deductibleVatShare;
        let deductibleCostsShare;

        // Voor verkopen wordt aftrekbare btw en aftrekbare kosten op 100% gezet
        if (item.book === 'S') {
            deductibleVatShare = 100;
            deductibleCostsShare = 100;
        }

        // Voor aankopen worden aftrekbate btw en aftrekbare kosten gezet obv de categorie
        // Indien geen categorie ingevuld, dan wordt default 100% genomen
        if (item.book === 'P') {
            deductibleVatShare = 100;
            deductibleCostsShare = 100;
            if (!Helpers.emptyObject(bookline.category)) {
                deductibleVatShare = bookline.category.defaultDeductibleVatShare;
                deductibleCostsShare = bookline.category.defaultDeductibleCostsShare;
            }                
        }

        //Indien veld 'Niet opgenomen in de btw aangifte' is aangevinkt, 
        //dan zetten we deductibleVatShare op 0
        if (item.noVatDeclaration) {
            deductibleVatShare = 0;
        } 

        commit('setBooklineProperty', {
            booklineId: bookline.id,
            property: 'deductibleVatShare',
            value: deductibleVatShare
        });
        
        commit('setBooklineProperty', {
            booklineId: bookline.id,
            property: 'deductibleCostsShare',
            value: deductibleCostsShare
        });

    },
    /**
     * Berekenen van bedragen voor de boeklijn
     * Bedrag BTW
     * Bedrag incl BTW
     * Bedrag aftrekbare kosten/in te brengen opbrengsten
     * Bedrag aftrekbare BTW/te betalen BTW
     * @param {*} param0 
     * @param {*} booklineId 
     */
    applyCalculations ({state,commit},booklineId) {

            const item = state.activeItem;
            const bookline = Helpers.getItem(item.booklines, booklineId);
            
            //Bedrag BTW en incl BTW
            const amountExclVat = bookline.amountExclVat;
            const vattype = bookline.vattype;
            const vattypePct = vattype ? vattype.pct : null;

            let amountInclVat = null;
            let amountVat = null;
            if (!isNaN(parseInt(amountExclVat)) && !isNaN(parseInt(vattypePct))) {
                amountInclVat = Helpers.calcAmountInclVat(amountExclVat, vattypePct);
                amountVat = Helpers.calcAmountVat(amountExclVat, vattypePct);
            }

            commit('setBooklineProperty', {
                'booklineId': bookline.id,
                'property': 'amountInclVat',
                'value': amountInclVat
            });
            commit('setBooklineProperty', {
                'booklineId': bookline.id,
                'property': 'amountVat',
                'value': amountVat
            });   
            
        },

}