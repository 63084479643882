var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.getLocalMsg("stockchange.overview")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table table-hover" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.getLocalMsg("stockchange.year")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("stockchange.initial")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("stockchange.end")))
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("stockchange.change")))
                  ]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.stockchanges, function(stockchange, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(stockchange.year))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _c("input", {
                        staticClass: "form-control text-right",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.initial
                        },
                        domProps: {
                          value: _vm.fixAmount(stockchange.initial, 2)
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateInitial($event, stockchange.year)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-right" }, [
                      _c("input", {
                        staticClass: "form-control text-right",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.end
                        },
                        domProps: { value: _vm.fixAmount(stockchange.end, 2) },
                        on: {
                          change: function($event) {
                            return _vm.updateEnd($event, stockchange.year)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.fixAmount(
                            stockchange.end - stockchange.initial,
                            2
                          )
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }