var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _vm.lockedPeriod(_vm.year, _vm.period)
          ? _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getLocalMsg("booking.locked")) +
                  "\n    "
              )
            ])
          : _c(
              "div",
              [
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h1", { staticClass: "d-inline" }, [
                      _vm._v(_vm._s(_vm.title()))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("validation-alert", {
                  attrs: { errors: _vm.validationErrors }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "relations" } }, [
                          _vm._v(_vm._s(_vm.relationLabel))
                        ]),
                        _vm._v(" "),
                        _c(
                          "search-and-select",
                          {
                            ref: "relation",
                            attrs: {
                              data: _vm.relations,
                              currentSearchValue: _vm.relation.name,
                              "next-field-id": _vm.noAutoComplete.invoiceDate,
                              placeholder:
                                _vm.getLocalMsg("global.search") +
                                " " +
                                _vm.relationLabel
                            },
                            on: {
                              hit: function($event) {
                                return _vm.commitRelation($event)
                              }
                            }
                          },
                          [
                            _c("template", { slot: "append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addNewRelation }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.getLocalMsg("global.new")) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          { attrs: { for: _vm.noAutoComplete.invoiceDate } },
                          [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("booking.invoiceDate"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("date-picker", {
                          attrs: {
                            autocomplete: _vm.noAutoComplete.invoiceDate,
                            id: _vm.noAutoComplete.invoiceDate,
                            config: _vm.dateTimePickerOptions
                          },
                          model: {
                            value: _vm.invoiceDate,
                            callback: function($$v) {
                              _vm.invoiceDate = $$v
                            },
                            expression: "invoiceDate"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { attrs: { for: _vm.noAutoComplete.reference } },
                        [_vm._v(_vm._s(_vm.getLocalMsg("booking.reference")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.reference,
                            expression: "reference",
                            modifiers: { lazy: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.reference,
                          id: _vm.noAutoComplete.reference
                        },
                        domProps: { value: _vm.reference },
                        on: {
                          change: function($event) {
                            _vm.reference = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { attrs: { for: _vm.noAutoComplete.description } },
                        [_vm._v(_vm._s(_vm.getLocalMsg("booking.description")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.description,
                            expression: "description",
                            modifiers: { lazy: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.description,
                          id: _vm.noAutoComplete.description
                        },
                        domProps: { value: _vm.description },
                        on: {
                          change: function($event) {
                            _vm.description = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { attrs: { for: _vm.noAutoComplete.invoiceAmount } },
                        [
                          _vm._v(
                            _vm._s(_vm.getLocalMsg("booking.invoiceAmount"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.invoiceAmountInput,
                            expression: "invoiceAmountInput",
                            modifiers: { lazy: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          autocomplete: _vm.noAutoComplete.invoiceAmount,
                          id: _vm.noAutoComplete.invoiceAmount
                        },
                        domProps: { value: _vm.invoiceAmountInput },
                        on: {
                          change: function($event) {
                            _vm.invoiceAmountInput = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.book === "P"
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              attrs: {
                                for: _vm.noAutoComplete.professionalShare
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getLocalMsg("booking.professionalShare")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: _vm.professionalShare,
                                  expression: "professionalShare",
                                  modifiers: { lazy: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                autocomplete:
                                  _vm.noAutoComplete.professionalShare,
                                id: _vm.noAutoComplete.professionalShare
                              },
                              domProps: { value: _vm.professionalShare },
                              on: {
                                change: function($event) {
                                  _vm.professionalShare = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm._m(0)
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "detail ml-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getLocalMsg("booking.professionalShare")
                              ) +
                                " : " +
                                _vm._s(_vm.invoiceAmountProfessionalPart)
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "form-group pl-4 d-inline-block" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.noVatDeclaration,
                              expression: "noVatDeclaration"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            tabindex: "-1",
                            id: "no-vat-declaration"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.noVatDeclaration)
                              ? _vm._i(_vm.noVatDeclaration, null) > -1
                              : _vm.noVatDeclaration
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.noVatDeclaration,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.noVatDeclaration = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.noVatDeclaration = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.noVatDeclaration = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "no-vat-declaration" }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getLocalMsg("booking.noVatDeclaration")
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group ml-4 pl-4 d-inline-block" },
                      [
                        _vm.relationtypeCode === "BE" ||
                        _vm.relationtypeCode === "EU"
                          ? _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.vatShifted,
                                    expression: "vatShifted"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  tabindex: "-1",
                                  id: "vat-shifted"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.vatShifted)
                                    ? _vm._i(_vm.vatShifted, null) > -1
                                    : _vm.vatShifted
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.vatShifted,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.vatShifted = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.vatShifted = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.vatShifted = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "vat-shifted" }
                                },
                                [
                                  _vm.relationtypeCode === "BE"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLocalMsg(
                                              "booking.vatShifted"
                                            )
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.relationtypeCode === "EU"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLocalMsg(
                                              "booking.realEstate"
                                            )
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box bg-white" },
                  [
                    _c("table", { staticClass: "table table-hover" }, [
                      _c("thead", [
                        _c("tr", [
                          _vm.book === "P"
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(_vm.getLocalMsg("booking.account"))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.getLocalMsg("booking.category")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.getLocalMsg("booking.vattype")))
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("booking.amountExclVat"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(_vm.getLocalMsg("booking.amountVat")))
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.getLocalMsg("booking.amountInclVat"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("th"),
                          _vm._v(" "),
                          _vm.book === "P"
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getLocalMsg("booking.deductibleVat")
                                  )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.book === "P"
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getLocalMsg("booking.deductibleCosts")
                                  )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.booklines, function(bookline, index) {
                          return _c("bookline-edit", {
                            key: index,
                            ref: "bookline-" + bookline.id,
                            refInFor: true,
                            attrs: { id: bookline.id }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _vm.book === "P" ? _c("th") : _vm._e(),
                        _vm._v(" "),
                        _c("th"),
                        _vm._v(" "),
                        _c("th"),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _c("span", { staticClass: "pr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.amountExclVat
                                  ? _vm.amountExclVat
                                  : _vm.fixAmount(0, 2)
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            class: {
                              "not-applicable": !_vm.vatApplicable(
                                _vm.$store.state.bookings.activeItem
                              ).value
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.amountVat
                                  ? _vm.amountVat
                                  : _vm.fixAmount(0, 2)
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "text-right",
                            class: {
                              "not-applicable": !_vm.vatApplicable(
                                _vm.$store.state.bookings.activeItem
                              ).value
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.amountInclVat
                                  ? _vm.amountInclVat
                                  : _vm.fixAmount(0, 2)
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.book === "P" ? _c("th") : _vm._e(),
                        _vm._v(" "),
                        _vm.book === "P" ? _c("th") : _vm._e(),
                        _vm._v(" "),
                        _c("th")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "float-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-3",
                          attrs: { tabindex: "0" },
                          on: { click: _vm.generateInvoice }
                        },
                        [_vm._v("Invoice")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-3",
                          attrs: { tabindex: "0" },
                          on: {
                            click: function($event) {
                              return _vm.save("back")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.save")))]
                      ),
                      _vm._v(" "),
                      _vm.propNewItem
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-3",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function($event) {
                                  return _vm.save("next")
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("global.saveAndNext"))
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { tabindex: "0" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("modal-template", {
                      ref: "modalNewRelation",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c("h5", { staticClass: "modal-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.book === "P"
                                        ? _vm.getLocalMsg("booking.newSupplier")
                                        : _vm.getLocalMsg("booking.newClient")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal"
                                    }
                                  },
                                  [_c("span", [_vm._v("×")])]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "body",
                            fn: function() {
                              return [
                                _c("relation-edit", {
                                  ref: "relationEdit",
                                  attrs: {
                                    propModalStyle: true,
                                    propNewItem: true,
                                    propClientSupplier:
                                      _vm.book === "P" ? "S" : "C",
                                    propEditable: true
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateRelation()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getLocalMsg("global.save"))
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getLocalMsg("global.close"))
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3751625916
                      )
                    }),
                    _vm._v(" "),
                    _c("modal-template", {
                      ref: "modalConfirms",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function() {
                              return [
                                _c(
                                  "ul",
                                  _vm._l(_vm.confirms, function(
                                    confirm,
                                    index
                                  ) {
                                    return _c("li", { key: index }, [
                                      _vm._v(_vm._s(confirm))
                                    ])
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLocalMsg(
                                          "booking.confirms.correct"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal"
                                    },
                                    on: { click: _vm.updateItem }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLocalMsg(
                                          "booking.confirms.proceed"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3723627278
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("%")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }