var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.title()))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.newRelation()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("global.new")))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "search-relations" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.search")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchInput,
                      expression: "searchInput"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "search-relations" },
                  domProps: { value: _vm.searchInput },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchInput = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table table-hover" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(
                        _vm.clientSupplier === "S"
                          ? _vm.getLocalMsg("relation.supplierId")
                          : _vm.getLocalMsg("relation.clientId")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("relation.name")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("relation.type")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.getLocalMsg("relation.vatNumber")))
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("relation.city")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.getLocalMsg("relation.country")))
                  ]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.relations, function(relation, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.refNumber))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.relationtype.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.vatnumber))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.city))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(relation.country))
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "align-middle text-right" },
                      [
                        _c("dropdown-menu", {
                          on: {
                            view: function($event) {
                              return _vm.viewRelation(relation.id)
                            },
                            edit: function($event) {
                              return _vm.editRelation(relation.id)
                            },
                            delete: function($event) {
                              return _vm.showDeleteModal(relation)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalDelete",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg("relation.delete.header", {
                              relation: _vm.relationName(true, true)
                            })
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg("relation.delete.body", {
                              relation: _vm.relationName(true, true)
                            })
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.deleteRelation }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.delete")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              683422278
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }