var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("h1", { staticClass: "d-inline" }, [
              _vm._v(_vm._s(_vm.getLocalMsg("lockedperiod.overview")))
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "box bg-white" }, [
          _c("table", { staticClass: "table table-hover" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.getLocalMsg("lockedperiod.period")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("lockedperiod.locked")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.periods, function(period) {
                return _c("tr", { key: period }, [
                  _c("td", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(period))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "align-middle text-right" },
                    [
                      _c("toggle-switch", {
                        attrs: {
                          propRounded: true,
                          propChecked: _vm.isLocked(period)
                        },
                        on: {
                          toggle: function($event) {
                            return _vm.update(period, $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "box bg-white" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.getLocalMsg("lockedperiod.info")) +
              "\n            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }