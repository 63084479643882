import localizationMixin from './localization-mixin';
import Helpers from '../admin/helpers'

export default {
    data() {
        return {
            dateTimePickerOptions: {
                format: this.getLocalMsg('dates.format'),
                useCurrent: false,
            },
        }
    },
    mixins: [localizationMixin],
    methods: {
        getSelectionProperty(moduleName,property) {
            const data = {
                moduleName: moduleName,
                property: property
            };
            return this.$store.getters['getSelectionProperty'](data);
        },
        setSelectionProperty(moduleName,property, value) {
            const data = {
                moduleName: moduleName,
                property: property,
                value: value
            };
            return this.$store.dispatch('setSelectionProperty', data);
        },
        getItems(moduleName) {
            const data = {
                moduleName: moduleName,
            };
            return this.$store.getters['getItems'](data);
        },
        getItemProperty(moduleName,property) {
            const data = {
                moduleName: moduleName,
                property: property
            };
            return this.$store.getters['getItemProperty'](data);
        },
        setItemProperty(moduleName, property, value) {
            const data = {
                moduleName: moduleName,
                property: property,
                value: value
            };
            return this.$store.dispatch('setItemProperty', data);
        },
        deleteItem(moduleName,itemId) {

            this.$store.commit('showSpinner', true);

            return this.$store.dispatch('deleteItem', {
                moduleName: moduleName,
                itemId: itemId
            }).then(
                () => this.$store.commit('showSpinner', false)
            );
            
        },
        vatApplicable(booking) {

            let text = '';
            let value = true;

            if(!booking.relation.relationtype){
                return {
                    text: '',
                    value: true
                };
            }

            if (booking.relation.relationtype.code === 'NOEU') {
                value = false;
                text = this.getLocalMsg('booking.vatExempt');
            } else if (booking.relation.relationtype.code === 'EU') {
                value = false;
                if (booking.vatShifted) {
                    text = this.getLocalMsg('booking.realEstate');
                } else {
                    text = this.getLocalMsg('booking.vatShifted');
                }
            } else {
                if (booking.vatShifted) {
                    value = false;
                    text = this.getLocalMsg('booking.vatShifted');
                }
            }

            return {
                text: text ? '<br><strong>' + text + '</strong>' : text,
                value: value
            };

        },
        formatDate(date) {
            return Helpers.formatDate(date, 'YYYY-MM-DD', this.getLocalMsg('dates.format'));
        },
        fixAmount(amount, decimals) {
            return Helpers.fixAmount(amount, decimals);
        },
        roundAmount(amount, decimals) {
            return Helpers.roundAmount(amount, decimals);
        },
        getQuarter(date) {
            return Helpers.getQuarter(date);
        },
        emptyObject(obj) {
            return Helpers.emptyObject(obj);
        },
        log(val) {
            Helpers.log(val);
        },
        logts(val) {
            Helpers.logts(val);
        },
        currentTimestamp() {
            return Helpers.currentTimestamp();
        },
        isEmpty(val) {
            return Helpers.isEmpty(val);
        },
        isInteger(val) {
            return Helpers.isInteger(val);
        },
        isNumeric(val) {
            return Helpers.isNumeric(val);
        },
        formatVatNumber(vatNumber){
            return Helpers.formatVatNumber(vatNumber);
        },
        checkVatNumber(vatNumber) {
            return Helpers.checkVatNumber(vatNumber);
        },
        generateRandomGuid() {
            return Helpers.generateRandomGuid();
        },
        noAutoCompleteId() {
            return Helpers.noAutoCompleteId();
        },
        totalsNotMatching(booking) {
            return Helpers.totalsNotMatching(booking);
        },
        fetchData() {
            this.$store.commit('showSpinner', true);
            this.$store.dispatch('fetchData', 'bookings');
            this.$store.dispatch('fetchData', 'relations');
            this.$store.dispatch('fetchData', 'accounts');
            this.$store.dispatch('fetchData', 'categories');
            this.$store.dispatch('fetchData', 'vattypes');
            this.$store.dispatch('fetchData', 'relationtypes');
            this.$store.dispatch('fetchData', 'stockchanges');
            this.$store.dispatch('fetchData', 'lockedperiods');
            this.$store.dispatch('fetchData', 'writeoffs');
            this.$store.dispatch('fetchData', 'transactions');
            this.$store.dispatch('fetchData', 'transactionAccounts');
            this.$store.dispatch('fetchData', 'transactionTypes');
            this.$store.dispatch('fetchData', 'companies');
        },
        lockedPeriod(year,period) {
            const lockedPeriods = this.getItems('lockedperiods');
            return lockedPeriods.includes(year + '_' + period);
        },
        
    }
};

