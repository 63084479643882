var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              !_vm.propModalStyle
                ? _c("h1", { staticClass: "d-inline" }, [
                    _vm._v(_vm._s(_vm.title()))
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
          _vm._v(" "),
          !_vm.propModalStyle ? _vm._m(0) : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "box bg-white" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2", class: _vm.colClass }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "relation-type" } }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("relation.type")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.relationtype,
                              expression: "relationtype"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.propEditable,
                            id: "relation-type"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.relationtype = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._m(1),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.relationtypeCode !== "NOEU" &&
                  _vm.relationtypeCode !== "PAR"
                    ? _c("div", { staticClass: "col-2", class: _vm.colClass }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.vatnumber } },
                            [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("relation.vatNumber"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.lazy",
                                value: _vm.vatnumber,
                                expression: "vatnumber",
                                modifiers: { lazy: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.vatnumber,
                              id: _vm.noAutoComplete.vatnumber
                            },
                            domProps: { value: _vm.vatnumber },
                            on: {
                              change: function($event) {
                                _vm.vatnumber = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.vatNumberValidationText
                            ? _c(
                                "small",
                                {
                                  staticClass:
                                    "form-text text-muted fg-color-primary"
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(this.vatNumberValidationText) +
                                      "\n                            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4", class: _vm.colClass }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.noAutoComplete.name } }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("relation.name")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: !_vm.propEditable,
                          type: "text",
                          autocomplete: _vm.noAutoComplete.name,
                          id: _vm.noAutoComplete.name
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.street } },
                            [_vm._v(_vm._s(_vm.getLocalMsg("relation.street")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.street,
                                expression: "street"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.street,
                              id: _vm.noAutoComplete.street
                            },
                            domProps: { value: _vm.street },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.street = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.postcode } },
                            [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("relation.postcode"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.postcode,
                                expression: "postcode"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.postcode,
                              id: _vm.noAutoComplete.postcode
                            },
                            domProps: { value: _vm.postcode },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.postcode = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.city } },
                            [_vm._v(_vm._s(_vm.getLocalMsg("relation.city")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.city,
                                expression: "city"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.city,
                              id: _vm.noAutoComplete.city
                            },
                            domProps: { value: _vm.city },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.city = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.country } },
                            [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("relation.country"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.country,
                                expression: "country"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.country,
                              id: _vm.noAutoComplete.country
                            },
                            domProps: { value: _vm.country },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.country = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.website } },
                            [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("relation.website"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website,
                                expression: "website"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.website,
                              id: _vm.noAutoComplete.website
                            },
                            domProps: { value: _vm.website },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.website = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propModalStyle
                    ? _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: _vm.noAutoComplete.email } },
                            [_vm._v(_vm._s(_vm.getLocalMsg("relation.email")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.propEditable,
                              type: "text",
                              autocomplete: _vm.noAutoComplete.email,
                              id: _vm.noAutoComplete.email
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.email = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm.propModalStyle
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary float-right",
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.propModalStyle && _vm.propEditable
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary float-right mr-3",
                        on: { click: _vm.save }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("global.save")))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              !_vm.propNewItem
                ? _c("div", [
                    _c("h4", { staticClass: "mt-4" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.book === "P"
                            ? _vm.getLocalMsg("relation.purchases")
                            : _vm.getLocalMsg("relation.sales")) +
                            " (" +
                            _vm.bookings.length +
                            ")"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box bg-white" },
                      [
                        _c("booking-items", {
                          attrs: {
                            book: _vm.book,
                            bookings: _vm.bookings,
                            details: true,
                            detailsRelation: false
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "box bg-white" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.getLocalMsg("relation.info")) +
              "\n            "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.relationtypes, function(relationtype, index) {
      return _c("option", { key: index, domProps: { value: relationtype } }, [
        _vm._v(_vm._s(relationtype.name))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }