<template>
    <div v-if="this.loaded">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="years">{{getLocalMsg('global.years')}}</label>
                    <select class="form-control" id="years" v-model="selectedYear">
                        <option v-once v-for="(year, index) in years" v-bind:key="index" v-bind:value = "year">{{year}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.totalPurchases',{'year': selectedYear})}}</h5>
                    <h1>{{emptyObject(purchases) ? 0 : fixAmount(purchases.Total.Total,2)}}</h1>
                </div>
            </div>
            <div class="col">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.totalSales',{'year': selectedYear})}}</h5>
                    <h1>{{emptyObject(sales) ? 0 : fixAmount(sales.Total.Total,2)}}</h1>
                </div>
            </div>
            <div class="col">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.nextVatDeclaration')}}</h5>
                    <h1>20/01/2020</h1>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.resultsPerMonth',{'year': selectedYear})}}</h5>
                    <bar-chart class="chart" :chart-data="barChartData('M',sales,purchases)" :options="barChartOptions"></bar-chart>
                </div>
            </div>
            <div class="col-6">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.resultsPerQuarter',{'year': selectedYear})}}</h5>
                    <bar-chart class="chart" :chart-data="barChartData('Q',sales,purchases)" :options="barChartOptions"></bar-chart>
                </div>
            </div>
        </div>
        <!-- <div class="row mb-3">
            <div class="col-6">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.retailPerMonth',{'year': selectedYear})}}</h5>
                    <bar-chart class="chart" :chart-data="barChartData('M',sales,retailGoods)" :options="barChartOptions"></bar-chart>
                </div>
            </div>
            <div class="col-6">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.retailPerQuarter',{'year': selectedYear})}}</h5>
                    <bar-chart class="chart" :chart-data="barChartData('Q',sales,retailGoods)" :options="barChartOptions"></bar-chart>
                </div>
            </div>
        </div> -->
        <div class="row mb-3">
            <div class="col-12">
                <div class="box bg-white">
                    <h5>{{getLocalMsg('dashboard.resultsPerQuarter',{'year': selectedYear})}}</h5>
                    <pie-chart class="chart" :chart-data="pieChartData()" :options="pieChartOptions"></pie-chart>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import sharedMixin from '../../mixins/shared-mixin';
import BarChart from '../../shared/barchart.js'
import PieChart from '../../shared/piechart.js'



export default {
    name: 'Dashboard',
    components: {
      BarChart,
      PieChart
    },
    watch: {
        $route: function () {
            this.initiate(this.loaded);
        },
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        }
    },
    data () {
      return {
        barChartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                }]
            }
        },
        pieChartOptions: {
            responsive: true,
            maintainAspectRatio: false,
        }
      }
    },
    mixins: [sharedMixin],
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.bookings.loaded ? loaded : false;
            loaded = this.$store.state.years.loaded ? loaded : false;
            return loaded;
        },
        purchases () {
            return this.$store.getters['bookings/getPurchases'];
        },
        // retailGoods () {
        //     return this.$store.getters['bookings/getRetailGoods'];
        // },
        purchasesByCategory () {
            return this.$store.getters['bookings/getPurchasesByCategory'];
        },
        sales () {
            return this.$store.getters['bookings/getSales'];
        },
        years () {
            return this.getItems('years');
        },
        selectedYear: {
            get: function () {
                return this.getSelectionProperty('bookings','year');
            },
            set: function (value) {
                this.setSelectionProperty('bookings','year',value);
            }   
        },
    },
    methods: {
        initiate (loaded) {
            if(!loaded) {
                this.fetchData();
                return;
            }
            this.$store.commit('showSpinner',false);
        },
        barChartData(periodicity,set1,set2) {

            let dataLabels = [];
            let dataset1 = [];
            let dataset2 = [];

            if(periodicity === 'M'){
                for(let i=1; i<=12; i++){
                    let period = 'M' + ('00' + i).substr(-2);
                    dataLabels.push(this.getLocalMsg('global.' + period));
                    if(!this.emptyObject(this.sales)){
                        dataset1.push(this.fixAmount(set1.Total[period],2));
                    }
                    if(!this.emptyObject(this.purchases)){
                        dataset2.push(this.fixAmount(set2.Total[period],2));
                    }
                }
            }else{
                for(let i=1; i<=4; i++){
                    let period = 'Q' + i;
                    dataLabels.push(this.getLocalMsg('global.' + period));
                    if(!this.emptyObject(this.sales)){
                        dataset1.push(this.fixAmount(set1.Total[period],2));
                    }
                    if(!this.emptyObject(this.purchases)){
                        dataset2.push(this.fixAmount(set2.Total[period],2));
                    }
                }
            }

            

            return {

                labels: dataLabels,
                datasets: [
                    {
                    label: this.getLocalMsg('dashboard.sales'),
                    backgroundColor: '#5CB85C',
                    borderColor: '#5CB85C',
                    data: dataset1,
                    fill: false
                    },
                    {
                    label: this.getLocalMsg('dashboard.purchases'),
                    backgroundColor: '#D74B4B',
                    borderColor: '#D74B4B',
                    data: dataset2,
                    fill: false
                    }, 
                ]
            }
        } ,
        pieChartData() {

            let dataLabels = [];
            let dataPurchases = [];

            if(!this.emptyObject(this.purchasesByCategory)){
                for (let category in this.purchasesByCategory) {
                    // skip loop if the property is from prototype
                    if (!this.purchasesByCategory.hasOwnProperty(category)) continue;
                    
                    dataLabels.push(category);
                    dataPurchases.push(this.purchasesByCategory[category]['Total']);

                }
            }
                
            return {

                labels: dataLabels,
                datasets: [
                    {
                    label: this.getLocalMsg('dashboard.purchases'),
                    backgroundColor: '#5CB85C',
                    //borderColor: '#5CB85C',
                    data: dataPurchases,
                    //fill: false
                    }
                ]
            }
        }  
    }
}

</script>

<style lang="scss" scoped>
    .chart {
        height: 300px;
    }
</style>>


</style>
