var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.getLocalMsg("category.overviewCategories")))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary float-right",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.newCategory()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getLocalMsg("global.new")))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table table-hover" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.getLocalMsg("category.name")))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.getLocalMsg("category.defaultDeductibleVatShare")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.getLocalMsg("category.defaultDeductibleCostsShare")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.categories, function(category, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "align-middle" }, [
                      _vm._v(_vm._s(category.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-right" }, [
                      _vm._v(_vm._s(category.defaultDeductibleVatShare))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "align-middle text-right" }, [
                      _vm._v(_vm._s(category.defaultDeductibleCostsShare))
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "align-middle text-right" },
                      [
                        _c("dropdown-menu", {
                          on: {
                            view: function($event) {
                              return _vm.viewCategory(category.id)
                            },
                            edit: function($event) {
                              return _vm.editCategory(category.id)
                            },
                            delete: function($event) {
                              return _vm.showDeleteModal(category)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalDelete",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("category.delete.header"))
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(_vm._s(_vm.getLocalMsg("category.delete.body")))
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.deleteCategory }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.delete")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              908480240
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }