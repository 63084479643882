import getters from './getters/index.js';
import actions from './actions/index.js';
import mutations from './mutations/index.js';

export default {
    namespaced: true,
    state: {
        loaded: false,
        activeItem: {},
        selection: {
            year: currentYear(),
            period: currentPeriod(),
            book: '',
        },
        items: [],
    },
    getters,
    mutations,
    actions
};

function currentYear() {
    const dt = new Date();
    const curYear = dt.getFullYear().toString();
    return curYear;
}

function currentPeriod () {
    const dt = new Date();
    const curMonth = dt.getMonth() + 1;
    if(curMonth<=3){
        return 'Q1';
    }else if(curMonth<=6){
        return 'Q2';
    }else if(curMonth<=9){
        return 'Q3';
    }else{
        return 'Q4';
    }
    
}



