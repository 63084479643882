<template>
    <div>
        <h1>Home</h1>
    </div>
</template>

<script>


export default {
    name: 'Home'
}
</script>

<style>

</style>
