var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "d-inline" }, [
                _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.title")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "years" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.years")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedYear,
                        expression: "selectedYear"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "years" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedYear = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._m(0),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "periods" } }, [
                  _vm._v(_vm._s(_vm.getLocalMsg("global.periods")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPeriod,
                        expression: "selectedPeriod"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "periods" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedPeriod = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._m(1),
                  0
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box bg-white" }, [
            _c("table", { staticClass: "table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2A")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(0)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2A00"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(0), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2B")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(1)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2B01"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(1), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(2)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2B02"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(2), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(3)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2B03"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(3), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2C")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(44)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2C44"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(44), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2D")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(45)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2D45"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(45), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2E")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(46)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2E46"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(46), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2F")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(47)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2F47"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(47), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box2G")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(48)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2G48"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(48), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(49)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box2G49"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(49), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3A")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(81)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3A81"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(81), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(82)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3A82"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(82), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(83)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3A83"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(83), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3B")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(84)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3B84"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(84), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(85)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3B85"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(85), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3C")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(86)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3C86"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(86), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3D")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(87)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3D87"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(87), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box3E")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(88)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box3E88"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(88), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4A")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(54)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4A54"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(54), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(55)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4A55"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(55), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(56)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4A56"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(56), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4B")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(57)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4B57"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(57), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4C")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(61)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4C61"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(61), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4D")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(63)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box4D63"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(63), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4E")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box4EXX")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcodeXX, 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5A")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(59)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box5A59"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(59), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5B")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(62)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box5B62"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(62), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5C")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.showModalDetail(64)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.getLocalMsg("vatDeclaration.box5C64"))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode(64), 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5D")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box5DYY")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcodeYY, 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box6")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box671")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode71, 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box672")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.fixAmount(_vm.vatcode72, 2)))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box7")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box791")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.leaveEmpty")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box8")))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.box8KL")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.getLocalMsg("vatDeclaration.none")))
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalDetail",
            attrs: { dialogClasses: "modal-xxl modal-dialog-scrollable" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg("vatDeclaration.detailVatcode")
                          ) +
                            " " +
                            _vm._s(_vm.selectedVatcode)
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("table", { staticClass: "table table-hover" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.bookingId"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.invoiceDate"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.relation"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.reference"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.getLocalMsg("booking.description"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(_vm.getLocalMsg("booking.amount")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.selectedBookings, function(booking) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key: booking.id,
                                    staticClass: "header-row"
                                  },
                                  [
                                    _c("td", { staticClass: "align-middle" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showModalBooking(
                                                booking.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              booking.book === "P"
                                                ? _vm.getLocalMsg(
                                                    "booking.purchase"
                                                  )
                                                : _vm.getLocalMsg(
                                                    "booking.sale"
                                                  )
                                            ) +
                                              " " +
                                              _vm._s(booking.refNumber)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(booking.invoiceDate)
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.relation.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.reference))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "align-middle" }, [
                                      _vm._v(_vm._s(booking.description))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "align-middle text-right"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fixAmount(
                                              booking.vatcodes.find(function(
                                                item
                                              ) {
                                                return (
                                                  Number(item.code) ===
                                                  Number(_vm.selectedVatcode)
                                                )
                                              }).amount,
                                              2
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.close")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              785383610
            )
          }),
          _vm._v(" "),
          _c("modal-template", {
            ref: "modalBooking",
            attrs: { dialogClasses: "modal-xxl modal-dialog-scrollable" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.getLocalMsg("vatDeclaration.detailBooking")
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _vm.selectedBooking.length > 0
                        ? _c("booking-items", {
                            attrs: {
                              book: _vm.selectedBooking[0].book,
                              bookings: _vm.selectedBooking,
                              details: true,
                              detailsRelation: true
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v(_vm._s(_vm.getLocalMsg("global.close")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              923284831
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.years, function(year, index) {
      return _c("option", { key: index, domProps: { value: year } }, [
        _vm._v(_vm._s(year))
      ])
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.periods, function(period, index) {
      return _c("option", { key: index, domProps: { value: period } }, [
        _vm._v(_vm._s(_vm.getLocalMsg("global." + period)))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }