var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.loaded
    ? _c("div", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "years" } }, [
                _vm._v(_vm._s(_vm.getLocalMsg("global.years")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedYear,
                      expression: "selectedYear"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "years" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedYear = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._m(0),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "box bg-white" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(
                    _vm.getLocalMsg("dashboard.totalPurchases", {
                      year: _vm.selectedYear
                    })
                  )
                )
              ]),
              _vm._v(" "),
              _c("h1", [
                _vm._v(
                  _vm._s(
                    _vm.emptyObject(_vm.purchases)
                      ? 0
                      : _vm.fixAmount(_vm.purchases.Total.Total, 2)
                  )
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "box bg-white" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(
                    _vm.getLocalMsg("dashboard.totalSales", {
                      year: _vm.selectedYear
                    })
                  )
                )
              ]),
              _vm._v(" "),
              _c("h1", [
                _vm._v(
                  _vm._s(
                    _vm.emptyObject(_vm.sales)
                      ? 0
                      : _vm.fixAmount(_vm.sales.Total.Total, 2)
                  )
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "box bg-white" }, [
              _c("h5", [
                _vm._v(_vm._s(_vm.getLocalMsg("dashboard.nextVatDeclaration")))
              ]),
              _vm._v(" "),
              _c("h1", [_vm._v("20/01/2020")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "box bg-white" },
              [
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.getLocalMsg("dashboard.resultsPerMonth", {
                        year: _vm.selectedYear
                      })
                    )
                  )
                ]),
                _vm._v(" "),
                _c("bar-chart", {
                  staticClass: "chart",
                  attrs: {
                    "chart-data": _vm.barChartData(
                      "M",
                      _vm.sales,
                      _vm.purchases
                    ),
                    options: _vm.barChartOptions
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "box bg-white" },
              [
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.getLocalMsg("dashboard.resultsPerQuarter", {
                        year: _vm.selectedYear
                      })
                    )
                  )
                ]),
                _vm._v(" "),
                _c("bar-chart", {
                  staticClass: "chart",
                  attrs: {
                    "chart-data": _vm.barChartData(
                      "Q",
                      _vm.sales,
                      _vm.purchases
                    ),
                    options: _vm.barChartOptions
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "box bg-white" },
              [
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.getLocalMsg("dashboard.resultsPerQuarter", {
                        year: _vm.selectedYear
                      })
                    )
                  )
                ]),
                _vm._v(" "),
                _c("pie-chart", {
                  staticClass: "chart",
                  attrs: {
                    "chart-data": _vm.pieChartData(),
                    options: _vm.pieChartOptions
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _vm._l(_vm.years, function(year, index) {
      return _c("option", { key: index, domProps: { value: year } }, [
        _vm._v(_vm._s(year))
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }