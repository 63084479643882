var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              !_vm.propModalStyle
                ? _c("h1", { staticClass: "d-inline" }, [
                    _vm._v(_vm._s(_vm.title()))
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("validation-alert", { attrs: { errors: _vm.validationErrors } }),
          _vm._v(" "),
          !_vm.propModalStyle ? _vm._m(0) : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "box bg-white" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4", class: _vm.colClass }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.noAutoComplete.name } }, [
                        _vm._v(_vm._s(_vm.getLocalMsg("category.name")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: !_vm.propEditable,
                          type: "text",
                          autocomplete: _vm.noAutoComplete.name,
                          id: _vm.noAutoComplete.name
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4", class: _vm.colClass }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: _vm.noAutoComplete.defaultDeductibleVatShare
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "category.defaultDeductibleVatShare"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.defaultDeductibleVatShare,
                            expression: "defaultDeductibleVatShare"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: !_vm.propEditable,
                          type: "text",
                          autocomplete:
                            _vm.noAutoComplete.defaultDeductibleVatShare,
                          id: _vm.noAutoComplete.defaultDeductibleVatShare
                        },
                        domProps: { value: _vm.defaultDeductibleVatShare },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.defaultDeductibleVatShare = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4", class: _vm.colClass }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: _vm.noAutoComplete.defaultDeductibleCostsShare
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLocalMsg(
                                "category.defaultDeductibleCostsShare"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.defaultDeductibleCostsShare,
                            expression: "defaultDeductibleCostsShare"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: !_vm.propEditable,
                          type: "text",
                          autocomplete:
                            _vm.noAutoComplete.defaultDeductibleCostsShare,
                          id: _vm.noAutoComplete.defaultDeductibleCostsShare
                        },
                        domProps: { value: _vm.defaultDeductibleCostsShare },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.defaultDeductibleCostsShare =
                              $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                !_vm.propModalStyle
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-secondary float-right",
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("global.cancel")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.propModalStyle && _vm.propEditable
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary float-right mr-3",
                        on: { click: _vm.save }
                      },
                      [_vm._v(_vm._s(_vm.getLocalMsg("global.save")))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              !_vm.propNewItem
                ? _c("div", [
                    _c("h4", { staticClass: "mt-4" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.book === "P"
                            ? _vm.getLocalMsg("relation.purchases")
                            : _vm.getLocalMsg("relation.sales")) +
                            " (" +
                            _vm.bookings.length +
                            ")"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box bg-white" },
                      [
                        _c("booking-items", {
                          attrs: {
                            book: _vm.book,
                            bookings: _vm.bookings,
                            details: true,
                            detailsRelation: true
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "box bg-white" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.getLocalMsg("category.info")) +
              "\n            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }