
<template>
    <div v-if="loaded">
        <validation-alert 
            v-bind:errors = "validationErrors"
        />
        <div class="row">
            <div class="col-12">
                <p>{{title}}</p>
            </div>
            <div class="col-12" v-if="propTransaction.transactionType.code === 'P' || propTransaction.transactionType.code === 'S'">
                <div class="form-group">
                    <select :id="noAutoComplete.booking" v-model="booking" class="form-control">
                            <option v-for="(booking, index) in bookings" v-bind:key="index" v-bind:value = "booking">{{bookingSelectString(booking)}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label :for="noAutoComplete.transactionDate">{{getLocalMsg('transaction.transactionDate')}}</label>
                    <!-- https://www.npmjs.com/package/vue-bootstrap-datetimepicker -->
                    <date-picker :autocomplete="noAutoComplete.transactionDate" :id="noAutoComplete.transactionDate" v-model.lazy="transactionDate" :config="dateTimePickerOptions"></date-picker>
                </div>
            </div>
            <div v-if="propTransaction.transactionType.code === 'T'" class="col-12">
                {{getLocalMsg('transaction.from')}}
            </div>
            <div class="col-12 mb-3">
                    <button v-for="(transactionAccount, index) in transactionAccounts" v-bind:key="index" type="button" class="btn btn-secondary mr-1" v-bind:class="buttonStyleFrom(transactionAccount)" v-on:click="transactionAccount1=transactionAccount">{{transactionAccount.name}}</button>
            </div>
            <div v-if="propTransaction.transactionType.code === 'T'" class="col-12">
                {{getLocalMsg('transaction.to')}}
            </div>
            <div v-if="propTransaction.transactionType.code === 'T'" class="col-12 mb-3">
                    <button v-for="(transactionAccount, index) in transactionAccounts" v-bind:key="index" type="button" class="btn btn-secondary mr-1" v-bind:class="buttonStyleTo(transactionAccount)" v-on:click="transactionAccount2=transactionAccount">{{transactionAccount.name}}</button>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label :for="noAutoComplete.amount">{{getLocalMsg('transaction.amount')}}<br><small>{{getLocalMsg('transaction.positive')}}</small></label>

                    <input type="text" :autocomplete="noAutoComplete.amount" class="form-control" :id="noAutoComplete.amount" v-model.lazy="amount">
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label :for="noAutoComplete.description">{{getLocalMsg('transaction.description')}}</label>
                    <input type="text" :autocomplete="noAutoComplete.description" class="form-control" :id="noAutoComplete.description" v-model.lazy="description">
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

//Check validatie ok front end en backend
//Update voorzien

import sharedMixin from '../../mixins/shared-mixin';
import ValidationAlert from '../../shared/ValidationAlert';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


export default {
    name: 'TransactionEdit',
    mixins: [sharedMixin],
    watch: {
        loaded: {
            handler(val) {
                this.initiate(val)
            },
            immediate: true
        },
        booking (val) {
            this.transactionDate = new Date(val.invoiceDate);
            this.amount = val.invoiceAmountInput;
        }
    },
    props: {
        propBooking: Object,
        propTransaction: Object
    },
    data () {
        return {
            componentKey: 0,
            validationErrors: [],
            noAutoComplete: {
                transactionDate: this.noAutoCompleteId(),
                description: this.noAutoCompleteId(),
                booking: this.noAutoCompleteId(),
                amount: this.noAutoCompleteId()
            },
            factor: 1,
            transactionType: {},
            transactionAccount1: {},
            transactionAccount2: {},
            transactionDate: null,
            amountBase: null,
            booking: null,
            description: '',

        }
    },
    
    components: {
        ValidationAlert,
        datePicker
    },
    computed: {
        loaded () {
            let loaded = true;
            loaded = this.$store.state.transactionTypes.loaded ? loaded : false;
            loaded = this.$store.state.transactionAccounts.loaded ? loaded : false;
            return loaded;
        },
        transactionDateYYYYMMDD () {
            if(this.transactionDate){
                const date = moment(this.transactionDate,this.dateTimePickerOptions.format);
                return date.format('YYYY-MM-DD');
            }else{
                return null;
            } 
        },
        amount: {
            get: function () {
                const result = this.amountBase;
                return this.fixAmount(result,2);
            },
            set: function (value) {

                value=value.toString().replace(',','.');
                if(isNaN(parseInt(value))) {
                    value = null;
                } else {
                    value = this.roundAmount(value,2);
                }

                //In 2 stappen werken om zeker te zijn dat dit reactive blijft
                this.amountBase = value;
                // if(value > this.bookingAmountToPay) {
                //     value = this.bookingAmountToPay;
                //     this.amountBase = value;
                // }
            }   
        },
        reference() {
            switch(this.propTransaction.transactionType.code) {
            case 'S':
                if(this.booking) {
                    return this.transactionType.name + ' ' + this.booking.refNumber;
                }else{
                    return this.transactionType.name;
                }
                break;
            case 'P':
                if(this.booking) {
                    return this.transactionType.name + ' ' + this.booking.refNumber;
                }else{
                    return this.transactionType.name;
                }
                break;
            case 'I':
                return this.transactionType.name;
                break;
            case 'O':
                return this.transactionType.name;
                break;
            case 'T':
                return this.transactionType.name + ' (' + this.transactionAccount1.name + ' > ' + this.transactionAccount2.name + ')';
                break;
            default:
            }
        },
        transactionTypes(){
            return this.getItems('transactionTypes');
        },
        transactionAccounts(){
            return this.getItems('transactionAccounts');
        },
        bookingAmountTotal(){

            if (this.booking) {
                return this.booking.invoiceAmountInput;
            }

        },
        bookingAmountPaid(){

            if (this.booking) {
                return this.booking.transactions.reduce((a,b) => {
                    return a + b.amount;
                },0)
            }

        },
        bookingAmountToPay(){

            if (this.booking) {
                return this.bookingAmountTotal-this.bookingAmountPaid;
            }
        },
        title () {
            return this.transactionType.name; 
        },
        bookings(){
            if (this.propTransaction.transactionType.code == 'S' || this.propTransaction.transactionType.code == 'P') {
                return this.$store.state.bookings.items
                .filter(booking => {
                    const transactionAmount = booking.transactions.reduce((a,b) => {
                        return a + b.amount;
                    },0)
                    if(transactionAmount < booking.invoiceAmountInput && booking.book == this.propTransaction.transactionType.code){
                        return true;
                    };
                    return false;
                })
                .sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))
                .sort((a, b) => (a.invoiceDate > b.invoiceDate) ? -1 : ((b.invoiceDate > a.invoiceDate) ? 1 : 0));
            }
            return [];
        }
        
    },
    methods: {
        initiate (loaded) {

            if(!loaded) {
                this.fetchData();
                return;
            }    

            if (this.propTransaction.booking) {
                this.booking = this.propTransaction.booking;
            } else {
                this.booking = null;
            }
            
            switch(this.propTransaction.transactionType.code) {
            case 'S':
            case 'P':
                this.factor = this.propTransaction.transactionType.code == 'S' ? 1 : -1;
                if(this.booking){
                    this.amount = this.bookingAmountToPay;
                    this.transactionDate = new Date(this.booking.invoiceDate);
                }else{
                    this.transactionDate = this.propTransaction.transactionDate ? new Date(this.propTransaction.transactionDate) : new Date();
                }
                this.transactionType = this.transactionTypes.find(item => {
                    return item.code === this.propTransaction.transactionType.code;
                });
                if(this.propTransaction.transactionAccount){
                    this.transactionAccount1 = this.propTransaction.transactionAccount;
                }
                break;
            case 'I':
            case 'O':
            case 'T':
                this.factor = this.propTransaction.transactionType.code == 'I' ? 1 : -1;
                this.transactionDate = this.propTransaction.transactionDate ? new Date(this.propTransaction.transactionDate) : new Date();
                this.transactionAccount1 = this.propTransaction.transactionAccount;
                this.transactionType = this.transactionTypes.find(item => {
                    return item.code === this.propTransaction.transactionType.code;
                });
                break;
            default:
            }

            this.$store.commit('showSpinner',false);
        },
        bookingSelectString(booking){

            const invoiceDate = moment(booking.invoiceDate,'YYYY-MM-DD').format('DD/MM/YYYY');

            let result = '';
            result += invoiceDate + ' - '; 
            result += (this.propTransaction.transactionType.code == 'S' ? this.getLocalMsg('transaction.sale') : this.getLocalMsg('transaction.purchase')) + ' ';
            result += booking.refNumber + ' - ';
            result += booking.relation.name + ' - ';
            result += this.fixAmount(booking.invoiceAmountInput,2);
            result += booking.reference ? ' - Ref ' + booking.reference : '';
            result += booking.description ?  ' - ' + booking.description : '';
            return result;
        },
        buttonStyleFrom(transactionAccount){
            if(this.transactionAccount1){
                if(transactionAccount.id === this.transactionAccount1.id){
                    return 'btn-primary'
                }
                return 'btn-secondary'
            }
        },
        buttonStyleTo(transactionAccount){
            if(this.transactionAccount2){
                if(transactionAccount.id === this.transactionAccount2.id){
                    return 'btn-primary'
                }
                return 'btn-secondary'
            }
        },
        validate() {

            let validate = true;
            this.validationErrors = [];


            //Check bedrag positief of meegeven aan gebruiker
            //Documenteren

            if(this.emptyObject(this.transactionAccount1)){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('transaction.transactionAccount')
                });
                validate=false;
            }
            
            if(this.propTransaction.transactionType.code == 'T') {
                if(this.emptyObject(this.transactionAccount2)){
                    this.validationErrors.push({
                        type: 'required',
                        field: this.getLocalMsg('transaction.transactionAccount')
                    });
                    validate=false;
                }
            }

            if(this.isEmpty(this.transactionDate)){
                this.validationErrors.push({
                    type: 'required',
                    field: this.getLocalMsg('transaction.transactionDate')
                });
                validate=false;
            }

            if(this.isEmpty(this.amount)){
                this.validationErrors.push({
                    type: 'numeric',
                    field: this.getLocalMsg('transaction.amount')
                });
                validate=false;
            }

            return validate;
        },
        promiseUpdateTransaction(id) {

            const action1 = () => {
                return this.$store.dispatch('setActiveItem', {
                    moduleName: 'transactions',
                    newItem: true
                })
            }
            const action2 = action1().then(() => {
                return this.setItemProperty('transactions','transactionDate',this.transactionDateYYYYMMDD)
            })
            const action3 = action2.then(() => {
                return this.setItemProperty('transactions','booking',this.booking)
            })
            const action4 = action3.then(() => {
                return this.setItemProperty('transactions','transactionType',this.transactionType)
            })
            const action5 = action4.then(() => {
                if(id==1){
                    return this.setItemProperty('transactions','transactionAccount',this.transactionAccount1)
                }else{
                    return this.setItemProperty('transactions','transactionAccount',this.transactionAccount2)
                }
            })
            const action6 = action5.then(() => {
                return this.setItemProperty('transactions','description',this.description)
            })
            const action7 = action6.then(() => {
                return this.setItemProperty('transactions','amount',this.amount)
            })
            const action8 = action7.then(() => {
                if(id==1){
                    return this.setItemProperty('transactions','factor',this.factor)
                }else{
                    return this.setItemProperty('transactions','factor',this.factor * -1)
                }
            })
            const action9 = action8.then(() => {
                return this.setItemProperty('transactions','reference',this.reference)
            })

            const action10 = action9.then(() => {
                return this.$store.dispatch('updateTransaction')
            })

            return action10;

        },
        updateTransaction() {

            if(!this.validate()){
                return Promise.resolve('NotValidated');
            };

            this.$store.commit('showSpinner', true);


            if(this.propTransaction.transactionType.code=='T'){
                
                return this.promiseUpdateTransaction(1).then(() =>{
                    return this.promiseUpdateTransaction(2)
                }).catch(error => {
                    this.log(error);
                }).finally(
                    () => this.$store.commit('showSpinner', false)
                ) 

            }else{
                return this.promiseUpdateTransaction(1).catch(error => {
                    this.log(error);
                }).finally(
                    () => this.$store.commit('showSpinner', false)
                )
            }

        },
    },
}

</script>

<style lang="scss">
    
</style>
