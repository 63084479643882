require('./bootstrap');
require('./custom');

import Vue from 'vue';
import router from './router';
import store from './store';

router.beforeEach((to, from, next) => {
    //Alle modal's sluiten voor het wijzigen van de route
    $('.modal').modal('hide');  
    next();    
})

// // Subscribe to store updates
// store.subscribe((mutation, state) => {
//     // Store the state object as a JSON string
//     localStorage.setItem('store', JSON.stringify(state));
// });

import Vuex from 'vuex';
Vue.use(Vuex);

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

//Custom directive voor Bootstrap Tooltip
Vue.directive('tooltip', function (el, binding) {
    $(el).tooltip({
        title: binding.value.title,
        placement: binding.value.placement,
        trigger: binding.value.trigger
    })
    $(el).on('click', function () {
        $(this).tooltip('hide')
    })
})

//Custom directive voor Bootstrap Popover
Vue.directive('popover', function (el, binding) {
    $(el).popover({
        title: binding.value.title,
        content: binding.value.content,
        trigger: binding.value.trigger,
        html: binding.value.html
    })
})


import App from './App.vue';


const app = new Vue(Vue.util.extend({
    router,
    store
}, App)).$mount('#app');



