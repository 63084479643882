import Vue from 'vue';
import VueRouter from 'vue-router';

import Site from '../shared/Site.vue';
import Admin from '../shared/Admin.vue';

import NotFound from '../site/NotFound.vue';

import RegisterCompany from '../admin/companies/RegisterCompany.vue';
import CompanySettings from '../admin/companies/CompanySettings.vue';


import Redirect from '../shared/Redirect.vue';

import Dashboard from '../admin/dashboard/Dashboard.vue';
import Passport from '../admin/passport/Passport.vue';

import IncomeStatement from '../admin/incomestatement/IncomeStatement.vue';
import VatDeclaration from '../admin/vatdeclaration/VatDeclaration.vue';
import StockchangeList from '../admin/stockchanges/StockchangeList.vue';
import LockedperiodList from '../admin/lockedperiods/LockedperiodList.vue';


import RelationList from '../admin/relations/RelationList.vue';
import RelationEdit from '../admin/relations/RelationEdit.vue';

import WriteOffList from '../admin/writeoffs/WriteOffList.vue';
import WriteOffEdit from '../admin/writeoffs/WriteOffEdit.vue';

import BookingList from '../admin/bookings/BookingList.vue';
import BookingEdit from '../admin/bookings/BookingEdit.vue';

import CategoryList from '../admin/categories/CategoryList.vue';
import CategoryEdit from '../admin/categories/CategoryEdit.vue';

import TransactionList from '../admin/transactions/TransactionList.vue';

import Home from '../site/Home.vue';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {   
            path: '/admin', 
            component: Admin,
            children: [
                {
                    path: '',
                    component: Dashboard
                },
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: 'registercompany',
                    component: RegisterCompany
                },
                {
                    path: 'companysettings',
                    component: CompanySettings
                },
                {
                    path: 'passport',
                    component: Passport
                },
                {
                    path: 'incomestatement',
                    component: IncomeStatement
                },
                {
                    path: 'vatdeclaration',
                    component: VatDeclaration
                },
                {
                    path: 'stockchanges',
                    component: StockchangeList
                },
                {
                    path: 'lockedperiods',
                    component: LockedperiodList
                },
                {
                    path: 'transactions',
                    component: TransactionList
                },
                {
                    path: 'purchases',
                    component: BookingList,
                    props: {
                        propBook: 'P'
                    }
                },
                {
                    path: 'purchase/new',
                    component: BookingEdit,
                    props: {
                        propBook: 'P',
                        propNewItem: true
                    }
                },
                {
                    path: 'purchase/:id/edit',
                    component: BookingEdit,
                    props: {
                        propBook: 'P',
                        propNewItem: false,
                    }
                },
                {
                    path: 'sales',
                    component: BookingList,
                    props: {
                        propBook: 'S'
                    }
                },
                {
                    path: 'sale/new',
                    component: BookingEdit,
                    props: {
                        propBook: 'S',
                        propNewItem: true
                    }
                },
                {
                    path: 'sale/:id/edit',
                    component: BookingEdit,
                    props: {
                        propBook: 'S',
                        propNewItem: false,
                    }
                },
                {
                    path: 'writeoff/:purchaseId/edit',
                    component: WriteOffEdit,
                },
                {
                    path: 'writeoffs',
                    component: WriteOffList,
                },
                {
                    path: 'suppliers',
                    component: RelationList,
                    props: {
                        propClientSupplier: 'S'
                    }
                },
                {
                    path: 'clients',
                    component: RelationList,
                    props: {
                        propClientSupplier: 'C'
                    }
                },
                {
                    path: 'client/new',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'C',
                        propNewItem: true,
                        propEditable: true
                    }
                },
                {
                    path: 'client/:id/edit',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'C',
                        propNewItem: false,
                        propEditable: true
                    }
                },
                {
                    path: 'client/:id/view',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'C',
                        propNewItem: false,
                        propEditable: false
                    }
                },
                {
                    path: 'supplier/new',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'S',
                        propNewItem: true,
                        propEditable: true
                    }
                },
                {
                    path: 'supplier/:id/edit',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'S',
                        propNewItem: false,
                        propEditable: true
                    }
                },
                {
                    path: 'supplier/:id/view',
                    component: RelationEdit,
                    props: {
                        propClientSupplier: 'S',
                        propNewItem: false,
                        propEditable: false
                    }
                },
                {
                    path: 'purchases/categories',
                    component: CategoryList,
                    props: {
                        propType: 'P'
                    }
                },
                {
                    path: 'sales/categories',
                    component: CategoryList,
                    props: {
                        propType: 'S'
                    }
                },
                {
                    path: 'purchases/category/:id/edit',
                    component: CategoryEdit,
                    props: {
                        propType: 'P',
                        propNewItem: false,
                        propEditable: true
                    }
                },
                {
                    path: 'sales/category/:id/edit',
                    component: CategoryEdit,
                    props: {
                        propType: 'S',
                        propNewItem: false,
                        propEditable: true
                    }
                },
                {
                    path: 'purchases/category/:id/view',
                    component: CategoryEdit,
                    props: {
                        propType: 'P',
                        propNewItem: false,
                        propEditable: false
                    }
                },
                {
                    path: 'sales/category/:id/view',
                    component: CategoryEdit,
                    props: {
                        propType: 'S',
                        propNewItem: false,
                        propEditable: false
                    }
                },
                {
                    path: 'purchases/category/new',
                    component: CategoryEdit,
                    props: {
                        propType: 'P',
                        propNewItem: true,
                        propEditable: true
                    }
                },
                {
                    path: 'sales/category/new',
                    component: CategoryEdit,
                    props: {
                        propType: 'S',
                        propNewItem: true,
                        propEditable: true
                    }
                },
            ]
        }, 
        {
            path: '/',
            component: Site,
            children: [
                {
                    path: '',
                    name: 'Home',
                    component: Home
                },
                {
                    path: 'home',
                    component: Home
                },
                {
                    path: 'redirect',
                    name: 'Redirect',
                    component: Redirect
                },
                {
                    path: '/404',
                    name: '404',
                    component: NotFound
                },
                {
                    path: '*',
                    redirect: '/404'
                },
            ]
        },   
        
    ],
});
